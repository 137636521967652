import React from 'react';
import GTMEvents from '@@Common/gtm';
import { ThumbnailUtils } from '@@Storefront';
import { MotorcycleTypeBannerContainer, TwiceBannerContainer } from './styled';
export const TwiceBanner = (props) => {
    if (!props.media)
        return null;
    const sendGTMClickEvent = (label) => {
        if (label)
            GTMEvents.push({
                event: 'homepageDualBannerClick',
                bannerTitle: label,
            });
    };
    return (React.createElement(TwiceBannerContainer, null, props.media.map((el, index) => {
        var _a, _b, _c, _d, _e;
        return (React.createElement(MotorcycleTypeBannerContainer, { key: index },
            React.createElement("img", { src: ThumbnailUtils.getImageSrc({ image: el === null || el === void 0 ? void 0 : el.path, entity: '' }), alt: el.title || '', loading: "lazy" }),
            ((_a = el.link) === null || _a === void 0 ? void 0 : _a.url) && (React.createElement("a", { href: (_b = el.link) === null || _b === void 0 ? void 0 : _b.url, target: (_c = el.link) === null || _c === void 0 ? void 0 : _c.target, title: ((_d = el.link) === null || _d === void 0 ? void 0 : _d.label) || 'Link to image in twice banner', className: "link", onClick: () => { var _a; return sendGTMClickEvent((_a = el.link) === null || _a === void 0 ? void 0 : _a.label); } },
                React.createElement("i", null, (_e = el.link) === null || _e === void 0 ? void 0 : _e.label)))));
    })));
};
