import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const ProductLongTileContainer = styled.div `
  display: flex;
  height: 150px;
  width: 100%;
  border: 1px solid ${(props) => props.theme.input.borderColors.default};
  padding: 15px;
  justify-content: space-between;
  column-gap: 20px;
  position: relative;
  &:hover {
    border-color: ${(props) => props.theme.input.borderColors.hover};
  }
`;
export const InformationContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  min-width: 20vw;
`;
export const PricesAndButtonsContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 255px;
  min-width: 255px;
  padding-top: 10px;
  @media (max-width: 1300px) {
    width: 200px;
    min-width: 200px;
  }
`;
export const SpecificationWrapper = styled.div `
  display: flex;
  column-gap: 10px;
`;
export const SpecificationColumn = styled.div `
  display: flex;
  flex-direction: column;
  width: 50%;
  row-gap: 5px;
`;
export const Title = styled.h5 `
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
  text-overflow: ellipsis;
`;
export const TextWrapper = styled.div `
  display: flex;
  column-gap: 5px;
`;
export const Text = styled.p `
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${(props) => props.greyColor && 'color: #525252'};
  margin: 0;
  white-space: ${(props) => (props.textWrap ? 'wrap' : 'nowrap')};
  line-clamp: 2;
  ${(props) => props.ellipsis &&
    `
    overflow: hidden;
    max-width: 95%;
    text-overflow: ellipsis;
  `};
`;
export const PricesWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
export const MainPriceLine = styled.span `
  display: flex;
  align-items: flex-end;
  column-gap: 3px;
  height: 28px;
`;
export const OriginalPriceLabel = styled.p `
  text-decoration: line-through;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  margin: 0;
`;
export const PricesWithTax = styled.span `
  display: flex;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const MainPriceLabel = styled.p `
  margin: 0;
  padding-bottom: 5px;
  color: #d9000d;
`;
export const MainPrice = styled.h2 `
  white-space: nowrap;
  color: #d9000d;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.mixins.mobile('font-size: 16px;')}
`;
export const StyledButton = styled(Button) `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  line-height: 42px;
  font-weight: 600;
  font-style: italic;
  ${(props) => props.theme.mixins.mobile(`
    height: 34px; 
    font-size: 13px; 
    line-height: 34px;
  `)};
`;
export const ButtonsWrapper = styled.div `
  display: flex;
  width: 100%;
  > *:first-child {
    width: 100%;
  }
  > *:not(:first-child) {
    max-width: 70px;
    margin-left: 10px;
    border: 1px solid #a0a0a0;
    @media (max-width: 1250px) and (min-width: 1101px) {
      max-width: 50px;
    }
  }
`;
export const ProductAvailabilityWrapper = styled.div `
  display: flex;
  align-items: center;
  column-gap: 5px;
`;
export const ProductAvailabilityIndicator = styled.div `
  min-width: 11px;
  height: 11px;
  background-color: #d9000d;
  background-position: center;
  border-radius: 100%;
  ${(props) => props.isAvailable && 'background-color: #1EDE60'};
`;
export const ProductImage = styled.img `
  object-fit: contain;
`;
