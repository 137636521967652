import styled from 'styled-components';
export const SocialsContainer = styled.div ``;
export const ButtonsWrapper = styled.div ``;
export const StyledGoogle = styled.button `
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  border: 1px solid black;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`;
export const StyledFacebook = styled.button `
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a77f2;
  color: white;
  border: 1px solid #1a77f2;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
`;
export const Title = styled.div `
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 20px;
`;
