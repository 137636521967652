import styled from 'styled-components';
export const MotorcycleTypeBannerContainer = styled.section `
  width: 100%;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  a.link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0;
    opacity: 0;
  }
`;
export const TwiceBannerContainer = styled.div `
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;
