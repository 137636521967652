import { useLang } from '@@Hooks/useLang';
import LoginForm from '@@Forms/LoginForm/index';
import { RegisterForm } from '@@Forms/RegisterForm';
import { userIsLoggedSelector } from '@@Redux/selectors/user.selectors';
import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { PageSeo } from '@@Components/PageSeo';
import { PageTitle, RegisterLink, LoginPageWrapper, LoginPageSection, LoginPageContentContainer, StyledParagraph, LoginPageImageWrapper, } from './styled';
export const LoginPage = ({ register, googleToken, }) => {
    const isLogged = useSelector(userIsLoggedSelector);
    const { translate } = useLang();
    const [isRegisterFormEnabled, toggleRegisterForm] = useState(register);
    return isLogged ? (React.createElement(Redirect, { to: "/account/edit" })) : (React.createElement(React.Fragment, null,
        React.createElement(PageSeo, { seo: {
                title: 'Logowanie',
                description: 'Strona logowania',
            } }),
        React.createElement(LoginPageWrapper, null,
            React.createElement(LoginPageSection, null, !isRegisterFormEnabled ? (React.createElement(LoginPageContentContainer, null,
                React.createElement(PageTitle, null,
                    React.createElement("span", null, translate('account.are_you_user'))),
                React.createElement(LoginForm, { googleToken: googleToken }),
                React.createElement("p", null,
                    React.createElement("span", null, translate('account.dont_have_account')),
                    React.createElement(RegisterLink, { onClick: () => toggleRegisterForm(true) }, translate('actions.register'))))) : (React.createElement(LoginPageContentContainer, null,
                React.createElement(PageTitle, null,
                    React.createElement("span", null, translate('account.first_time'))),
                React.createElement(RegisterForm, null),
                React.createElement(StyledParagraph, null,
                    React.createElement("span", null, translate('account.do_you_have_account')),
                    React.createElement(RegisterLink, { onClick: () => toggleRegisterForm(false) }, translate('actions.log_in_exclamation_mark')))))),
            React.createElement(LoginPageImageWrapper, null,
                React.createElement("img", { src: isRegisterFormEnabled
                        ? './assets/image/loginPage/register_bg.jpg'
                        : './assets/image/loginPage/login_bg.jpg', alt: "background" })))));
};
export default LoginPage;
