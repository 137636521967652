import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
import { PaymentMethodIconStyled } from '@@Pages/CheckoutPage/components/PaymentMethodIcon/styled';
export const PaymentMethodIcon = ({ code }) => {
    const mageConfig = useSelector(mageConfigSelector);
    const [isAllowed, setIsAllowed] = useState(false);
    useEffect(() => {
        var _a, _b;
        const allowedIcons = ((_b = (_a = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.layout) === null || _a === void 0 ? void 0 : _a.checkout) === null || _b === void 0 ? void 0 : _b.show_icons_for_payment_methods) || [];
        setIsAllowed(allowedIcons.includes(code));
    }, [mageConfig]);
    return isAllowed || true ? (React.createElement(PaymentMethodIconStyled, { src: `/assets/payment/method-icons/${code}.svg` })) : (React.createElement(React.Fragment, null));
};
