import styled from 'styled-components';
export const ModalTextWrapper = styled.div `
  text-align: center;
`;
export const ModalHeading = styled.p `
  font-weight: 600;
  line-height: 26px;
  margin: 0;
`;
export const ModalTitle = styled.h2 `
  line-height: 36px;
`;
export const ModalDescription = styled.p `
  font-size: 16px;
  padding-top: 30px;
  line-height: 26px;
  ${(props) => props.theme.mixins.mobile(`
    font-size: 14px;
    padding-top: 20px;
    line-height: 23px;
  `)};
`;
export const ModalContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 45px;
  width: 100%;
`;
export const ModalImage = styled.img `
  margin-top: 45px;
  width: 100%;
  max-height: 220px;
  object-fit: cover;
`;
export const ContentWrapper = styled.div `
  margin-top: 30px;
  ${(props) => props.theme.mixins.mobile('margin-top: 25px')};
`;
export const ModalInnerContainer = styled.div `
  padding: 0 25px;
  width: 100%;
  ${(props) => props.theme.mixins.mobile('padding: 0 10px')};
`;
