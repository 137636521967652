import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { useLang } from '@@Hooks/useLang';
import { useParams } from 'react-router-dom';
import { confirmNewsletterSubscription } from '@@Storefront/actions/newsletter';
import { Logger } from '@grupakmk/libstorefront';
import { Spinner } from '@@Components/Spinner';
import { InformationImage } from '@@Components/InformationImage';
import { SpinnerWrapper } from './styled';
const NewsletterSubscriptionConfirmationPage = () => {
    const { translate } = useLang();
    const { id, token } = useParams();
    const [pending, setIsPending] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (!id || !token)
                return;
            try {
                yield confirmNewsletterSubscription(id, token);
            }
            catch (e) {
                setError(true);
                Logger.error('Cannot confirm newsletter subscription', 'newsletter-subscription-confirmation-page', e.message || e);
            }
            setIsPending(false);
        }))();
    }, [token, id]);
    return (React.createElement(React.Fragment, null, pending ? (React.createElement(SpinnerWrapper, null,
        React.createElement(Spinner, { size: 30 }))) : (React.createElement(InformationImage, { title: translate(error
            ? 'headers.newsletter_confirmation_error'
            : 'headers.newsletter_confirmation'), description: translate(error
            ? 'messages.newsletter_confirmation_error'
            : 'messages.newsletter_confirmation'), imageUrl: "assets/image/newsletterConfirmation/newsletter.jpg", buttonText: translate('pages.main'), buttonUrl: "/" }))));
};
export default NewsletterSubscriptionConfirmationPage;
