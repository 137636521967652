import React, { useEffect, useState } from 'react';
import { useLang } from '@@Hooks/useLang';
import { InformationImage } from '@@Components/InformationImage';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
import { useSelector } from 'react-redux';
import { useRouter } from '@@Hooks/useRouter';
import { LinksContainer, PhoneMailLink } from './styled';
import { useParams } from 'react-router-dom';
const PaymentErrorPage = () => {
    const { translate } = useLang();
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const mageConfig = useSelector(mageConfigSelector);
    const { search } = useRouter();
    const { type } = useParams();
    useEffect(() => {
        var _a, _b;
        if (mageConfig) {
            setEmail((_a = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.store_email_addresses) === null || _a === void 0 ? void 0 : _a.general_email);
            setPhone((_b = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.storeInformation) === null || _b === void 0 ? void 0 : _b.phone);
        }
    }, [mageConfig]);
    const contactInformation = (React.createElement(LinksContainer, null,
        email && (React.createElement(PhoneMailLink, { bold: true, href: `mailto:${email}` }, email)),
        phone && React.createElement(PhoneMailLink, { href: `tel:${phone}` }, phone)));
    const description = type === 'ca_raty'
        ? translate('messages.ca_error_page_description')
        : translate('messages.payment_error_description');
    return (React.createElement(InformationImage, { heading: (search === null || search === void 0 ? void 0 : search.order) ? `${translate('common.order')} ${translate('common.short_number')}. ${search.order}`
            : '', imageUrl: "assets/image/paymentError/paymenterror.jpg", title: translate('messages.payment_error_title'), description: description, contentSlots: contactInformation, smallImageUrl: "assets/image/common/box.svg" }));
};
export default PaymentErrorPage;
