import styled from 'styled-components';
import Button from '@@Components/Buttons/Button';
import { Link } from 'react-router-dom';
export const NewsletterDescription = styled.p `
  line-height: 22px;
  margin: 0;
  ${(props) => props.paddingBottom && `padding-bottom: ${props.paddingBottom}px;`}
`;
export const StyledButton = styled(Button) `
  margin-top: 30px;
  width: 100%;
`;
export const PopupContentContainer = styled.div `
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const StyledLink = styled(Link) `
  text-decoration: underline;
`;
export const Title = styled.p `
  font-weight: bold;
  margin: 0;
  padding-bottom: 15px;
`;
export const NewsletterContainer = styled.div `
  margin-bottom: 30px;
`;
export const SpinnerWrapper = styled.div `
  display: flex;
  align-items: center;
  width: 120px;
  height: 20px;
`;
