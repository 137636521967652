import styled from 'styled-components';
import { CheckoutManagePanel } from '../../Layout/styled';
export const CheckoutWrapper = styled.div `
  --side-padding: 15px;
  margin: 0 auto;
  display: flex;
  ${(props) => props.emptyCart
    ? `
    margin: 0 auto;
    min-height: 800px;
    max-width: none;
    padding: 0;
    @media (max-width: 1024px) {
      flex-direction: column-reverse !important;    
      min-height: 700px;
    }
  `
    : `
    margin-top: 30px;
    margin-bottom: 45px;
    max-width: calc(1366px + 2 * var(--side-padding));
    padding: 0 var(--side-padding);
  `}
  > div {
    flex: 1;
  }
  @media (max-width: 1024px) {
    flex-direction: column;

    ${CheckoutManagePanel} {
      margin-top: 25px;
    }
  }
`;
