import styled from 'styled-components';
export const SearchBarContainer = styled.div `
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
export const SearchBarInputWrapper = styled.div `
  border-bottom: 1px solid black;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
export const SearchBarInput = styled.input `
  height: 100%;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
  &::placeholder {
    color: black;
  }
`;
export const ClearButton = styled.button `
  padding: 0;
`;
export const ResultsContainer = styled.div `
  position: absolute;
  margin-top: 12px;
  width: 604px;
  display: flex;
  flex-direction: column;
  z-index: 200;
  background-color: white;
  @media (max-width: 1000px) {
    width: 100%;
    position: relative;
  }
`;
export const ResultsGradient = styled.div `
  height: 30px;
  width: calc(100% - 12px);
  position: absolute;
  bottom: 0;
  left: 0;
  background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0%
    0% no-repeat;
  ${(props) => props.disable && 'display: none'};
  @media (max-width: 1000px) {
    display: none;
  }
`;
export const ResultsContentWrapper = styled.div `
  border: 1px solid black;
  border-bottom: none;
  width: 100%;
  padding-right: 5px;
  position: relative;
  @media (max-width: 1000px) {
    border: none;
    padding: 0;
  }
`;
export const ResultsContent = styled.div `
  display: flex;
  flex-direction: column;
  min-height: 50px;
  max-height: 350px;
  overflow-y: scroll;
  padding-left: 20px;
  padding-right: 10px;
  ::-webkit-scrollbar-thumb {
    background: #787878;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-track {
    background: white;
  }
  @media (max-width: 1000px) {
    overflow: hidden;
    max-height: none;
    padding: 0;
  }
`;
export const StyledLink = styled.a `
  margin: 0;
  padding: 0;
  text-decoration: none;
  @media (max-width: 1000px) {
    position: sticky;
    bottom: 15px;
  }
`;
