import React, { useState, useEffect } from 'react';
import { ThumbnailUtils } from '@@Storefront';
import { ProductGallerySlideWrapper, ProductGalleryVideoWrapper, } from './styled';
export const ProductGallerySlide = ({ width, height, isActive, typ, src, allowFullscreen, alt, pos, image, imageAlt, productName, withoutBorder, onClick, }) => {
    const imageWidth = width || 640;
    const imageHeight = height || 430;
    const imgAlt = imageAlt || `${productName}_${pos}}`;
    const [showVideo, setShowVideo] = useState(false);
    useEffect(() => {
        isActive && setShowVideo(true);
    }, [isActive]);
    const handleItemClick = () => {
        onClick && onClick();
    };
    if (typ === 'external-video') {
        return (React.createElement(React.Fragment, null, showVideo && (React.createElement(ProductGalleryVideoWrapper, { withoutBorder: withoutBorder, onClick: handleItemClick, withPointerCursor: !!onClick },
            React.createElement("iframe", { src: src, width: imageWidth, height: imageHeight, frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;", allowFullScreen: allowFullscreen })))));
    }
    return (React.createElement(ProductGallerySlideWrapper, { withoutBorder: withoutBorder, onClick: handleItemClick, withPointerCursor: !!onClick },
        React.createElement("img", { alt: imgAlt, src: ThumbnailUtils.getImageSrc({
                image: image || 'default.jpg',
                width: imageWidth,
                height: imageHeight,
                entity: '',
            }) })));
};
