import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { Heading } from '@@Components/Heading';
import { useControlledForm } from '@@Hooks/useControlledForm';
import { validateEmail } from '@@Helpers/validators';
import { useLang } from '@@Hooks/useLang';
import LSF from '@@Storefront';
import { NotificationType } from '@adsel/libstorefront';
import Button from '@@Components/Buttons/Button';
import Input from '@@Components/Input/Input';
import { notify } from '@@Helpers/notifications';
import { ButtonWrapper, RemindPasswordDescription, RemindPasswordModalInnerWrapper, } from './styled';
export const ResetPassword = (props) => {
    const [isRemindPasswordInProgress, setIsRemindPasswordInProgress] = useState(false);
    const { translate } = useLang();
    const { register, errors, handleSubmit, reset } = useControlledForm({ mode: 'onTouched' });
    const onError = () => {
        notify(translate('remind_password.could_not'), NotificationType.ERROR);
    };
    const onSuccess = () => {
        notify(translate('remind_password.success_info'), NotificationType.SUCCESS);
        if (props.onSuccess) {
            props.onSuccess();
        }
    };
    const onSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsRemindPasswordInProgress(true);
            const response = yield LSF.UserService.resetPassword(e.email);
            if (response)
                onSuccess();
            else
                onError();
            setIsRemindPasswordInProgress(false);
        }
        catch (error) {
            onError();
            setIsRemindPasswordInProgress(false);
        }
    });
    useEffect(() => {
        return () => reset({ email: '' });
    }, []);
    return (React.createElement(RemindPasswordModalInnerWrapper, null,
        React.createElement("header", null,
            React.createElement(Heading, { size: "h3", style: { marginBottom: '5px' } }, translate('remind_password.cant_remember_password'))),
        React.createElement(RemindPasswordDescription, null,
            translate('remind_password.description_line1'),
            React.createElement("br", null),
            translate('remind_password.description_line2')),
        React.createElement("form", { noValidate: true, autoComplete: "off", "data-cy": "form rememberPassword", onSubmit: handleSubmit(onSubmit) },
            React.createElement(Input, { name: "email", ref: register({ required: true, validate: validateEmail }), placeholder: translate('customer.getEmailAddress'), label: translate('checkout.email'), error: errors.email }),
            React.createElement(ButtonWrapper, null,
                React.createElement(Button, { appearance: "primary", maxWidth: "212", disabled: isRemindPasswordInProgress, loading: isRemindPasswordInProgress }, translate('remind_password.send_email')),
                props.withBackButton && props.onBack && (React.createElement(Button, { appearance: "outline", maxWidth: "212", disabled: isRemindPasswordInProgress, loading: isRemindPasswordInProgress, onClick: props.onBack }, translate('remind_password.go_back')))))));
};
