import styled from 'styled-components';
export const EditUserConsentsFormContainer = styled.div `
  max-width: 440px;
`;
export const Title = styled.p `
  font-weight: bold;
  margin: 0;
  padding-bottom: 15px;
`;
export const CheckboxWrapper = styled.div `
  > *:not(:last-child) {
    padding-bottom: 10px;
  }
`;
