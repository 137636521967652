import React from 'react';
import Icon from '@@Components/Icon/Icon';
import { Link } from '@@Components/Link';
import { useLang } from '@@Hooks/useLang';
import { CAStatus, OrderPreviewBack, OrderPreviewDate, OrderPreviewHeaderContainer, OrderPreviewID, OrderPreviewInfo, OrderPreviewLinks, } from './styled';
const OrderPreviewHeader = ({ order }) => {
    var _a, _b;
    const { translate } = useLang();
    return (React.createElement(OrderPreviewHeaderContainer, null,
        React.createElement(OrderPreviewInfo, null,
            React.createElement(OrderPreviewBack, null,
                React.createElement(Link, { to: "/account/orders-history/" },
                    React.createElement(Icon, { icon: "back", width: "15" }))),
            React.createElement("div", null,
                React.createElement(OrderPreviewID, null,
                    translate('common.order'),
                    ": ",
                    React.createElement("strong", null, order === null || order === void 0 ? void 0 : order.increment_id)),
                React.createElement(OrderPreviewDate, null,
                    translate('common.of_date'),
                    " ", order === null || order === void 0 ? void 0 :
                    order.created_at),
                ((_a = order === null || order === void 0 ? void 0 : order.extension_attributes) === null || _a === void 0 ? void 0 : _a.status_description) && (React.createElement(CAStatus, null,
                    translate('payment.ca_raty.credit_status'),
                    ":",
                    ' ',
                    React.createElement("strong", null, order.extension_attributes.status_description))))),
        ((_b = order === null || order === void 0 ? void 0 : order.extension_attributes) === null || _b === void 0 ? void 0 : _b.tracking_url) && (React.createElement(OrderPreviewLinks, null,
            React.createElement("a", { href: order.extension_attributes.tracking_url, target: "_blank", rel: "noreferrer" },
                React.createElement("strong", null, translate('order.track_order')))))));
};
export default OrderPreviewHeader;
