import styled from 'styled-components';
export const RemindPasswordModalInnerWrapper = styled.div `
  text-align: center;
  font-size: 15px;

  header {
    margin-bottom: 25px;
  }

  form {
    max-width: 438px;
    margin: 0 auto;
  }
`;
export const RemindPasswordDescription = styled.p `
  color: #121212;
  font-size: 16px;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 25px;
  text-align: center;
  margin-bottom: 25px;
`;
export const ButtonWrapper = styled.div `
  margin-top: 25px;

  button {
    width: 100%;
    max-width: 100%;
  }

  button + button {
    margin-top: 5px;
  }
`;
