import styled from 'styled-components';
export const StyledLink = styled.a `
  border-bottom: 1px solid ${(props) => props.theme.input.borderColors.default};
  &:last-child {
    border-bottom: none;
  }
`;
export const TileContainer = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 13px 0 13px 13px;
  @media (max-width: 1000px) {
    padding: 13px;
  }
  @media (max-width: 650px) {
    height: 88px;
  }
`;
export const TileImage = styled.img `
  width: 54px;
  height: 36px;
  object-fit: cover;
`;
export const TileName = styled.p `
  line-height: 22px;
  margin: 0;
  max-width: 257px;
  min-width: 150px;
  margin: 0 25px 0 30px;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 45px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: none;
  }
  @media (max-width: 650px) {
    margin: 0;
  }
`;
export const TilePrice = styled.p `
  ${(props) => props.bold && 'font-weight: 600'};
  margin: 0;
  line-height: 23px;
  color: ${(props) => props.theme.colors.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const TileOldPrice = styled.p `
  text-decoration: line-through;
  margin: 0;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const TileInfoWrapper = styled.div `
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  @media (max-width: 1000px) {
    justify-content: space-between;
  }
  @media (max-width: 650px) {
    justify-content: start;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
  }
`;
export const TilePricesWrapper = styled.div `
  display: flex;
  column-gap: 10px;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;
