import React from 'react';
import { Modal } from '@@Components/Modal';
import { ModalContainer, ModalHeading, ModalTitle, ModalDescription, ModalTextWrapper, ModalImage, ContentWrapper, ModalInnerContainer, } from './styled';
export const Popup = ({ heading, title, description, content, imageUrl, onClose, isOpened, }) => {
    return (React.createElement(Modal, { isOpened: isOpened, onClose: onClose, noMobileFullscreen: true, noInternalPaddingMobile: true, noInternalPadding: true },
        React.createElement(ModalContainer, null,
            React.createElement(ModalInnerContainer, null,
                React.createElement(ModalTextWrapper, null,
                    heading && React.createElement(ModalHeading, null, heading),
                    title && React.createElement(ModalTitle, null, title),
                    description && React.createElement(ModalDescription, null, description)),
                content && React.createElement(ContentWrapper, null, content)),
            imageUrl && React.createElement(ModalImage, { src: imageUrl }))));
};
