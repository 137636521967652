import { __rest } from "tslib";
import React from 'react';
import { ProductUtils, ThumbnailUtils, PriceUtils, } from '@adsel/libstorefront';
import { TileContainer, TileImage, TileName, TileOldPrice, TilePrice, StyledLink, TileInfoWrapper, TilePricesWrapper, } from './styled';
export const SearchTile = (_a) => {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var { product } = _a, props = __rest(_a, ["product"]);
    const getProductImage = (product) => {
        return ThumbnailUtils.getImageSrc({
            image: product === null || product === void 0 ? void 0 : product.image,
            width: 108,
            height: 72,
            entity: '',
        });
    };
    const onProductLinkClick = (product) => {
        if (props.onProductLinkClick) {
            props.onProductLinkClick(product);
        }
    };
    const isDiscount = !!(((_c = (_b = product === null || product === void 0 ? void 0 : product.prices) === null || _b === void 0 ? void 0 : _b.final_price) === null || _c === void 0 ? void 0 : _c.gross) && ((_e = (_d = product === null || product === void 0 ? void 0 : product.prices) === null || _d === void 0 ? void 0 : _d.final_price) === null || _e === void 0 ? void 0 : _e.net) &&
        ((_g = (_f = product === null || product === void 0 ? void 0 : product.prices) === null || _f === void 0 ? void 0 : _f.final_price) === null || _g === void 0 ? void 0 : _g.gross) < ((_j = (_h = product === null || product === void 0 ? void 0 : product.prices) === null || _h === void 0 ? void 0 : _h.price) === null || _j === void 0 ? void 0 : _j.gross));
    return product ? (React.createElement(StyledLink, { href: `/produkt/${product.url_key}`, title: product.name, onClick: () => onProductLinkClick(product) },
        React.createElement(TileContainer, null,
            React.createElement(TileImage, { src: getProductImage(product), alt: product.name }),
            React.createElement(TileInfoWrapper, null,
                React.createElement(TileName, null, product.name),
                React.createElement(TilePricesWrapper, null,
                    ((_l = (_k = product === null || product === void 0 ? void 0 : product.prices) === null || _k === void 0 ? void 0 : _k.final_price) === null || _l === void 0 ? void 0 : _l.gross) && ((_o = (_m = product === null || product === void 0 ? void 0 : product.prices) === null || _m === void 0 ? void 0 : _m.final_price) === null || _o === void 0 ? void 0 : _o.net) && (React.createElement(TilePrice, { bold: true }, ((_t = PriceUtils.getFormattedPrice((_q = (_p = product === null || product === void 0 ? void 0 : product.prices) === null || _p === void 0 ? void 0 : _p.final_price) === null || _q === void 0 ? void 0 : _q.net, (_s = (_r = product === null || product === void 0 ? void 0 : product.prices) === null || _r === void 0 ? void 0 : _r.final_price) === null || _s === void 0 ? void 0 : _s.gross)) === null || _t === void 0 ? void 0 : _t.price_incl_tax) || '')),
                    !ProductUtils.isConfigurableProduct(product) && isDiscount && (React.createElement(TileOldPrice, null, (_u = PriceUtils.getFormattedPrice(product.prices.price.net, product.prices.price.gross)) === null || _u === void 0 ? void 0 : _u.price_incl_tax))))))) : (React.createElement(React.Fragment, null));
};
