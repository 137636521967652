import React from 'react';
import { useLang } from '@@Hooks/useLang';
import { PageSpecificMeta } from '@@Components/PageSpecificMeta';
import { EditUserConsentsForm } from '@@Forms/EditUserConsentsForm';
import { EditUserDataForm } from '@@Forms/EditUserDataForm';
import { ChangeUserPasswordForm } from '@@Forms/ChangeUserPasswordForm';
import { NewsletterSubscriptionManagement } from '@@Components/NewsletterSubscriptionManagement';
import { AccountPageEditContainer, EditColumn, EditColumnsWrapper, Title, } from './styles';
export const AccountPageEditProfile = () => {
    const { translate } = useLang();
    return (React.createElement(AccountPageEditContainer, null,
        React.createElement(PageSpecificMeta, { title: "Moje konto" }),
        React.createElement(EditColumnsWrapper, null,
            React.createElement(EditColumn, { rowGap: 35 },
                React.createElement(EditUserDataForm, null),
                React.createElement(ChangeUserPasswordForm, null)),
            React.createElement(EditColumn, { rowGap: 30 },
                React.createElement("div", null,
                    React.createElement(Title, null, translate('headers.subscriptions_and_consents')),
                    React.createElement(EditUserConsentsForm, null)),
                React.createElement(NewsletterSubscriptionManagement, null)))));
};
export default AccountPageEditProfile;
