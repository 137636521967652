import styled from 'styled-components';
export const CommentTextareaWrapper = styled.div `
  padding-top: 10px;
`;
export const CheckoutPageWrapper = styled.div `
  section + section {
    margin-top: 30px;
  }
`;
export const FormSectionParagraph = styled.p `
  line-height: 1.57;
  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;
