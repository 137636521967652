import styled from 'styled-components';
export const FormSectionHeading = styled.header `
  --color: ${(props) => (props.active ? '#000' : '#838383')};
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color);
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;

  h3 {
    color: var(--color);
    font-size: 16px;
    font-weight: bold;
  }

  a {
    color: #000000;
    font-size: 14px;
    cursor: pointer;
  }
`;
