import styled from 'styled-components';
export const StyledForm = styled.form `
  display: flex;
  width: 100%;
  ${(props) => props.theme.mixins.mobile('flex-direction: column')}
`;
export const FormInputWrapper = styled.div `
  width: 100%;
  ${(props) => props.theme.mixins.mobile('padding-bottom: 10px;')}
`;
export const FormButtonWrapper = styled.div `
  min-width: 210px;
`;
