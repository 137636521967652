import React from 'react';
import { ThumbnailUtils } from '@@Storefront';
import Button from '@@Components/Buttons/Button';
import { BannerTitle, BannerContainer, BannerDescription, BannerContent, } from '@@Components/SingleBanner/styled';
export const SingleBanner = (props) => {
    var _a, _b, _c, _d;
    return ((_a = props === null || props === void 0 ? void 0 : props.media) === null || _a === void 0 ? void 0 : _a.image) ? (React.createElement(BannerContainer, { background: ThumbnailUtils.getImageSrc({
            image: (_c = (_b = props.media) === null || _b === void 0 ? void 0 : _b.image[0]) === null || _c === void 0 ? void 0 : _c.url,
            entity: '',
            width: 1440,
            height: 450,
        }) },
        React.createElement(BannerContent, { textColor: ((_d = props === null || props === void 0 ? void 0 : props.media) === null || _d === void 0 ? void 0 : _d.image_text_color) || '#000' },
            props.media.title && React.createElement(BannerTitle, null, props.media.title),
            props.media.description && (React.createElement(BannerDescription, null, props.media.description)),
            props.media.button_url && props.media.button_text && (React.createElement("a", { href: props.media.button_url },
                React.createElement(Button, { maxWidth: 250 }, props.media.button_text)))))) : null;
};
