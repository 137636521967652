import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const LoginFormWrapper = styled.div `
  width: 440px;
  margin: 0 auto;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const LoginButtonContainer = styled.div `
  margin-top: 30px;
`;
export const RemindPasswordButtonContainer = styled.div `
  margin: 15px 0 25px;
`;
export const RemindPasswordLink = styled.a `
  cursor: pointer;
  color: #0f0f0f;
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
`;
export const GoogleLogInButton = styled(Button) `
  letter-spacing: 0.28px;
  font-weight: normal;
  margin-bottom: 15px;
`;
