import React from 'react';
import { translate } from '@@Helpers/translateGlobal';
import { SubmitButtonWrapper } from '../ShippingSelection/styled';
import Button from '@@Components/Buttons/Button';
export const VirtualProductShippingInfo = ({ withButton, onButtonClick }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement("p", null, translate('checkout_form.virtual_product_shipping_method_info')),
        withButton && (React.createElement(SubmitButtonWrapper, null,
            React.createElement(Button, { onClick: () => {
                    if (onButtonClick)
                        onButtonClick();
                }, appearance: "primary", "data-cy": "proceedButton" }, translate('checkout_form.proceed'))))));
};
