import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { ThumbnailUtils } from '@grupakmk/libstorefront';
import Icon from '@@Components/Icon/Icon';
import { dispatch } from '@@Redux/dispatch';
import { TranslatePlural } from '@@Client/lang';
import { QuantityInput } from '@@Components/QuantityInput';
import { Spinner } from '@@Components/Spinner';
import { updateItemQty, removeItem } from '@@Redux/thunks/cart.thunks';
import { PriceUtils } from '@@Storefront';
import { getVariantOptions } from './utility';
import { StyledContent, StyledCount, StyledDelete, StyledImage, StyledProduct, StyledTitle, StyledConfiguredLabel, StyledGross, StyledCounter, StyledNet, StyledPrices, StyledPricing, StyledTax, StyledId, StyledContainerAnimated, ProductPriceWrapper, ProductOriginalPrice, ProductPrice, ProductPriceContainer, } from './styled';
import { translate } from '@kmk/common/src/helpers/translateGlobal';
export const CartProductTile = (props) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [isLoading, setIsLoading] = useState(false);
    const [price, setPrice] = useState({
        price: '',
        price_incl_tax: '',
        original_price: '',
        type: '',
    });
    const [options, setOptions] = useState(null);
    const [qty, setQty] = useState(props === null || props === void 0 ? void 0 : props.qty);
    const openUrlProduct = (name) => window.open('/produkt/' + name, '_self');
    const handleDelete = () => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(removeItem(props));
    });
    const handleQuantity = (qty) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield dispatch(updateItemQty(props, qty));
        setQty(qty);
        setIsLoading(false);
    });
    const getVariantImage = () => {
        const configurableChildren = props === null || props === void 0 ? void 0 : props.configurable_children;
        let matchChild = null;
        configurableChildren === null || configurableChildren === void 0 ? void 0 : configurableChildren.forEach((child) => {
            let containOptions = 0;
            options === null || options === void 0 ? void 0 : options.forEach((option) => {
                var _a;
                const optionValue = (_a = option.value) === null || _a === void 0 ? void 0 : _a.value;
                const childAttribute = child[option.attributeCode];
                if (childAttribute &&
                    optionValue &&
                    childAttribute === Number(optionValue)) {
                    containOptions++;
                }
            });
            if (containOptions !== 0 && (options === null || options === void 0 ? void 0 : options.length) === containOptions)
                matchChild = child;
        });
        return matchChild === null || matchChild === void 0 ? void 0 : matchChild.image;
    };
    useEffect(() => {
        var _a, _b;
        const productOptions = (_b = (_a = props === null || props === void 0 ? void 0 : props.product_option) === null || _a === void 0 ? void 0 : _a.extension_attributes) === null || _b === void 0 ? void 0 : _b.configurable_item_options;
        if ((props === null || props === void 0 ? void 0 : props.type_id) === 'configurable' && (productOptions === null || productOptions === void 0 ? void 0 : productOptions.length) > 0) {
            const variantOptions = getVariantOptions(productOptions);
            (variantOptions === null || variantOptions === void 0 ? void 0 : variantOptions.length) > 0 && setOptions(variantOptions);
        }
        else
            setOptions(null);
    }, [props]);
    const formattedPrice = PriceUtils.getFormattedPrice((_a = props.totals) === null || _a === void 0 ? void 0 : _a.price, (_b = props.totals) === null || _b === void 0 ? void 0 : _b.price_incl_tax);
    return (React.createElement(StyledProduct, { last: props.last },
        React.createElement(StyledImage, { src: ThumbnailUtils.getImageSrc({
                image: ((props === null || props === void 0 ? void 0 : props.type_id) === 'configurable'
                    ? getVariantImage()
                    : props === null || props === void 0 ? void 0 : props.image) || 'default.jpg',
                entity: '',
            }), alt: (props === null || props === void 0 ? void 0 : props.name) || 'image product', onClick: () => openUrlProduct(props.url_key) }),
        React.createElement(StyledContent, null,
            (props === null || props === void 0 ? void 0 : props.showId) && (props === null || props === void 0 ? void 0 : props.id) && React.createElement(StyledId, null,
                "ID: ",
                props.id),
            React.createElement(StyledTitle, { onClick: () => openUrlProduct(props.url_key) }, props === null || props === void 0 ? void 0 : props.name),
            React.createElement(StyledCount, null, options === null || options === void 0 ? void 0 :
                options.map((option, index) => {
                    var _a;
                    return (React.createElement(StyledConfiguredLabel, { key: index }, (_a = option.value) === null || _a === void 0 ? void 0 : _a.label));
                }),
                React.createElement(TranslatePlural, { id: "plural.count", count: qty })),
            props.withChangeCount ? (React.createElement(StyledPricing, null,
                React.createElement(StyledCounter, null,
                    !isLoading && (props === null || props === void 0 ? void 0 : props.qty) && (React.createElement(QuantityInput, { maximum: (_c = props === null || props === void 0 ? void 0 : props.stock) === null || _c === void 0 ? void 0 : _c.qty, initialValue: qty, onChange: (e) => handleQuantity(e) })),
                    isLoading && (React.createElement(StyledContainerAnimated, null,
                        React.createElement(Spinner, null)))),
                ((_d = props.totals) === null || _d === void 0 ? void 0 : _d.price) && ((_e = props.totals) === null || _e === void 0 ? void 0 : _e.price_incl_tax) ? (React.createElement(StyledPrices, null,
                    React.createElement(StyledGross, null,
                        formattedPrice.price_incl_tax,
                        false &&
                            PriceUtils.isSpecialTypePrice(price.type) && (price === null || price === void 0 ? void 0 : price.original_price_incl_tax) && (React.createElement(ProductOriginalPrice, null, price === null || price === void 0 ? void 0 : price.original_price_incl_tax)),
                        React.createElement(StyledTax, null,
                            "z VAT ",
                            translate('common.per_unit_short'))),
                    React.createElement(StyledNet, null,
                        formattedPrice.price,
                        " netto"))) : (React.createElement(StyledContainerAnimated, { noBorder: true },
                    React.createElement(Spinner, null))))) : ((_f = props.totals) === null || _f === void 0 ? void 0 : _f.price) && ((_g = props.totals) === null || _g === void 0 ? void 0 : _g.price_incl_tax) ? (React.createElement(ProductPriceWrapper, null,
                React.createElement(ProductPriceContainer, null,
                    React.createElement(ProductPrice, null, formattedPrice.price_incl_tax),
                    false &&
                        PriceUtils.isSpecialTypePrice(price.type) &&
                        price.original_price_incl_tax && (React.createElement(ProductOriginalPrice, null, price.original_price_incl_tax)),
                    "\u00A0",
                    translate('common.per_unit_short')))) : (React.createElement(StyledContainerAnimated, { noBorder: true },
                React.createElement(Spinner, null)))),
        React.createElement(StyledDelete, { onClick: () => handleDelete() },
            React.createElement(Icon, { width: 20, height: 17, icon: "trash" }))));
};
