import styled from 'styled-components';
export const ProductBadgesContainer = styled.div `
  position: absolute;
  display: flex;
  row-gap: 5px;
  column-gap: 5px;
  z-index: 1;
  flex-wrap: wrap;
  width: calc(100% -5px);
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px;`}
  z-index: 10;
`;
export const ProductBadge = styled.div `
  display: flex;
  align-items: center;
  background: ${(props) => props.type === 'discount'
    ? '#D9000D'
    : props.type === 'bestseller'
        ? '#000'
        : '#525252'};
  color: white;
  padding: 0 5px;
  font-size: 12px;
  line-height: 21px;
`;
