import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { useLang } from '@@Hooks/useLang';
import { ProductUtils } from '@adsel/libstorefront';
import { useParams } from 'react-router-dom';
import { getProductSpecification } from '@@Helpers/getProductSpecification';
import { getProductByUrl } from '@@Storefront/actions/product/getProductByUrl';
import { Title, NameWrapper, SpecificationContainer, SpecificationWrapper, SpecificationRow, ValueWrapper, Value, Name, } from './styled';
export const ProductSpecification = ({ product }) => {
    const { translate } = useLang();
    const params = useParams();
    const [specification, setSpecification] = useState(null);
    useEffect(() => {
        if (product) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                let configurableChild = null;
                let productSpecification = null;
                if (ProductUtils.isConfigurableProduct(product) && (params === null || params === void 0 ? void 0 : params.urlKeyVariant)) {
                    configurableChild = yield getProductByUrl(params.urlKeyVariant);
                    if (configurableChild)
                        productSpecification = getProductSpecification(configurableChild);
                }
                if ((productSpecification === null || productSpecification === void 0 ? void 0 : productSpecification.length) > 0) {
                    setSpecification(productSpecification);
                }
                else {
                    productSpecification = getProductSpecification(product);
                    setSpecification(productSpecification);
                }
            }))();
        }
    }, [product, params === null || params === void 0 ? void 0 : params.urlKeyVariant]);
    return (React.createElement(React.Fragment, null, product && (specification === null || specification === void 0 ? void 0 : specification.length) > 0 && (React.createElement(SpecificationWrapper, null,
        React.createElement(Title, null, translate('products.technical_specifications')),
        React.createElement(SpecificationContainer, null, specification.map((element, index) => {
            var _a, _b, _c;
            return (React.createElement(SpecificationRow, { key: index },
                React.createElement(NameWrapper, null,
                    React.createElement(Name, null, element.label)),
                React.createElement(ValueWrapper, null, Array.isArray(element.value) &&
                    ((_a = element.value) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement(Value, null, (_b = element.value) === null || _b === void 0 ? void 0 : _b.map((item, index) => {
                    var _a;
                    return (React.createElement(React.Fragment, null,
                        React.createElement("span", { key: index }, item === null || item === void 0 ? void 0 : item.label),
                        React.createElement("span", null, index + 1 !== ((_a = element.value) === null || _a === void 0 ? void 0 : _a.length) && ', ')));
                }))) : (React.createElement(Value, null, (_c = element.value) === null || _c === void 0 ? void 0 : _c.label)))));
        }))))));
};
