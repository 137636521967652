import styled from 'styled-components';
export const CheckoutLayoutWrapper = styled.div `
  height: 100%;
`;
export const CheckoutPageWrapper = styled.div `
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  max-width: 1440px;
  ${(props) => props.theme.mixins.mobile(`
    flex-flow: column;
  `)}
`;
export const CheckoutRouterWrapper = styled.div `
  flex-grow: 2;
  width: 100%;
  margin-right: 116px;
  @media (max-width: 1000px) {
    margin-right: 0;
  }
`;
export const CheckoutManagePanel = styled.div `
  display: flex;
`;
export const Image = styled.img `
  object-fit: cover;
  width: 100%;
  height: 100vh;
`;
