import React from 'react';
import { CheckoutPriceContainer, CheckoutPriceEntryRow, } from '../Summary/styled';
import { translate } from '@@Helpers/translateGlobal';
import { CheckoutSummaryDivider, CheckoutSummaryBigPrice } from './styled';
import { PriceUtils } from '@@Storefront';
export const OrderSegments = ({ selectedShipping = true, totals = [], }) => {
    const totalsTop = totals.filter((total) => total.area !== 'footer');
    const totalsBottom = totals.filter((total) => total.area === 'footer');
    const displayTotals = (totals) => (totals || []).map((total) => {
        var _a, _b;
        const title = total.code === 'grand_total__incl_tax' ? (React.createElement("strong", null, total.title)) : (total.title);
        const isShipping = total.code === 'shipping__incl_tax' ||
            total.code === 'shipping__excl_tax';
        const value = isShipping && !selectedShipping ? (React.createElement(React.Fragment, null,
            translate('checkout.shipping_before_selected_1'),
            React.createElement("br", null),
            translate('checkout.shipping_before_selected_2'))) : (React.createElement(React.Fragment, null, total.code === 'grand_total__incl_tax' ? (React.createElement(CheckoutSummaryBigPrice, null, (_a = PriceUtils.getFormattedPrice(total.value, null, false)) === null || _a === void 0 ? void 0 : _a.price)) : ((_b = PriceUtils.getFormattedPrice(total.value, null, false)) === null || _b === void 0 ? void 0 : _b.price)));
        return total.title && typeof total.value === 'number' ? (React.createElement(CheckoutPriceEntryRow, null,
            React.createElement("div", null, title),
            React.createElement("div", null, value))) : ('');
    });
    return (React.createElement(CheckoutPriceContainer, null,
        displayTotals(totalsTop),
        React.createElement(CheckoutSummaryDivider, null),
        displayTotals(totalsBottom)));
};
