import { __awaiter } from "tslib";
import React, { useState, useEffect } from 'react';
import { translate } from '@@Helpers/translateGlobal';
import { useSelector } from 'react-redux';
import { checkoutSelector } from '@@Redux/selectors/checkout.selectors';
import LSF from '@@Storefront/index';
import { SelectedLocationWrapper } from './styles';
import { isInpost } from '@@Storefront/utils/is-inpost';
export const ShippingInformation = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const checkout = useSelector(checkoutSelector);
    const locationId = (_b = (_a = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails) === null || _a === void 0 ? void 0 : _a.extension_attributes) === null || _b === void 0 ? void 0 : _b.location_id;
    const locations = useSelector((state) => { var _a; return (_a = state.storelocator) === null || _a === void 0 ? void 0 : _a.locations; }); // @Mateusz Py Jak tu ogarnąć typ?
    const [selectedLocation, setSelectedLocation] = useState();
    const [countries, setCountries] = useState([]);
    const paczkomatId = (_d = (_c = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails) === null || _c === void 0 ? void 0 : _c.extension_attributes) === null || _d === void 0 ? void 0 : _d.paczkomaty_target_point;
    const getCountryName = (countryId) => {
        var _a;
        const name = (_a = countries === null || countries === void 0 ? void 0 : countries.find((country) => country.id === countryId)) === null || _a === void 0 ? void 0 : _a.full_name_locale;
        return name || countryId;
    };
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const countries = yield LSF.CountriesService.getCountriesList();
                setCountries(countries.filter((c) => c.full_name_locale));
            }
            catch (error) { }
        }))();
    }, []);
    useEffect(() => {
        if (locations && locationId) {
            const selectedLocation = locations.find((location) => Number(location === null || location === void 0 ? void 0 : location.location_id) === Number(locationId));
            setSelectedLocation(selectedLocation || null);
        }
        else {
            setSelectedLocation(null);
        }
    }, [locations, locationId]);
    return (React.createElement("div", null,
        translate('checkout_form.shipping_method'),
        ":\u00A0",
        React.createElement("span", null, (_f = (_e = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails) === null || _e === void 0 ? void 0 : _e.shippingMethod) === null || _f === void 0 ? void 0 :
            _f.carrier_title,
            " "),
        ((_h = (_g = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails) === null || _g === void 0 ? void 0 : _g.shippingMethod) === null || _h === void 0 ? void 0 : _h.method_code) ===
            'mpstorepickup' && selectedLocation ? (React.createElement(SelectedLocationWrapper, null,
            React.createElement("b", null, selectedLocation.name),
            React.createElement("br", null),
            selectedLocation.street,
            React.createElement("br", null),
            selectedLocation.city,
            React.createElement("br", null),
            getCountryName(selectedLocation.country),
            React.createElement("br", null),
            "tel. ",
            selectedLocation.phone_one)) : (React.createElement(React.Fragment, null,
            React.createElement("span", null, (_k = (_j = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails) === null || _j === void 0 ? void 0 : _j.shippingMethod) === null || _k === void 0 ? void 0 :
                _k.method_title,
                ' '))),
        isInpost((_m = (_l = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails) === null || _l === void 0 ? void 0 : _l.shippingMethod) === null || _m === void 0 ? void 0 : _m.method_code) &&
            paczkomatId ? (React.createElement(SelectedLocationWrapper, null,
            translate('checkout.selected_paczkomat'),
            ":",
            ' ',
            React.createElement("strong", null, paczkomatId))) : (React.createElement(React.Fragment, null))));
};
