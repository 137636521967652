import { __awaiter } from "tslib";
import { useLang } from '@@Hooks/useLang';
import { useSelector } from 'react-redux';
import { dispatch } from '@@Redux/dispatch';
import React, { useRef, useState } from 'react';
import { notify } from '@@Helpers/notifications';
import { register } from '@@Redux/thunks/user.thunks';
import FormRowsWrapper from '@@Components/FormRowsWrapper';
import { NotificationType } from '@grupakmk/libstorefront';
import { userIsRegisterInProgress } from '@@Redux/selectors/user.selectors';
import { UserDataFormMapper } from '@@App/src/lib/storefront/model-mappers/user-data-form-mapper';
import { UserDataForm } from '@@Forms/UserDataForm';
import { UserNewsletterSubscriptionForm, } from '@@Forms/UserNewsletterSubscriptionForm';
import { UserConsentsForm } from '@@Forms/UserConsentsForm';
import SocialLoginButtons from '@@Components/SocialLoginButtons';
import { RegisterButton, RegisterFormWrapper, UserConsentsFormWrapper, } from './styles';
export const RegisterForm = () => {
    const { translate } = useLang();
    const userDataFormMapper = new UserDataFormMapper();
    const userDataFormRef = useRef(); // TODO define type
    const userMarkeringConsentsFormRef = useRef();
    const userNewsletterSubscriptionFormRef = useRef();
    const isRegisterInProgress = useSelector(userIsRegisterInProgress);
    const [isUserConsentsFormValid, setIsUserConsentsFormValid] = useState(false);
    const [isUserDataFormValid, setIsUserDataFormValid] = useState(false);
    const isValid = () => isUserConsentsFormValid && isUserDataFormValid;
    const handleRegisterButtonClick = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!isValid()) {
            userDataFormRef.current.triggerShowValidation();
            userMarkeringConsentsFormRef.current.trigger();
            return;
        }
        const isSubscribed = userNewsletterSubscriptionFormRef.current.getFormContent().newsletter;
        const customer = userDataFormMapper.transformToLibType(userDataFormRef.current.getFormContent(), userMarkeringConsentsFormRef.current.getFormContent(), isSubscribed);
        const { password } = userDataFormRef.current.getFormContent();
        try {
            yield dispatch(register({
                customer: customer,
                password,
            }));
            notify(translate('customer.youHaveBeenRegistered'), NotificationType.SUCCESS);
        }
        catch (error) {
            if ((_a = error === null || error === void 0 ? void 0 : error.error) === null || _a === void 0 ? void 0 : _a.includes('same email address')) {
                notify(translate('customer.registrationErrorAccountExist'), NotificationType.ERROR);
            }
            else
                notify(translate('customer.registrationError'), NotificationType.ERROR);
        }
    });
    const onUserConsentsFormChange = (event) => {
        setIsUserConsentsFormValid(event.isValid);
    };
    const onUserDataFormChange = (event) => {
        setIsUserDataFormValid(event.isValid);
    };
    return (React.createElement(RegisterFormWrapper, null,
        React.createElement(FormRowsWrapper, null,
            React.createElement(UserDataForm, { isInCheckout: false, validationMode: "onChange", onChange: onUserDataFormChange, ref: userDataFormRef }),
            React.createElement(UserConsentsFormWrapper, null,
                React.createElement(UserConsentsForm, { onChange: onUserConsentsFormChange, ref: userMarkeringConsentsFormRef })),
            React.createElement(UserNewsletterSubscriptionForm, { style: { marginTop: '10px', textAlign: 'left' }, ref: userNewsletterSubscriptionFormRef }),
            React.createElement(RegisterButton, { appearance: "primary", loading: isRegisterInProgress, disabled: isRegisterInProgress, onClick: handleRegisterButtonClick }, translate('actions.create_account')),
            React.createElement(SocialLoginButtons, { title: translate('account.or_register_with_account'), socials: ['facebook', 'google'] }))));
};
