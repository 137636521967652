import styled from 'styled-components';
export const TextWrapper = styled.div `
  width: 80%;
  @media screen and (max-width: 1135px) {
    width: 100%;
  }
  ${(props) => props.theme.mixins.mobile('padding-bottom: 30px;')}
`;
export const TextContent = styled.div `
  margin: 0;
  font-size: 16px;
  line-height: 26px;
  @media screen and (max-width: 960px) {
    font-size: 14px;
    line-height: 23px;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  ul,
  ol {
    margin: unset;
    padding: unset;
    list-style-position: inside;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  .generated-video-container {
    position: relative;
    padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%); /* 16:9 */
    height: 0;
    margin: 20px 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;
