import styled from 'styled-components';
export const RadiosWrapper = styled.div `
  display: flex;
  padding-bottom: 5px;
  > * + * {
    margin-left: 10px;
  }
`;
export const SubmitButtonWrapper = styled.div `
  padding-top: 20px;
  button {
    width: 250px;
  }
`;
export const CheckoutFormWrapper = styled.div `
  --form-horizontal-gap: 15px;
  --form-vertical-gap: 14px;
  padding-top: 10px;

  .form-row + .form-row {
    margin-top: var(--form-vertical-gap);
  }
`;
export const TwoFieldsWrapper = styled.div `
  ${(props) => !props.single &&
    `
    display: grid;
    grid-template-columns: 1fr 210px;
    grid-gap: var(--form-horizontal-gap);

    @media screen and (max-width: 900px) {
      display: block;

      > div + div {
        margin-top: var(--form-vertical-gap);
      }
    }
  `}
`;
