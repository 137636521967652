import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
import { translate } from '@kmk/common/src/helpers/translateGlobal';
import { CARatyButtonContainer } from './styled';
export const CARatyButton = ({ productPriceGross, addOfferId, }) => {
    const mageConfig = useSelector(mageConfigSelector);
    const [buttonImgSrc, setButtonImgSrc] = React.useState('');
    const [buttonHref, setButtonHref] = React.useState();
    useEffect(() => {
        var _a;
        const caRatyConfig = (_a = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.payment) === null || _a === void 0 ? void 0 : _a.ca_raty;
        const buttonImgSrc = caRatyConfig === null || caRatyConfig === void 0 ? void 0 : caRatyConfig.button_simulator_url;
        setButtonImgSrc(buttonImgSrc);
        const specialPriceOfferId = caRatyConfig === null || caRatyConfig === void 0 ? void 0 : caRatyConfig.special_price_offer_id;
        const offerIdPart = addOfferId && specialPriceOfferId
            ? `&offerId=${specialPriceOfferId}`
            : '';
        if (caRatyConfig && productPriceGross) {
            setButtonHref(caRatyConfig.product_simulator_url + productPriceGross + offerIdPart);
        }
    }, [mageConfig, productPriceGross, addOfferId]);
    return buttonImgSrc && buttonHref ? (React.createElement(CARatyButtonContainer, null,
        React.createElement("a", { href: buttonHref, target: "_blank", rel: "noopener noreferrer" },
            React.createElement("img", { src: buttonImgSrc, alt: translate('payment.ca_raty.calculate_installment') })))) : (React.createElement(React.Fragment, null));
};
