import styled from 'styled-components';
export const HomePageWrapper = styled.div `
  width: 100%;
`;
export const HomePageSection = styled.div `
  padding: ${(props) => props.padding || '1.8rem 15px 0 15px'};
  ${(props) => props.narrow &&
    `
    max-width: ${props.theme.pages.homePage.maxWidth};
    margin: 0 auto;
  `}
  ${(props) => props.mobilePadding &&
    `
    @media screen and (max-width: 1200px) {
      padding: ${props.mobilePadding};
    }
  `}
  ${(props) => props.smallScreenPadding &&
    `
    @media screen and (max-width: 499px) {
      padding: ${props.smallScreenPadding};
    }
  `}
`;
