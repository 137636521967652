import styled from 'styled-components';
import Button from '@@Components/Buttons/Button';
export const ChangeUserPasswordFormContainer = styled.div `
  max-width: 439px;
  ${(props) => props.theme.mixins.mobile(`
    width: 100%; 
    max-width: none;
  `)}
`;
export const StyledForm = styled.form `
  padding-top: 30px;
  > *:not(:nth-last-child(2)) {
    padding-bottom: 15px;
  }
`;
export const StyledButton = styled(Button) `
  ${(props) => !props.isVisible && 'display: none'};
  max-width: 250px;
  margin-top: 25px;
  @media (max-width: 700px) {
    max-width: none;
    width: 100%;
  }
`;
export const Title = styled.p `
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;
