import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { LowestPriceWatermark, WatermarkImage, } from '@@Components/LowerPriceGuaranteeBadge/styled';
import { ThumbnailUtils } from '@@Storefront';
import { getBadgeMedia } from '@@Storefront/actions/media/getBadgeMedia';
export const LowerPriceGuaranteeBadge = ({ product, variant = 'tile', }) => {
    const [image, setImage] = useState(null);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const badges = yield getBadgeMedia();
            setImage((badges === null || badges === void 0 ? void 0 : badges.length) > 0 ? badges[0] : null);
        }))();
    }, []);
    if (!(product === null || product === void 0 ? void 0 : product.badge_gwarancja_najnizszej_ceny) || !image)
        return React.createElement(React.Fragment, null);
    return (React.createElement(LowestPriceWatermark, { variant: variant },
        React.createElement(WatermarkImage, { alt: "lowest price", src: ThumbnailUtils.getImageSrc({
                image: image.path,
                entity: '',
            }) })));
};
