import React from 'react';
import { ThumbnailUtils } from '@@Storefront';
import { ImagesContainer, ImageLeft, ImageRight, ImagesWrapper, } from '@@Components/TwoImagesBanner/styled';
export const TwoImagesBanner = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (React.createElement(ImagesWrapper, null,
        React.createElement(ImagesContainer, null,
            React.createElement(ImageLeft, { role: "img", "aria-label": ((_a = props === null || props === void 0 ? void 0 : props.banner[0]) === null || _a === void 0 ? void 0 : _a.title) || undefined, title: ((_b = props === null || props === void 0 ? void 0 : props.banner[0]) === null || _b === void 0 ? void 0 : _b.title) || undefined, background: ThumbnailUtils.getImageSrc({
                    image: ((_c = props.banner[0]) === null || _c === void 0 ? void 0 : _c.image[0].url) ? (_d = props.banner[0]) === null || _d === void 0 ? void 0 : _d.image[0].url : (_e = props.banner[0]) === null || _e === void 0 ? void 0 : _e.image[0].toString(),
                    entity: '',
                    height: 420,
                }) }),
            React.createElement(ImageRight, { role: "img", "aria-label": ((_f = props === null || props === void 0 ? void 0 : props.banner[1]) === null || _f === void 0 ? void 0 : _f.title) || undefined, title: ((_g = props === null || props === void 0 ? void 0 : props.banner[1]) === null || _g === void 0 ? void 0 : _g.title) || undefined, background: ThumbnailUtils.getImageSrc({
                    image: (_h = props.banner[1]) === null || _h === void 0 ? void 0 : _h.image[0].url,
                    entity: '',
                    height: 420,
                }) }))));
};
