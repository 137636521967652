import { __rest } from "tslib";
import styled from 'styled-components';
import React from 'react';
import InputErrorTooltip from '@@Components/InputErrorTooltip';
import useMergedRef from '@react-hook/merged-ref';
const InputWrapper = styled.div `
  margin-top: ${(props) => (props.placeholder ? '12' : '0')}px;
  position: relative;
  z-index: 1;
`;
const InputField = styled.input `
  box-sizing: border-box;
  height: 42px;
  width: 100%;
  font-size: 1.25rem;
  border: none;
  outline: none;
  padding: 11px 10px;
  line-height: 18px;
  border: 1px solid
    ${(props) => props.error && !props.disabled
    ? props.theme.colors.error
    : props.theme.input.borderColors.default};

  &[disabled] {
    cursor: not-allowed;
  }

  ${(props) => !props.error &&
    `
    &:focus {
      border: 1px solid ${props.theme.input.borderColors.focus};
    }
    &:not([disabled]):hover {
      border-color: ${props.theme.input.borderColors.hover};
    }
  `}
  &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => props.theme.input.normal.backgroundColor} inset;
  }

  &[disabled]:-webkit-autofill,
  &[disabled]:-webkit-autofill:hover,
  &[disabled]:-webkit-autofill:focus,
  &[disabled]:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px
      ${(props) => props.theme.input.disabled.backgroundColor} inset;
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  color: ${(props) => props.theme.input.normal.textColor};
  -webkit-text-fill-color: ${(props) => props.theme.input.normal.textColor};

  &[disabled] {
    color: ${(props) => props.theme.input.disabled.textColor};
    -webkit-text-fill-color: ${(props) => props.theme.input.disabled.textColor};
  }

  ::-webkit-input-placeholder {
    opacity: 1;
  }

  ::-moz-placeholder {
    opacity: 1;
  }

  :-ms-input-placeholder {
    opacity: 1;
  }

  &::placeholder {
    opacity: 1;
    text-transform: lowercase;
    color: ${(props) => props.theme.input.normal.placeholderTextColor};
    -webkit-text-fill-color: ${(props) => props.theme.input.normal.placeholderTextColor};

    &[disabled] {
      color: ${(props) => props.theme.input.disabled.placeholderTextColor};
      -webkit-text-fill-color: ${(props) => props.theme.input.disabled.placeholderTextColor};
    }
  }

  background: ${(props) => props.theme.input.normal.backgroundColor};

  &[disabled] {
    background: ${(props) => props.theme.input.disabled.backgroundColor};
  }

  filter: none; /* Disable autofill on firefox */

  /* remove rounded corners on iOS */
  border-radius: 0;
  -webkit-border-radius: 0;

  /* remove shadow on iOS */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;
const InputInnerWrapper = styled.div `
  position: relative;
`;
const InputLabel = styled.label `
  color: ${(props) => props.theme.input[props.disabled ? 'disabled' : 'normal'].textColor};
  background-color: ${(props) => props.theme.input[props.disabled ? 'disabled' : 'normal'].backgroundColor};
  font-size: 14px;
  padding: 0 5px 0 5px;
  line-height: 16px;
  font-weight: ${(props) => props.labelFontWeight || 600};
  color: #000;
  transition: all 100ms linear;
  position: absolute;
  display: inline-block;
  pointer-events: none;
  top: 0;
  left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
  transform: translateY(-50%);
`;
const InputErrorTooltipContainer = styled.div `
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
const defaultProps = {
    autoComplete: 'off',
};
export const InputBlik = React.forwardRef((props, ref) => {
    var _a;
    const { placeholder, error, onChange, type, onClick, initialValue, autoComplete, label, required, disabled } = props, inputProps = __rest(props, ["placeholder", "error", "onChange", "type", "onClick", "initialValue", "autoComplete", "label", "required", "disabled"]);
    const otherRef = React.useRef(null);
    const multiRef = useMergedRef(ref, otherRef);
    const inputType = type || 'text';
    const onInputChange = (e) => {
        const tempEvent = Object.assign({}, e);
        tempEvent.target.value = e.target.value;
        if (onChange)
            onChange(tempEvent);
    };
    const onInputClick = (e) => {
        if (onClick) {
            onClick(e);
        }
    };
    const handlePaste = (event) => {
        if (event.type === 'paste') {
            setTimeout(() => {
                var _a;
                if ((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value) {
                    event.target.value = event.target.value.trim();
                    if (onChange)
                        onChange(event);
                }
            }, 100);
        }
        return () => clearTimeout();
    };
    const changeEvents = {
        [inputType === 'number' ? 'onInput' : 'onChange']: onInputChange,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InputWrapper, null,
            React.createElement(InputInnerWrapper, null,
                ((!props.labelAuto && label) ||
                    (props.labelAuto && ((_a = otherRef.current) === null || _a === void 0 ? void 0 : _a.value))) && (React.createElement(InputLabel, { labelFontWeight: props.labelFontWeight, disabled: disabled }, `${required ? '*' : ''}${label}`)),
                React.createElement(InputField, Object.assign({ autoComplete: autoComplete, type: inputType, ref: multiRef, defaultValue: initialValue, placeholder: placeholder, error: !!(error === null || error === void 0 ? void 0 : error.type), onClick: onInputClick, "data-cy": `input ${props.name}`, "data-error": props.error && `input error ${props.name}`, onPaste: (event) => handlePaste(event) }, inputProps, changeEvents)),
                (error === null || error === void 0 ? void 0 : error.message) && !disabled && (React.createElement(InputErrorTooltipContainer, null,
                    React.createElement(InputErrorTooltip, { message: error.message })))))));
});
InputBlik.defaultProps = defaultProps;
export default InputBlik;
