import { __awaiter } from "tslib";
import React, { useState, useEffect } from 'react';
import { UserDataFormMapper, } from '@@Storefront/model-mappers/user-data-form-mapper';
import { useLang } from '@@Hooks/useLang';
import Input from '@@Components/Input/Input';
import { Select } from '@@Components/Select/Select';
import { userProfileSelector } from '@@Redux/selectors/user.selectors';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { validatePhoneNumber, validatePostalCode } from '@@Helpers/validators';
import { notify } from '@@Helpers/notifications';
import { NotificationType } from '@adsel/libstorefront';
import { updateUserProfile } from '@@Storefront/actions/user/updateUserProfile';
import { getCountriesList } from '@@Storefront/actions/countries/getCountriesList';
import { EditUserDataFormContainer, InputsWrapper, StyledButton, StyledForm, Title, } from './styled';
export const EditUserDataForm = () => {
    const userDataFormMapper = new UserDataFormMapper();
    const { register, handleSubmit, errors, control } = useForm({
        mode: 'onChange',
    });
    const { translate } = useLang();
    const userProfile = useSelector(userProfileSelector);
    const [isFormModified, setIsFormModified] = useState(false);
    const [userData, setUserData] = useState();
    const [countries, setCountries] = useState();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const user = userDataFormMapper.transformFromLibType(userProfile);
        setUserData(user);
    }, [userProfile]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const countries = yield getCountriesList();
            const countriesOptions = countries
                .filter((c) => c.full_name_locale)
                .map((c) => ({
                value: c.id,
                label: c.full_name_locale,
                regions: c.available_regions,
            }));
            setCountries(countriesOptions);
        }))();
    }, []);
    const onDataSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const newUserData = Object.assign(Object.assign({}, userData), { country: data.userCountry, zipCode: data.userPostalCode, city: data.userCity, firstName: data.userName, lastName: data.userLastname, phoneNumber: data.userPhone, streetAddress: data.userStreet, houseNo: data.userHouseNumber, apartmentNumber: data.userApartmentNumber });
            const transformedUserData = userDataFormMapper.transformToLibType(newUserData);
            yield updateUserProfile(transformedUserData);
            notify(translate('messages.user_data_updated_success'), NotificationType.SUCCESS);
        }
        catch (error) {
            notify(translate('messages.user_data_updated_error'), NotificationType.WARN);
        }
        setIsLoading(false);
    });
    const validateIsEmpty = (value) => {
        if (value && value !== '')
            return true;
        return translate('validation.required');
    };
    return (React.createElement(React.Fragment, null, userData && countries && (React.createElement(EditUserDataFormContainer, null,
        React.createElement(Title, null, translate('headers.account_data')),
        React.createElement(StyledForm, { "data-cy": "form editUserData", autoComplete: "none", noValidate: true, onSubmit: handleSubmit(onDataSubmit) },
            React.createElement(Input, { label: translate('checkout.email'), type: "email", name: "userEmail", readOnly: true, initialValue: userData.emailAddress }),
            React.createElement(Input, { label: translate('checkout.name'), type: "text", name: "userName", initialValue: userData.firstName, error: errors['userName'], ref: register({ validate: validateIsEmpty }), onChange: () => setIsFormModified(true), placeholder: `${translate('common.enter')} ${translate('checkout.name')}` }),
            React.createElement(Input, { label: translate('checkout.surname'), type: "text", name: "userLastname", initialValue: userData.lastName, error: errors['userLastname'], ref: register({ validate: validateIsEmpty }), onChange: () => setIsFormModified(true), placeholder: `${translate('common.enter')} ${translate('checkout.surname')}` }),
            React.createElement(Controller, { control: control, name: "userCountry", rules: { required: translate('validation.required') }, render: ({ ref, onBlur, onChange, value }) => (React.createElement(Select, { name: "userCountry", label: translate('checkout.country'), options: countries, value: value || userData.country, onInit: (value) => onChange(value.value), onBlur: onBlur, ref: ref, onChange: (value) => {
                        onChange(value.value);
                        setIsFormModified(true);
                    }, error: errors['userCountry'], placeholder: `${translate('common.select')} ${translate('checkout.country')}` })) }),
            React.createElement(Input, { label: translate('checkout.city'), type: "text", name: "userCity", ref: register({ validate: validateIsEmpty }), initialValue: userData.city, onChange: () => setIsFormModified(true), error: errors['userCity'], placeholder: `${translate('common.enter')} ${translate('checkout.city')}` }),
            React.createElement(Input, { label: translate('checkout.postal_code'), type: "text", name: "userPostalCode", initialValue: userData.zipCode, error: errors['userPostalCode'], ref: register({ validate: validatePostalCode }), onChange: () => setIsFormModified(true), placeholder: `${translate('common.enter')} ${translate('checkout.postal_code')}` }),
            React.createElement(Input, { label: translate('checkout.street'), type: "text", name: "userStreet", initialValue: userData.streetAddress, onChange: () => setIsFormModified(true), ref: register({ validate: validateIsEmpty }), error: errors['userStreet'], placeholder: translate('customer.enter_street_name') }),
            React.createElement(InputsWrapper, null,
                React.createElement(Input, { label: translate('checkout.building_number'), type: "text", name: "userHouseNumber", initialValue: userData.houseNo, onChange: () => setIsFormModified(true), ref: register(), placeholder: `${translate('common.enter')} ${translate('checkout.building_number')}` }),
                React.createElement(Input, { label: translate('checkout.flat_number'), type: "text", name: "userApartmentNumber", initialValue: userData.apartmentNumber, onChange: () => setIsFormModified(true), ref: register(), placeholder: `${translate('common.enter')} ${translate('checkout.flat_number')}` })),
            React.createElement(Input, { label: translate('checkout.phone'), type: "number", name: "userPhone", initialValue: userData.phoneNumber, error: errors['userPhone'], ref: register({ validate: validatePhoneNumber }), onChange: () => setIsFormModified(true), placeholder: translate('customer.enter_phone_number') }),
            React.createElement(StyledButton, { "data-cy": "saveDataButton", loading: isLoading, isVisible: isFormModified }, translate('actions.save_changes')))))));
};
