import styled from 'styled-components';
export const BannersSliderWrapper = styled.div `
  position: relative;
  margin-bottom: 50px;
  aspect-ratio: 1280 / 1040;
  ${(props) => props.theme.mixins.mobile('margin-bottom: 25px')}
  .swiper-container {
    height: 100%;
  }
`;
