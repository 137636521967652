import { __awaiter } from "tslib";
import React, { useState } from 'react';
import { subscribeToNewsletter, unsubscribeFromNewsletter, } from '@@Storefront/actions/newsletter';
import Button from '@@Components/Buttons/Button';
import { Popup } from '@@Components/Popup';
import { useLang } from '@@Hooks/useLang';
import { notify } from '@@Helpers/notifications';
import { NotificationType } from '@grupakmk/libstorefront';
import { userProfileSelector } from '@@Redux/selectors/user.selectors';
import { useSelector } from 'react-redux';
import { Spinner } from '@@Components/Spinner';
import { NewsletterContainer, NewsletterDescription, PopupContentContainer, SpinnerWrapper, StyledButton, StyledLink, Title, } from './styled';
export const NewsletterSubscriptionManagement = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const { translate } = useLang();
    const userProfile = useSelector(userProfileSelector);
    const sendActivationLink = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const { email } = userProfile;
        try {
            yield subscribeToNewsletter(email);
            setShowSuccessPopup(true);
        }
        catch (error) {
            notify(translate('newsletter.send_link_error'), NotificationType.ERROR);
        }
        setIsLoading(false);
    });
    const handleUnsubscribeFromNewsletter = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setIsLoading(true);
        const confirmCode = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.extension_attributes) === null || _a === void 0 ? void 0 : _a.subscriber_confirm_code;
        const { email } = userProfile;
        if (confirmCode && email) {
            try {
                yield unsubscribeFromNewsletter(email, confirmCode);
                notify(translate('newsletter.unsubscribe_success'), NotificationType.SUCCESS);
            }
            catch (error) {
                notify(translate('newsletter.unsubscribe_error'), NotificationType.ERROR);
            }
        }
        setIsLoading(false);
    });
    const renderSuccessPopup = () => {
        const popupContent = (React.createElement(PopupContentContainer, null,
            React.createElement(NewsletterDescription, { paddingBottom: 10 }, translate('newsletter.check_email_description_2')),
            isLoading ? (React.createElement(Spinner, { size: 20 })) : (React.createElement(Button, { appearance: "text", onClick: () => sendActivationLink() }, translate('actions.resend')))));
        return (React.createElement(Popup, { isOpened: showSuccessPopup, heading: "Newsletter", title: translate('headers.thank_you'), description: `${translate('newsletter.subscribe_success_description')} ${userProfile.email} ${translate('newsletter.subscribe_success_description_2')}`, content: popupContent, onClose: () => setShowSuccessPopup(false), imageUrl: "assets/image/popups/notify_about_availability.jpg" }));
    };
    const renderNewsletterSectionContent = () => {
        var _a;
        const subscriberStatus = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.extension_attributes) === null || _a === void 0 ? void 0 : _a.subscriber_status;
        if (subscriberStatus === '1') {
            return (React.createElement(React.Fragment, null,
                React.createElement(NewsletterDescription, { paddingBottom: 15 }, translate('newsletter.unsubscribe_description')),
                isLoading ? (React.createElement(SpinnerWrapper, null,
                    React.createElement(Spinner, { size: 20 }))) : (React.createElement(Button, { appearance: "text", onClick: () => handleUnsubscribeFromNewsletter() }, translate('actions.quit_newsletter')))));
        }
        else if (subscriberStatus === '2') {
            return (React.createElement(React.Fragment, null,
                React.createElement(NewsletterDescription, { paddingBottom: 15 }, translate('newsletter.check_email_description')),
                React.createElement(NewsletterDescription, { paddingBottom: 10 }, translate('newsletter.check_email_description_2')),
                isLoading ? (React.createElement(SpinnerWrapper, null,
                    React.createElement(Spinner, { size: 20 }))) : (React.createElement(Button, { appearance: "text", onClick: () => sendActivationLink() }, translate('actions.resend')))));
        }
        else {
            return (React.createElement(React.Fragment, null,
                React.createElement(NewsletterDescription, null,
                    translate('newsletter.subscribe_description') + ' ',
                    React.createElement(StyledLink, { to: "/polityka-prywatnosci" }, translate('newsletter.subscribe_description_2'))),
                React.createElement(StyledButton, { onClick: () => sendActivationLink(), loading: isLoading }, translate('actions.send_activation_link'))));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(NewsletterContainer, null,
            React.createElement(Title, null, "Newsletter"),
            renderNewsletterSectionContent()),
        renderSuccessPopup()));
};
