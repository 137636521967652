import styled from 'styled-components';
export const InpostErrorWrapper = styled.div `
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0 0 0;
  color: ${(props) => props.theme.colors.error};
  svg {
    fill: ${(props) => props.theme.colors.error};
  }
  text-decoration: underline;
`;
export const ShippingMethodNameContainer = styled.div `
  display: flex;
`;
export const ShippingMethodInfo = styled.div `
  width: 100%;
`;
export const ShippingMethodName = styled.div `
  font-weight: bold;
`;
export const ShippingMethodPrice = styled.div `
  margin-left: 5px;
`;
export const InpostMethodWrapper = styled.div `
  width: 100%;
  padding-bottom: 1px;
`;
export const InpostErrorLabel = styled.div `
  margin-left: 0.5rem;
`;
export const InpostPoint = styled.div `
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-left: 5px;
  }
`;
export const SubmitButtonWrapper = styled.div `
  width: 250px;
  margin-top: 20px;
`;
export const ShippingMethodWrapper = styled.div ``;
export const ShippingMethodsWrapper = styled.div `
  ${ShippingMethodWrapper} + ${ShippingMethodWrapper} {
    margin-top: 3px;
  }
`;
export const StoreLocatorWrapper = styled.div `
  margin-top: 5px;
`;
