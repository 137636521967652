import { __awaiter } from "tslib";
import React, { useState, useEffect, useRef } from 'react';
import Icon from '@@Components/Icon/Icon';
import { useLang } from '@@Hooks/useLang';
import { SearchTile } from '@@Components/SearchTile';
import { useClickOutside } from '@@Hooks/useClickOutside';
import Button from '@@Components/Buttons/Button';
import { searchProductsByPhrase } from '@@Storefront/actions/product/searchProductsByPhrase';
import { useDebounce } from '@@Hooks/useDebounce';
import { useRouter } from '@@Hooks/useRouter';
import GTMEvents from '@@Common/gtm';
import { ClearButton, ResultsContainer, ResultsContent, SearchBarContainer, SearchBarInput, SearchBarInputWrapper, ResultsContentWrapper, StyledLink, ResultsGradient, } from './styled';
export const SearchBar = ({ callback, onInputChange, onReturnPress, }) => {
    const [inputContent, setInputContent] = useState('');
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(true);
    const { translate } = useLang();
    const ref = useRef();
    const { goTo } = useRouter();
    useClickOutside(ref, () => {
        if (window.innerWidth > 1000) {
            setShowResults(false);
        }
    });
    useEffect(useDebounce(() => {
        if (callback)
            callback(inputContent.length);
        if (inputContent.length > 2) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                const products = yield searchProductsByPhrase(inputContent, 10);
                setResults(products);
                onInputChange && onInputChange(products);
            }))();
        }
        else if (results)
            setResults([]);
    }, 200), [inputContent]);
    const onKeyPress = (event) => {
        var _a;
        if (event.charCode === 13 && ((_a = inputContent === null || inputContent === void 0 ? void 0 : inputContent.trim()) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
            onReturnPress && onReturnPress();
            setInputContent('');
            goTo(`/search?p=${inputContent}`);
        }
    };
    const onProductLinkClick = (product) => {
        GTMEvents.push({
            event: 'searchSuggestedProductClick',
            keyword: inputContent,
            suggestedProductName: product.name,
        });
    };
    return (React.createElement(SearchBarContainer, { ref: ref, fullHeight: results.length > 0 },
        React.createElement(SearchBarInputWrapper, { className: "search-bar-wrapper" },
            React.createElement(SearchBarInput, { onChange: (event) => setInputContent(event.target.value), value: inputContent, placeholder: translate('actions.search'), onClick: () => setShowResults(true), onKeyPress: (e) => onKeyPress(e) }),
            !inputContent ? (React.createElement(Icon, { icon: "search" })) : (React.createElement(ClearButton, { onClick: () => setInputContent('') },
                React.createElement(Icon, { icon: "clear" })))),
        results.length > 0 && showResults && (React.createElement(ResultsContainer, null,
            React.createElement(ResultsContentWrapper, null,
                React.createElement(ResultsContent, null, results.slice(0, 10).map((result) => (React.createElement(SearchTile, { key: result.id, product: result, onProductLinkClick: onProductLinkClick })))),
                React.createElement(ResultsGradient, { disable: results.length < 6 })),
            results.length > 0 && showResults && (React.createElement(StyledLink, { href: `/search?p=${inputContent}` },
                React.createElement(Button, null, translate('actions.see_all'))))))));
};
