import styled from 'styled-components';
export const CategoryProductsSliderWrapper = styled.div ``;
export const CategoryProductsSlidersWrapper = styled.div `
  ${CategoryProductsSliderWrapper} + ${CategoryProductsSliderWrapper} {
    margin-top: 50px;
    @media screen and (max-width: 900px) {
      margin-top: 40px;
    }
  }
`;
