import styled from 'styled-components';
export const OrderPreviewSummaryContainer = styled.div `
  display: flex;
  flex-flow: row;

  @media (max-width: 1023px) {
    flex-flow: column;
  }

  margin-bottom: 25px;
`;
export const OrderPreviewSummaryItem = styled.div `
  flex: 1;

  @media (max-width: 1023px) {
    margin-bottom: 35px;
  }
`;
export const OrderPreviewSummaryHeading = styled.div `
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 25px;

  @media (max-width: 1023px) {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 15px;
  }
`;
export const OrderSummaryHeader = styled.div `
  display: flex;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-flow: column;
  }
`;
export const OrderSummaryItem = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
  ${(props) => props.last &&
    `
      border-top: 1px solid black;
      margin-top: 25px;
      padding-top: 10px;
      @media (max-width: 1023px) {
        margin-top: 15px;
        padding-top: 15px;
      }
  `}
`;
export const OrderSummaryLabel = styled.div `
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  & span {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
  }
`;
export const OrderSummaryValue = styled.div `
  color: #d9000d;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;

  @media (max-width: 1023px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
`;
