import styled from 'styled-components';
export const TextLinksBannerContainer = styled.div `
  position: relative;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #3b3b3b;
`;
export const TextLinksBannerItem = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: opacity 0.4s ease;
  color: #fff;
  line-height: 35px;
  font-size: 18px;
  ${(props) => props.visible
    ? 'opacity: 1; pointer-events: all;'
    : 'opacity: 0; pointer-events: none;'};
`;
