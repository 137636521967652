import Input from '@@Components/Input/Input';
import { useLang } from '@@Hooks/useLang';
import React, { useEffect, useState } from 'react';
import { userIsLoggedSelector } from '@@Redux/selectors/user.selectors';
import FormRowsWrapper from '@@Components/FormRowsWrapper';
import { useControlledForm, } from '@@Hooks/useControlledForm';
import { useSelector } from 'react-redux';
import { validateEmail, validatePassword } from '@@Helpers/validators';
import { Checkbox } from '@@Components/Input/Checkbox';
import { UserDataFormWrapper, OptionalRegistrationCheckboxWrapper, } from './styles';
export const UserDataForm = React.forwardRef(({ onChange = () => { }, withPassword = true, validationMode = 'onChange', optionalRegistration = false, onRegistrationChange = null, }, ref) => {
    const [isRegistration, setIsRegistration] = useState(false);
    const [showValidationForced, setShowValidationForced] = useState(true);
    const forceValidationShow = () => {
        setShowValidationForced(true);
    };
    useEffect(() => {
        if (typeof onRegistrationChange === 'function') {
            onRegistrationChange(isRegistration);
        }
    }, [isRegistration]);
    const { translate } = useLang();
    const { register, formWatch, formState, errors, handleSubmit, clearErrors, reset, } = useControlledForm({
        mode: validationMode,
        onChange,
        additionalRefMethods: {
            triggerShowValidation: () => {
                forceValidationShow();
                ref.current.trigger();
            },
        },
    }, ref);
    const isLogged = useSelector(userIsLoggedSelector);
    return (React.createElement(UserDataFormWrapper, null,
        React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: handleSubmit(() => { }), "data-cy": "form register" },
            React.createElement(FormRowsWrapper, null,
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "emailAddress", label: translate('customer.emailAddress'), placeholder: '*' + translate('customer.emailAddress'), error: (showValidationForced ||
                            formState.dirtyFields.emailAddress) &&
                            errors.emailAddress, ref: register({ validate: validateEmail }) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "firstName", label: translate('customer.firstName'), placeholder: '*' + translate('customer.firstName'), error: (showValidationForced || formState.dirtyFields.firstName) &&
                            errors.firstName, ref: register({ required: translate('validation.required') }) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "lastName", label: translate('customer.lastName'), placeholder: '*' + translate('customer.lastName'), error: (showValidationForced || formState.dirtyFields.lastName) &&
                            errors.lastName, ref: register({ required: translate('validation.required') }) })),
                optionalRegistration && (React.createElement(OptionalRegistrationCheckboxWrapper, null,
                    React.createElement(Checkbox, { checked: isRegistration, onChange: (isRegistration) => setIsRegistration(isRegistration) }, translate('checkout.i_want_to_register')))),
                withPassword && optionalRegistration === isRegistration && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { required: true, name: "password", label: translate('customer.password'), placeholder: '*' + translate('customer.password'), error: (showValidationForced ||
                                formState.dirtyFields.password) &&
                                errors.password, type: "password", ref: register({ validate: validatePassword }) })),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { required: true, name: "passwordRepeat", label: translate('customer.passwordConfirm'), placeholder: '*' + translate('customer.passwordConfirm'), error: (showValidationForced ||
                                formState.dirtyFields.passwordRepeat) &&
                                errors.passwordRepeat, type: "password", ref: register({
                                validate: (value) => (value && value === formWatch.password) ||
                                    translate('customer.passwordsDontMatch'),
                            }) }))))))));
});
