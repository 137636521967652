import styled from 'styled-components';
export const ProductsListContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
`;
export const ProductsContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const GridContainer = styled.div `
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;

  @media (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 650px) {
    gap: 10px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
export const GridItem = styled.div `
  height: 330px;
  width: 100%;
  @media (max-width: 1300px) {
    height: 310px;
  }
  @media (max-width: 550px) {
    height: 274px;
  }
`;
