import { __awaiter } from "tslib";
import Input from '@@Components/Input/Input';
import { useControlledForm, } from '@@Hooks/useControlledForm';
import { useLang } from '@@Hooks/useLang';
import { Heading } from '@@Components/Heading';
import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { validateEmail, validatePassword } from 'src/helpers/validators';
import FormRowsWrapper from '@@Components/FormRowsWrapper';
import Button from '@@Components/Buttons/Button';
import { notify } from '@@Helpers/notifications';
import { NotificationType } from '@adsel/libstorefront';
import LSF from '@@Storefront/index';
import { PageSpecificMeta } from '@@Components/PageSpecificMeta';
import { useRouter } from '@@Hooks/useRouter';
import { dispatch } from '@@Redux/dispatch';
import { login } from '@@Redux/thunks/user.thunks';
const ResetPasswordContainer = styled.div `
  display: flex;
  text-align: center;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
const ResetPasswordFormContainer = styled.div `
  max-width: 440px;
  margin: 0 auto;
`;
const SubmitButtonWrapper = styled.div `
  margin-top: 20px;
`;
export const HeadingWrapper = styled.div `
  display: flex;
  align-items: center;
  h1 {
    text-align: center;
    width: 100%;
  }
`;
export const LoginPageSection = styled.div `
  height: 95vh;
  text-align: center;
  flex: 1;
  font-size: 14px;
  padding: 30px;
  padding: 233px 0;
  @media screen and (max-width: 1000px) {
    padding: 150px 0;
  }
  ${(props) => props.backgroundImage &&
    `
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(${props.backgroundImage});
      min-height: 372px;
  `}
  @media (max-width: 1024px) {
    padding: 25px 15px;
  }
`;
export const ResetPasswordPage = (props) => {
    const { token } = props.match.params;
    const ref = useRef();
    const { translate } = useLang();
    const router = useRouter();
    const [passwordChangeInProgress, setPasswordChangeInProgress] = useState(false);
    const [, setFormIsValid] = useState(false);
    const [, setFormTouchedAfterSubmit] = useState(false);
    const [shouldRedirectAfterSuccess, setShouldRedirectAfterSuccess] = useState(false);
    const onFormChange = () => {
        const formState = ref.current.getFormState();
        const anyTouched = Object.keys(formState.touched).length > 0;
        setFormIsValid(formState.isValid);
        setFormTouchedAfterSubmit(anyTouched);
    };
    const onHandleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const formContent = ref.current.getFormContent();
        setPasswordChangeInProgress(true);
        try {
            yield LSF.UserService.createPassword(formContent.email, formContent.password, token);
            notify(translate('actions.your_password_change'), NotificationType.SUCCESS);
            setShouldRedirectAfterSuccess(true);
        }
        catch (error) {
            notify((error === null || error === void 0 ? void 0 : error.result) || translate('actions.change_password_error'), NotificationType.ERROR);
            setPasswordChangeInProgress(false);
        }
    });
    const { register, errors, handleSubmit } = useControlledForm({ mode: 'onChange', onChange: onFormChange }, ref);
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        if (shouldRedirectAfterSuccess) {
            const formContent = ref.current.getFormContent();
            yield dispatch(login({ email: formContent.email, password: formContent.password }));
            router.goTo('/account/edit');
        }
    });
    useEffect(() => {
        handleLogin();
    }, [shouldRedirectAfterSuccess]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageSpecificMeta, { title: translate('actions.reset_password') }),
        React.createElement(ResetPasswordContainer, null,
            React.createElement(LoginPageSection, null,
                React.createElement(ResetPasswordFormContainer, null,
                    React.createElement(HeadingWrapper, null,
                        React.createElement(Heading, { size: "h1" }, translate('actions.change_password'))),
                    React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: handleSubmit(onHandleSubmit) },
                        React.createElement(FormRowsWrapper, null,
                            React.createElement("div", { className: "form-row" },
                                React.createElement(Input, { required: true, name: "email", placeholder: '*' + translate('customer.emailAddress'), error: errors.email, ref: register({ validate: validateEmail }) })),
                            React.createElement("div", { className: "form-row" },
                                React.createElement(Input, { name: "password", placeholder: '*' + translate('customer.password'), error: errors.password, type: "password", ref: register({ validate: validatePassword }) })),
                            React.createElement(SubmitButtonWrapper, null,
                                React.createElement(Button, { appearance: "primary", loading: passwordChangeInProgress }, translate('actions.save'))))))),
            React.createElement(LoginPageSection, { backgroundImage: './assets/image/loginPage/reset_bg.jpg' }))));
};
export default ResetPasswordPage;
