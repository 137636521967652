import styled from 'styled-components';
export const LinksContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const PhoneMailLink = styled.a `
  margin: 0;
  cursor: pointer;
  ${(props) => props.underline && 'text-decoration: underline;'}
  ${(props) => props.bold && 'font-weight: 600;'}
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
  &:first-child {
    margin-bottom: 10px;
  }
`;
