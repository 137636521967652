import styled from 'styled-components';
export const OrderPreviewProductsListContainer = styled.div `
  margin-bottom: 35px;
  @media (max-width: 1023px) {
    margin-bottom: 25px;
  }
`;
export const OrderInfoElement = styled.div `
  margin-bottom: 5px;
`;
export const MobileOrderItem = styled.div `
  border-bottom: 1px solid #bababa;
`;
export const MobileOrderBoxWithImage = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & > div:first-of-type {
    margin-right: 15px;
    width: 60px;
  }
`;
export const MobileOrderBox = styled.div `
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  & > div:first-of-type {
    margin-right: 5px;
    width: 70px;
  }
`;
export const ConfigurableVariantOption = styled.span `
  color: #525252;
  margin-right: 5px;
`;
export const PriceBeforeDiscount = styled.span `
  text-decoration: line-through;
`;
