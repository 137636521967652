import { __awaiter } from "tslib";
import React, { useState, useEffect } from 'react';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { ProductTile } from '@@Components/ProductTile';
import { Heading } from '@@Components/Heading';
import { ResponsiveMatcher } from '@@Components/ResponsiveMatcher';
import Icon from '@@Components/Icon/Icon';
import { ProductsSliderContainer, SlideLeftButton, SlideRightButton, SliderWrapper, } from './styled';
import LSF from '@@App/src/lib/storefront';
export const ProductsSlider = React.forwardRef(({ products = [], allowTouchMove = true, shortSlider = false, autoplay = 0, title, href, }, ref) => {
    var _a, _b;
    const [swiperInstance, setSwiperInstance] = useState(null);
    const mobile = {
        breakpoint: 500,
        slidesPerView: shortSlider ? 1 : 2,
    };
    const tablet = {
        breakpoint: 900,
        slidesPerView: shortSlider ? 2 : 3,
    };
    const desktop = {
        breakpoint: 1200,
        slidesPerView: shortSlider ? 3 : 4,
    };
    const responsiveBreakpoints = {
        1: {
            slidesPerView: 1.04,
            spaceBetween: 0,
        },
        [mobile.breakpoint]: {
            slidesPerView: mobile.slidesPerView,
            spaceBetween: 10,
        },
        650: {
            slidesPerView: mobile.slidesPerView,
            spaceBetween: 25,
        },
        [tablet.breakpoint]: {
            slidesPerView: tablet.slidesPerView,
            spaceBetween: 25,
        },
        [desktop.breakpoint]: {
            slidesPerView: desktop.slidesPerView,
            spaceBetween: 25,
        },
    };
    if (ref) {
        React.useImperativeHandle(ref, () => ({
            updateSlider: () => {
                if (swiperInstance) {
                    swiperInstance.update();
                }
            },
        }), [swiperInstance]);
    }
    React.useEffect(() => {
        if (autoplay && swiperInstance) {
            const interval = setInterval(() => {
                swiperInstance.slideNext();
            }, autoplay);
            return () => clearInterval(interval);
        }
    }, [swiperInstance]);
    const [productsWithPrices, setProductsWithPrices] = useState();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const priceMissingIds = products === null || products === void 0 ? void 0 : products.map((item) => !item.prices && Number(item.id)).filter((item) => typeof item === 'number');
            if (priceMissingIds === null || priceMissingIds === void 0 ? void 0 : priceMissingIds.length) {
                const prices = yield LSF.ProductService.getProductPrices(priceMissingIds);
                setProductsWithPrices(products.map((item) => (Object.assign(Object.assign({}, item), { prices: prices.find((price) => price.id === item.id) }))));
            }
        }))();
    }, [products]);
    // Allow loop depends on window size and products amount for prevent slider bug that is appear when
    // number of products is less then slidesPerView
    const decideIsLoop = (elements) => {
        if ((elements === null || elements === void 0 ? void 0 : elements.length) < 1)
            return false;
        if ((window.innerWidth >= desktop.breakpoint &&
            elements.length > desktop.slidesPerView) ||
            (window.innerWidth < desktop.breakpoint &&
                window.innerWidth >= tablet.breakpoint &&
                elements.length > tablet.slidesPerView) ||
            (window.innerWidth < tablet.breakpoint &&
                window.innerWidth >= mobile.breakpoint &&
                elements.length > mobile.slidesPerView) ||
            (window.innerWidth < mobile.breakpoint &&
                elements.length >= mobile.slidesPerView))
            return true;
        return false;
    };
    const renderHeader = (size = 'h2') => {
        const heading = (React.createElement(Heading, { size: size, style: { marginBottom: '25px' } }, title));
        if (href) {
            return (React.createElement("a", { href: href, target: '_self', title: title, style: { display: 'inline-block' } }, heading));
        }
        else {
            return heading;
        }
    };
    return (React.createElement(React.Fragment, null, (productsWithPrices === null || productsWithPrices === void 0 ? void 0 : productsWithPrices.length) > 0 && (React.createElement(ProductsSliderContainer, null,
        title && (React.createElement(ResponsiveMatcher, { mobileBreakpoint: 900, desktop: renderHeader('h2'), mobile: renderHeader('h4') })),
        React.createElement(SliderWrapper, { shortSlider: shortSlider },
            window.innerWidth > 499 &&
                products.length > ((_a = swiperInstance === null || swiperInstance === void 0 ? void 0 : swiperInstance.params) === null || _a === void 0 ? void 0 : _a.slidesPerView) && (React.createElement(SlideLeftButton, { onClick: () => swiperInstance && swiperInstance.slidePrev() },
                React.createElement(Icon, { icon: "prev_slide", width: 10, height: 22 }))),
            React.createElement(SwiperComponent, { onSwiper: setSwiperInstance, loop: decideIsLoop(products), allowTouchMove: allowTouchMove, breakpoints: responsiveBreakpoints, style: { height: '100%' }, threshold: 4 }, productsWithPrices.map((product, i) => (React.createElement(SwiperSlide, { key: i, style: { height: '100%' } },
                React.createElement(ProductTile, { product: product }))))),
            window.innerWidth > 499 &&
                productsWithPrices.length > ((_b = swiperInstance === null || swiperInstance === void 0 ? void 0 : swiperInstance.params) === null || _b === void 0 ? void 0 : _b.slidesPerView) && (React.createElement(SlideRightButton, { onClick: () => swiperInstance && swiperInstance.slideNext() },
                React.createElement(Icon, { icon: "next_slide", width: 10, height: 22 }))))))));
});
