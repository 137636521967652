import React, { useState, useEffect } from 'react';
import { TextLinksBannerContainer, TextLinksBannerItem } from './styled';
export const TextLinksBanner = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        let interval;
        const onInterval = () => {
            setCurrentIndex((currentIndex) => (currentIndex + 1) % props.items.length);
        };
        interval = setInterval(onInterval, 5000);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);
    return (React.createElement(TextLinksBannerContainer, null, props.items.map((item, index) => (React.createElement(TextLinksBannerItem, { visible: currentIndex === index },
        React.createElement("a", { href: item.url }, item.title))))));
};
