import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { ElasticSearchEntityType } from '@grupakmk/libstorefront';
import LSF, { SearchQuery } from '@@Storefront';
import CategoryProductsSlider from '@@Pages/HomePage/HomePageProductsSlider/CategoryProductsSlider';
import { Shell } from '@@Components/Shell';
import { CategoryProductsSlidersWrapper, CategoryProductsSliderWrapper, } from './styled';
const loadCategories = () => __awaiter(void 0, void 0, void 0, function* () {
    const searchQuery = new SearchQuery();
    const size = 12;
    searchQuery.applyFilter({
        key: 'show_products_homepage_slider',
        value: true,
    });
    const categories = yield LSF.SearchClient.searchByQuery({
        query: searchQuery,
        size,
        entityType: ElasticSearchEntityType.Category,
    });
    return categories.items;
});
const HomePageProductsSlider = () => {
    const [categories, setCategories] = useState([]);
    const [showBottomProductSlider, setShowBottomProductSlider] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            loadCategories().then((res) => res && setCategories(res));
            setShowBottomProductSlider(false);
        }, 1000);
    }, []);
    return (React.createElement(React.Fragment, null, showBottomProductSlider ? (React.createElement(Shell, { height: '416px', width: '100%' })) : (React.createElement(CategoryProductsSlidersWrapper, null, categories.map((category, index) => (React.createElement(CategoryProductsSliderWrapper, { key: index },
        React.createElement(CategoryProductsSlider, { key: category.id, categoryId: category.id, title: category.products_homepage_slider_title || category.name, href: category.url_path }))))))));
};
export default HomePageProductsSlider;
