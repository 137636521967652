import { __awaiter } from "tslib";
import React, { useEffect, useReducer, useState } from 'react';
import { useCategoryTree } from '@@Hooks/useCategoryTree';
import Icon from '@@Components/Icon/Icon';
import { useRouter } from '@@Hooks/useRouter';
import { useLang } from '@@Hooks/useLang';
import { useCategory } from '@@Hooks/useCategory';
import { searchProductsByPhrase } from '@@Storefront/actions/product/searchProductsByPhrase';
import { categoriesMap } from '@@Redux/selectors/category';
import qs from 'query-string';
import { useSelector } from 'react-redux';
import { Spinner } from '@@Components/Spinner';
import { Button } from '@@Components/Buttons/Button';
import { reducer, includedId, getCategoryChildrenIds, getCategoryTreeNodeChildrenIds, getProductsCategoryTree, } from './utility';
import { CategoryTreeContainer, CategoryTreeNodeItem, CategoryTreeNodeItemChildren, CategoryTreeNodeItemName, CategoryTreeNodeItemNameButton, CategoryTitle, CategoryTreeNodeItemButton, ButtonWrapper, } from './styled';
export const CategoryTree = ({ categoryId = 54, noTitle = false, mobileMode = false, onNodeClick, largeTop, openFirstLevel = false, }) => {
    var _a, _b;
    const categoriesTree = useCategoryTree(categoryId);
    const { translate } = useLang();
    const phrase = (_a = qs.parse(location.search)) === null || _a === void 0 ? void 0 : _a.p;
    const searchCategoryId = (_b = qs.parse(location.search)) === null || _b === void 0 ? void 0 : _b.category;
    const { currentCategory, categories } = useCategory();
    const categoriesRedux = useSelector(categoriesMap);
    const [expanded, dispatch] = useReducer(reducer, []);
    const [categoryTree, setCategoryTree] = useState([]);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const toggleCategory = (categoryId) => {
        dispatch({ type: 'TOGGLE_CATEGORY', payload: categoryId });
    };
    const resetCategories = (categoriesIds) => {
        dispatch({ type: 'RESET_CATEGORIES', payload: categoriesIds });
    };
    const buildExpandedCategoryTree = (currentCategory) => {
        var _a;
        if (currentCategory && ((_a = Object.keys(categories)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            let categoriesIds = [];
            const buildTreeFromRootToCurrent = () => {
                let nextCategoryId = currentCategory.parent_id;
                categoriesIds = [currentCategory.id];
                while (!!nextCategoryId) {
                    categoriesIds.push(nextCategoryId);
                    if (nextCategoryId && categories[nextCategoryId]) {
                        nextCategoryId = categories[nextCategoryId].parent_id;
                    }
                    else
                        nextCategoryId = null;
                }
            };
            const buildAllNodesTreeFromFourthLevelCategoryIfIsCurrent = () => {
                if (currentCategory.level === 4) {
                    const ids = getCategoryChildrenIds(currentCategory, categories);
                    categoriesIds = [...categoriesIds, ...ids];
                }
            };
            const buildAllNodesTreeFromFourthLevelCategoryIfCurrentIsBiggerThenFour = () => {
                var _a, _b;
                if (currentCategory.level > 4) {
                    let parentId = currentCategory.parent_id;
                    while (((_a = categories[parentId]) === null || _a === void 0 ? void 0 : _a.level) !== 4) {
                        parentId = (_b = categories[parentId]) === null || _b === void 0 ? void 0 : _b.parent_id;
                    }
                    const ids = getCategoryChildrenIds(categories[parentId], categories);
                    categoriesIds = [...categoriesIds, ...ids];
                }
            };
            buildTreeFromRootToCurrent();
            buildAllNodesTreeFromFourthLevelCategoryIfIsCurrent();
            buildAllNodesTreeFromFourthLevelCategoryIfCurrentIsBiggerThenFour();
            resetCategories(categoriesIds);
        }
    };
    useEffect(() => {
        if ((phrase === null || phrase === void 0 ? void 0 : phrase.length) > 0) {
            (() => __awaiter(void 0, void 0, void 0, function* () {
                setLoading(true);
                const items = yield searchProductsByPhrase(phrase.toString(), 10000, [
                    'category_ids',
                ]);
                setProducts(items);
                setLoading(false);
            }))();
        }
    }, [phrase]);
    useEffect(() => {
        var _a;
        if ((products === null || products === void 0 ? void 0 : products.length) > 0 && ((_a = Object.keys(categoriesRedux)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            const tree = getProductsCategoryTree(products, categoriesRedux);
            setCategoryTree(tree);
        }
    }, [categoriesRedux, products]);
    useEffect(() => {
        if (!(phrase === null || phrase === void 0 ? void 0 : phrase.length) && categoriesTree) {
            setCategoryTree(categoriesTree);
        }
    }, [categoriesTree]);
    useEffect(() => {
        if ((phrase === null || phrase === void 0 ? void 0 : phrase.length) > 0 && searchCategoryId) {
            buildExpandedCategoryTree(categories[Number(searchCategoryId)]);
        }
        else if (currentCategory)
            buildExpandedCategoryTree(currentCategory);
    }, [currentCategory, categories, searchCategoryId]);
    useEffect(() => {
        // Automatically open the first level categories on mobile.
        if (categoryTree && openFirstLevel && !currentCategory) {
            categoryTree.forEach((category) => {
                toggleCategory([category.id]);
            });
        }
    }, [categoryTree]);
    return (React.createElement(React.Fragment, null, loading ? (React.createElement(Spinner, null)) : ((categoryTree === null || categoryTree === void 0 ? void 0 : categoryTree.length) > 0 && (React.createElement(CategoryTreeContainer, { largeTop: largeTop, mobileMode: mobileMode },
        !noTitle && (React.createElement(CategoryTitle, null, translate('products.categories'))),
        categoryTree.map((category) => (React.createElement(CategoryTreeNode, { key: category === null || category === void 0 ? void 0 : category.id, category: category, mobileMode: mobileMode, onNodeClick: onNodeClick, toggleCategory: toggleCategory, expanded: expanded }))))))));
};
export const CategoryTreeNode = ({ category, mobileMode, onNodeClick, toggleCategory, expanded, forceExpanded, }) => {
    var _a, _b, _c, _d, _e;
    const { goTo } = useRouter();
    const { translate } = useLang();
    const { currentCategory } = useCategory();
    const phrase = (_a = qs.parse(location.search)) === null || _a === void 0 ? void 0 : _a.p;
    const searchCategoryId = (_b = qs.parse(location.search)) === null || _b === void 0 ? void 0 : _b.category;
    const goToCategory = (category) => {
        const { external_url_path: externalPath } = category || {};
        if ((phrase === null || phrase === void 0 ? void 0 : phrase.length) > 0) {
            goTo(`/search?category=${category.id}&p=${phrase}`);
        }
        else if (externalPath) {
            externalPath.startsWith('http')
                ? window.open(externalPath)
                : goTo(externalPath);
        }
        else
            goTo(category === null || category === void 0 ? void 0 : category.url_path);
        onNodeClick && onNodeClick();
    };
    const expandCategory = (category) => {
        if (category.level === 4) {
            const childrenIds = getCategoryTreeNodeChildrenIds(category);
            toggleCategory(childrenIds);
        }
        else {
            toggleCategory(category.id);
        }
    };
    const onMobileNodeClick = (category) => {
        var _a;
        if (category.level < 5 && ((_a = category === null || category === void 0 ? void 0 : category.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            expandCategory(category);
        }
        else {
            goToCategory(category);
        }
    };
    const isExpanded = forceExpanded || includedId(expanded, category.id);
    return (React.createElement(React.Fragment, null, category && (React.createElement(CategoryTreeNodeItem, { className: 'level' + category.level, mobileMode: mobileMode },
        React.createElement(CategoryTreeNodeItemNameButton, { mobileMode: mobileMode, onClick: mobileMode ? () => onMobileNodeClick(category) : undefined },
            ((_c = category === null || category === void 0 ? void 0 : category.children) === null || _c === void 0 ? void 0 : _c.length) > 0 &&
                ((category.level === 2 && !mobileMode) ||
                    category.level === 3) && (React.createElement(CategoryTreeNodeItemButton, { side: "left", onClick: !mobileMode ? () => expandCategory(category) : undefined },
                React.createElement(Icon, { icon: isExpanded ? 'minus' : 'plus', width: 12, height: 12 }))),
            React.createElement(CategoryTreeNodeItemName, { onClick: !mobileMode ? () => goToCategory(category) : undefined, isActive: category.id ===
                    (phrase ? Number(searchCategoryId) : currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.id), mobileMode: mobileMode }, category === null || category === void 0 ? void 0 : category.name),
            ((_d = category === null || category === void 0 ? void 0 : category.children) === null || _d === void 0 ? void 0 : _d.length) > 0 &&
                (category.level === 4 ||
                    (category.level === 2 && mobileMode)) && (React.createElement(CategoryTreeNodeItemButton, { side: "right", onClick: !mobileMode ? () => expandCategory(category) : undefined },
                React.createElement(Icon, { icon: "expand_thin", rotate: isExpanded ? '180' : '0' })))),
        isExpanded && (React.createElement(CategoryTreeNodeItemChildren, null, category === null || category === void 0 ? void 0 : category.children.map((item) => {
            return (React.createElement(CategoryTreeNode, { key: item === null || item === void 0 ? void 0 : item.id, category: item, mobileMode: mobileMode, onNodeClick: onNodeClick, toggleCategory: toggleCategory, expanded: expanded }));
        }))),
        mobileMode && isExpanded && ((_e = category.children) === null || _e === void 0 ? void 0 : _e.length) > 0 && (React.createElement(React.Fragment, null,
            category.level === 2 && (React.createElement(ButtonWrapper, { margin: "0 0 20px 0" },
                React.createElement(Button, { appearance: "dark", onClick: () => goToCategory(category) }, translate('actions.show_all_from_category')))),
            category.level === 3 && (React.createElement(ButtonWrapper, { margin: "15px" },
                React.createElement(Button, { onClick: () => goToCategory(category) }, translate('actions.see_everything'))))))))));
};
