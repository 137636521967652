import styled from 'styled-components';
export const MinicartInnerWrapper = styled.div `
  width: 100%;
  max-width: 503px;
  background-color: #fff;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  margin-left: auto;
  position: relative;
  z-index: 120;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const MinicartDimmer = styled.div `
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  user-select: none;
  visibility: hidden;
  width: 100%;
  z-index: 120;
`;
export const MinicartTop = styled.div `
  align-items: center;
  border-bottom: 1px solid #525252;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 24px;
  width: 100%;
  height: 74px;
  @media (max-width: 1000px) {
    height: 60px;
    padding: 10px 15px;
  }
`;
export const MinicartInnerContainer = styled.div `
  flex: 1;
  overflow: auto;
  padding-left: 25px;
  padding-right: 37px;
  width: 100%;
  @media (max-width: 1000px) {
    padding: 0 15px;
  }
`;
export const MinicartSummary = styled.div `
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 37px 15px 24px;
  width: 100%;
  box-shadow: 0px 5px 20px #0000001c;
  @media (max-width: 500px) {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    > div {
      width: 100%;
    }
    > button {
      margin-top: 10px;
      max-width: none;
    }
  }
`;
export const MinicartHeader = styled.h3 `
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
export const MiniCartHeaderAmount = styled.span `
  font-weight: normal;
`;
export const MinicartHeaderButtons = styled.div `
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const MinicartGoToCartButton = styled.div `
  > button {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
  svg {
    margin-top: 2px;
  }
`;
export const MinicartGoToCartButtonLabel = styled.span `
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 22px;
  margin-right: 0.5rem;
  text-transform: lowercase;
`;
export const MinicartClearButton = styled.span `
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0 13px;
  @media (max-width: 1000px) {
    padding: 0;
  }
`;
export const MinicartSpinnerWrapper = styled.div `
  max-width: 60px;
`;
export const MinicartSummaryPricesWrapper = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 260px;
  @media (max-width: 1000px) {
    flex-direction: row;
    column-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
  }
  @media (max-width: 500px) {
    max-width: none;
  }
`;
export const MinicartSummaryLabel = styled.div `
  color: #525252;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 18px;
  margin-top: 3px;
  text-transform: lowercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const MinicartSummaryAmount = styled.div `
  color: ${(props) => props.theme.colors.primary};
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 37px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  @media (max-width: 1000px) {
    font-size: 22px;
    line-height: 27px;
  }
`;
export const MinicartTopLeft = styled.div `
  display: flex;
  flex-direction: column;
`;
export const StyledProductsList = styled.div `
  display: flex;
  flex-direction: column;
  ${(props) => props.noElements
    ? `
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 44px;
    @media (max-width: 1024px) {
        padding: 0;
    }
  `
    : `
    padding: 20px 0;
  `}
`;
export const StyledTitle = styled.div `
  font-size: 26px;
  font-weight: 36px;
  margin-bottom: 30px;
  font-weight: 700;
  text-align: center;
`;
export const StyledDescription = styled.div `
  font-size: 16px;
  font-weight: 26px;
  margin-bottom: 30px;
  text-align: center;
`;
