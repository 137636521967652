import { __awaiter } from "tslib";
import React, { useEffect, useRef, useState } from 'react';
import { TextWrapper, TextContent } from '@@Pages/ProductPage/Overview/styled';
export const Overview = ({ product, }) => {
    const contentRef = useRef(null);
    const [contentHtmlToSet, setContentHtmlToSet] = useState('');
    const contentHtml = product === null || product === void 0 ? void 0 : product.description;
    const applyDOMChanges = () => {
        const dom = contentRef.current;
        if (!dom) {
            return;
        }
        const ytEmbedStart = '<div class="generated-video-container">';
        const ytEmbedEnd = '</div>';
        // wrap Youtube embeds in responsive elements
        [...dom.querySelectorAll('iframe[width][height][src*="youtube"]')].forEach((iframe) => {
            if (!iframe.parentNode.classList.contains('generated-video-container')) {
                iframe.outerHTML = ytEmbedStart + iframe.outerHTML + ytEmbedEnd;
            }
        });
    };
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            if (contentHtml) {
                yield setContentHtmlToSet(contentHtml);
                setTimeout(() => {
                    applyDOMChanges();
                }, 100); // naive
            }
        }))();
    }, [contentHtml]);
    return (React.createElement(React.Fragment, null, product && (React.createElement(TextWrapper, { ref: contentRef }, (product === null || product === void 0 ? void 0 : product.description) && (React.createElement(TextContent, { dangerouslySetInnerHTML: { __html: contentHtmlToSet } }))))));
};
