import styled from 'styled-components';
export const SubmitButtonWrapper = styled.div `
  width: 250px;
  margin-top: 20px;
`;
export const PaymentMethodWrapper = styled.div ``;
export const PaymentMethodsWrapper = styled.div `
  ${PaymentMethodWrapper} + ${PaymentMethodWrapper} {
    margin-top: 3px;
  }
`;
export const ShippingMethodWrapper = styled.div ``;
export const ShippingMethodsWrapper = styled.div `
  ${ShippingMethodWrapper} + ${ShippingMethodWrapper} {
    margin-top: 3px;
  }
`;
