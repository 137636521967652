import { __awaiter } from "tslib";
import React, { useState, useEffect } from 'react';
import Input from '@@Components/Input/Input';
import { useControlledForm, } from '@@Hooks/useControlledForm';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@@Components/Input/Checkbox';
import { useLang } from '@@Hooks/useLang';
import { Select } from '@@Components/Select/Select';
import LSF from '@@Storefront/index';
import { dispatch } from '@@Redux/dispatch';
import { register as registerThunk } from '@@Redux/thunks/user.thunks';
import { notify } from '@@Helpers/notifications';
import { NotificationType, } from '@grupakmk/libstorefront';
import { validateEmail, validatePassword, validatePhoneNumber, validatePostalCode, } from '@@Helpers/validators';
import { Radio } from '@@Components/Input/Radio';
import Button from '@@Components/Buttons/Button';
import { useSelector } from 'react-redux';
import { userIsLoggedSelector, userProfileSelector, } from '@@Redux/selectors/user.selectors';
import { checkoutSelector } from '@@Redux/selectors/checkout.selectors';
import { UserDataFormMapper } from '@@Storefront/model-mappers/user-data-form-mapper';
import { isDifferentInvoiceAddress } from '@@Helpers/isDifferentInvoiceAddress';
import { mapCheckoutUserDataToPaymentDetails } from '@@Helpers/mapCheckoutUserDataToPaymentDetails';
import { CheckoutFormWrapper, TwoFieldsWrapper, RadiosWrapper, SubmitButtonWrapper, } from './styled';
var InvoiceType;
(function (InvoiceType) {
    InvoiceType["Individual"] = "individual";
    InvoiceType["Company"] = "company";
})(InvoiceType || (InvoiceType = {}));
export const CheckoutForm = React.forwardRef((props, ref) => {
    const { reset, register, control, handleSubmit, errors, formWatch, setValue, } = useControlledForm({
        mode: 'onChange',
    }, ref);
    useEffect(() => {
        if (!formWatch.invoiceType) {
            setValue('invoiceType', InvoiceType.Company);
        }
    }, [formWatch.differentInvoiceAddress, formWatch.invoiceType]);
    const userDataFormMapper = new UserDataFormMapper();
    const { translate } = useLang();
    const [isRegistration, setIsRegistration] = useState(false);
    const [countries, setCountries] = useState([]);
    const [isPending, setIsPending] = useState(false);
    const isLogged = useSelector(userIsLoggedSelector);
    const userProfile = useSelector(userProfileSelector);
    const checkout = useSelector(checkoutSelector);
    // Autofill form if user is logged in, has billing information
    useEffect(() => {
        const shippingDetails = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails;
        const paymentDetails = checkout === null || checkout === void 0 ? void 0 : checkout.paymentDetails;
        const hasAddress = (shippingDetails === null || shippingDetails === void 0 ? void 0 : shippingDetails.firstName) && (shippingDetails === null || shippingDetails === void 0 ? void 0 : shippingDetails.lastName);
        if (hasAddress && shippingDetails) {
            let resetData = {
                emailAddress: shippingDetails.email,
                firstName: shippingDetails.firstName,
                lastName: shippingDetails.lastName,
                company: shippingDetails.company,
                country: shippingDetails.country,
                city: shippingDetails.city,
                zipCode: shippingDetails.zipCode,
                streetAddress: shippingDetails.streetAddress,
                houseNo: shippingDetails.houseNo,
                apartmentNumber: shippingDetails.apartmentNumber,
                phoneNumber: shippingDetails.phoneNumber,
            };
            if (isDifferentInvoiceAddress()) {
                resetData = Object.assign(Object.assign({}, resetData), { differentInvoiceAddress: true, invoiceType: paymentDetails.company && paymentDetails.company !== '-'
                        ? InvoiceType.Company
                        : InvoiceType.Individual, invoiceCompany: paymentDetails.company && paymentDetails.company !== '-'
                        ? paymentDetails.company
                        : '', invoiceFirstName: paymentDetails.firstName && paymentDetails.firstName !== '-'
                        ? paymentDetails.firstName
                        : '', invoiceLastName: paymentDetails.lastName && paymentDetails.lastName !== '-'
                        ? paymentDetails.lastName
                        : '', invoiceCountry: paymentDetails.country, invoiceCity: paymentDetails.city, invoiceZipCode: paymentDetails.zipCode, invoiceStreetAddress: paymentDetails.streetAddress, invoiceHouseNo: paymentDetails.houseNo, invoiceVatId: paymentDetails.vat_id, invoiceApartmentNumber: paymentDetails.apartmentNumber });
            }
            else {
                resetData.differentInvoiceAddress = false;
            }
            reset(resetData);
        }
    }, [checkout]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const countries = yield LSF.CountriesService.getCountriesList();
                setCountries(countries
                    .filter((c) => c.full_name_locale)
                    .map((c) => ({ value: c.id, label: c.full_name_locale })));
            }
            catch (error) { }
        }))();
    }, []);
    const handleUserDataSubmit = (userData) => __awaiter(void 0, void 0, void 0, function* () {
        if (userData.password) {
            setIsPending(true);
            try {
                const customer = userDataFormMapper.transformCheckoutData(userData);
                const { password } = userData;
                yield dispatch(registerThunk({
                    customer: customer,
                    password,
                }));
                notify(translate('checkout.you_have_been_registered'), NotificationType.SUCCESS);
                setIsPending(false);
            }
            catch (error) {
                notify(translate('checkout.registration_error'), NotificationType.ERROR);
                setIsPending(false);
            }
        }
        else {
            const personalDetails = {
                email: userData.emailAddress,
                firstName: userData.firstName,
                lastName: userData.lastName,
            };
            yield LSF.CheckoutService.setPersonalDetails(personalDetails);
            const userShippingData = Object.assign(Object.assign({ id: null }, personalDetails), { streetAddress: userData.streetAddress, apartmentNumber: userData.apartmentNumber, country: userData.country, city: userData.city, zipCode: userData.zipCode, phoneNumber: userData.phoneNumber, company: userData.company, houseNo: userData.houseNo });
            yield LSF.CheckoutService.setShippingDetails(Object.assign(Object.assign({}, userShippingData), { shippingMethod: null, custom_attributes: null, extension_attributes: null }));
            const paymentDetails = mapCheckoutUserDataToPaymentDetails(userData);
            yield LSF.CheckoutService.setPaymentDetails(paymentDetails);
            if (props.onSuccess) {
                props.onSuccess();
            }
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(CheckoutFormWrapper, null,
            React.createElement("form", { onSubmit: handleSubmit(handleUserDataSubmit), "data-cy": "form userDataForm" },
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "emailAddress", labelAuto: true, label: translate('checkout_form.email'), placeholder: '*' + translate('checkout_form.email'), error: errors.emailAddress, ref: register({ validate: validateEmail }) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "firstName", labelAuto: true, label: translate('checkout_form.first_name'), placeholder: '*' + translate('checkout_form.first_name'), error: errors.firstName, ref: register({ required: translate('validation.required') }) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "lastName", labelAuto: true, label: translate('checkout_form.last_name'), placeholder: '*' + translate('checkout_form.last_name'), error: errors.lastName, ref: register({ required: translate('validation.required') }) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { name: "company", labelAuto: true, label: translate('checkout_form.company_name'), placeholder: translate('checkout_form.company_name'), error: errors.company, ref: register() })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Controller, { control: control, name: "country", labelAuto: true, defaultValue: "PL", render: ({ onChange, onBlur, value }) => (React.createElement(Select, { placeholder: '*' + translate('checkout_form.country'), floating: true, disabled: !countries.length, options: countries, value: value, error: errors.country, onBlur: onBlur, onChange: (e) => {
                                onChange(e.value);
                            } })) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(TwoFieldsWrapper, null,
                        React.createElement("div", null,
                            React.createElement(Input, { required: true, name: "city", label: translate('checkout_form.city'), labelAuto: true, placeholder: '*' + translate('checkout_form.city'), error: errors.city, ref: register({
                                    required: translate('validation.required'),
                                }) })),
                        React.createElement("div", null,
                            React.createElement(Input, { required: true, name: "zipCode", label: translate('checkout_form.zip_code'), labelAuto: true, placeholder: '*' + translate('checkout_form.zip_code'), error: errors.zipCode, ref: register({ validate: validatePostalCode }) })))),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "streetAddress", label: translate('checkout_form.street_address'), labelAuto: true, placeholder: '*' + translate('checkout_form.street_address'), error: errors.streetAddress, ref: register({ required: translate('validation.required') }) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(TwoFieldsWrapper, null,
                        React.createElement("div", null,
                            React.createElement(Input, { required: true, name: "houseNo", label: translate('checkout_form.house_no'), labelAuto: true, placeholder: '*' + translate('checkout_form.house_no'), error: errors.houseNo, ref: register({
                                    required: translate('validation.required'),
                                }) })),
                        React.createElement("div", null,
                            React.createElement(Input, { name: "apartmentNumber", label: translate('checkout_form.apartment_number'), labelAuto: true, placeholder: translate('checkout_form.apartment_number'), error: errors.apartmentNumber, ref: register })))),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { required: true, name: "phoneNumber", type: "number", label: translate('checkout_form.phone_number'), labelAuto: true, placeholder: '*' + translate('checkout_form.phone_number'), error: errors.phoneNumber, ref: register({ validate: validatePhoneNumber }) })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Checkbox, { name: "differentInvoiceAddress", ref: register() }, translate('checkout_form.i_want_invoice'))),
                formWatch.differentInvoiceAddress && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "form-row" },
                        React.createElement(RadiosWrapper, null,
                            React.createElement(Radio, { name: "invoiceType", ref: register(), value: "company" }, translate('checkout_form.company')),
                            React.createElement(Radio, { name: "invoiceType", ref: register(), value: "individual" }, translate('checkout_form.individual')))),
                    formWatch.invoiceType === InvoiceType.Individual ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "form-row" },
                            React.createElement(Controller, { control: control, name: "invoiceFirstName", rules: { required: translate('validation.required') }, render: ({ ref, onBlur, onChange, value }) => (React.createElement(Input, { required: true, value: value, label: translate('checkout_form.first_name'), labelAuto: true, placeholder: '*' + translate('checkout_form.first_name'), error: errors.invoiceFirstName, onChange: onChange })) })),
                        React.createElement("div", { className: "form-row" },
                            React.createElement(Controller, { control: control, name: "invoiceLastName", rules: { required: translate('validation.required') }, render: ({ ref, onBlur, onChange, value }) => (React.createElement(Input, { required: true, value: value, label: translate('checkout_form.last_name'), labelAuto: true, placeholder: '*' + translate('checkout_form.last_name'), error: errors.invoiceLastName, onChange: onChange })) })))) : (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "form-row" },
                            React.createElement(Input, { name: "invoiceCompany", label: translate('checkout_form.company_name'), labelAuto: true, placeholder: '*' + translate('checkout_form.company_name'), error: errors.invoiceCompany, required: true, ref: register({
                                    required: translate('validation.required'),
                                }) })),
                        React.createElement("div", { className: "form-row" },
                            React.createElement(Input, { name: "invoiceVatId", type: "number", labelFontWeight: 500, labelAuto: true, label: translate('checkout_form.nip'), placeholder: '*' + translate('checkout_form.nip'), error: errors.invoiceVatId, required: true, ref: register({
                                    validate: (value) => value.length === 10 ||
                                        'Proszę podać 10-cyfrowy numer NIP.',
                                }) })))),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Controller, { control: control, name: "invoiceCountry", defaultValue: "PL", render: ({ onChange, onBlur, value }) => (React.createElement(Select, { placeholder: '*' + translate('checkout_form.country'), floating: true, disabled: !countries.length, options: countries, value: value, error: errors.invoiceCountry, onBlur: onBlur, onChange: (e) => {
                                    onChange(e.value);
                                } })) })),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(TwoFieldsWrapper, null,
                            React.createElement("div", null,
                                React.createElement(Input, { required: true, name: "invoiceCity", label: translate('checkout_form.city'), labelAuto: true, placeholder: '*' + translate('checkout_form.city'), error: errors.invoiceCity, ref: register({
                                        required: translate('validation.required'),
                                    }) })),
                            React.createElement("div", null,
                                React.createElement(Input, { required: true, name: "invoiceZipCode", label: translate('checkout_form.zip_code'), labelAuto: true, placeholder: '*' + translate('checkout_form.zip_code'), error: errors.invoiceZipCode, ref: register({ validate: validatePostalCode }) })))),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { required: true, name: "invoiceStreetAddress", label: translate('checkout_form.street_address'), labelAuto: true, placeholder: '*' + translate('checkout_form.street_address'), error: errors.invoiceStreetAddress, ref: register({
                                required: translate('validation.required'),
                            }) })),
                    React.createElement("div", { className: "form-row" },
                        React.createElement(TwoFieldsWrapper, null,
                            React.createElement("div", null,
                                React.createElement(Input, { required: true, name: "invoiceHouseNo", label: translate('checkout_form.house_no'), labelAuto: true, placeholder: '*' + translate('checkout_form.house_no'), error: errors.invoiceHouseNo, ref: register({
                                        required: translate('validation.required'),
                                    }) })),
                            React.createElement("div", null,
                                React.createElement(Input, { name: "invoiceApartmentNumber", label: translate('checkout_form.apartment_number'), labelAuto: true, placeholder: translate('checkout_form.apartment_number'), error: errors.invoiceApartmentNumber, ref: register })))))),
                !isLogged && (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "form-row" },
                        React.createElement(Checkbox, { onChange: (checked) => setIsRegistration(checked) },
                            React.createElement("b", null, translate('checkout_form.register_me')),
                            React.createElement("p", null, translate('checkout_form.by_registering_you_accept')))),
                    isRegistration && (React.createElement("div", { className: "form-row" },
                        React.createElement(Input, { type: "password", name: "password", required: true, ref: register({ validate: validatePassword }), placeholder: '*' + translate('checkout_form.password'), label: translate('checkout_form.password'), error: errors.password }))))),
                React.createElement(SubmitButtonWrapper, null,
                    React.createElement(Button, { appearance: "primary", loading: isPending, "data-cy": "proceedButton" }, translate('checkout_form.proceed')))))));
});
