import styled from 'styled-components';
export const CategoryResultsManageBarWrapper = styled.div `
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  ${(props) => props.theme.mixins.mobile('padding-top: 15px;')};
`;
export const Text = styled.p `
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0;
`;
export const TextPadding = styled(Text) `
  padding-left: 35px;
  padding-right: 5px;
  @media (max-width: 1080px) {
    padding-left: 10px;
  }
  ${(props) => props.theme.mixins.mobile('display: none')};
`;
export const TextMobile = styled.button `
  margin-right: ${(props) => props.marginRight};
  display: none;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  font-weight: 700;
  ${(props) => props.theme.mixins.mobile('display: flex;')};
`;
export const PaginationWrapper = styled.div `
  @media (max-width: 550px) {
    display: none;
  }
`;
export const ProductsInfoWrapper = styled.div `
  width: 40%;
  @media (max-width: 1050px) {
    width: 220px;
  }
  ${(props) => props.theme.mixins.mobile('width: 170px;')};
  @media (max-width: 550px) {
    width: auto;
  }
`;
export const DropdownContainer = styled.div `
  display: flex;
  justify-content: flex-end;
  width: 40%;
  @media (max-width: 1050px) {
    width: 220px;
  }
  ${(props) => props.theme.mixins.mobile('width: 170px;')};
  @media (max-width: 550px) {
    width: auto;
  }
`;
export const DropdownWrapper = styled.div `
  display: flex;
  align-items: center;
  ${(props) => props.theme.mixins.mobile('display: none')};
`;
export const IconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  svg {
    ${(props) => props.isInactive && 'fill: #A2A2A2;'}
  }
`;
export const ViewIconsWrapper = styled.div `
  display: flex;
  @media (max-width: 1279px) {
    display: none;
  }
`;
