import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLang } from '@@Hooks/useLang';
import Icon from '@@Components/Icon/Icon';
import { limitTextLength } from '@@Helpers/limitTextLength';
import { Description, ShowMoreButton } from './styled';
export const CategoryDescription = ({ description }) => {
    const [isShowMore, setIsShowMore] = useState(false);
    const location = useLocation();
    const { translate } = useLang();
    const textLength = 325;
    const descriptionWithoutHtmlTags = description === null || description === void 0 ? void 0 : description.replace(/<\/?[^>]+(>|$)/g, '');
    useEffect(() => {
        setIsShowMore(false);
    }, [location]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Description, { dangerouslySetInnerHTML: {
                __html: isShowMore
                    ? description
                    : limitTextLength(description, textLength, true),
            } }),
        (descriptionWithoutHtmlTags === null || descriptionWithoutHtmlTags === void 0 ? void 0 : descriptionWithoutHtmlTags.length) > textLength && (React.createElement(ShowMoreButton, { onClick: () => setIsShowMore(!isShowMore) },
            isShowMore
                ? translate('actions.show_less')
                : translate('actions.show_more'),
            React.createElement(Icon, { icon: "short_arrow", width: 12, height: 12, rotate: isShowMore ? '180' : '0' })))));
};
