import styled from 'styled-components';
export const ProductGalleryWrapper = styled.div `
  display: flex;
  flex-flow: column;

  .swiper-wrapper {
    align-items: center;
  }
`;
export const ProductGallerySlides = styled.div `
  overflow: hidden;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
`;
export const ProductGallerySlideWrapper = styled.div `
  width: 100%;
  border: ${(props) => (!props.withoutBorder ? '1px solid #D8D8D8' : 'none')};
  img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  ${(props) => props.withPointerCursor && 'cursor: pointer;'}
`;
export const ProductGalleryVideoWrapper = styled.div `
  width: 100%;
  padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
  position: relative;
  height: 0;
  ${(props) => props.withPointerCursor && 'cursor: pointer;'}

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
export const ProductThumbnailButtonWrapper = styled.button `
  box-sizing: content-box;
  padding: 10px;
  margin: auto;
  cursor: pointer;
  width: 14px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 1;
`;
export const ProductGalleryThumbnailsWrapper = styled.div `
  display: flex;
  ${(props) => props.theme.mixins.mobile(`
    width: 100%;
  `)}
`;
export const ProductThumbnailSwiperContainer = styled.div `
  flex: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;
  overflow: hidden;
  .swiper-slide {
    width: auto;
  }

  .swiper-container {
    overflow: visible !important;
  }
`;
export const ProductGalleryThumbnailWrapper = styled.div `
  width: 91px;
  height: 54px;
  padding: 5px;
  margin-right: 0.5rem;
  border: 1px solid #d8d8d8;
  position: relative;
  cursor: pointer;
  ${(props) => props.active && 'border-color: #363535'};
`;
export const ProductGalleryThumbnail = styled.div `
  background-color: #fff;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
`;
export const BadgesWrapper = styled.div `
  width: calc(100% - 120px);
  position: relative;
  @media (max-width: 1000px) {
    width: calc(100% - 100px);
  }
`;
