import { __awaiter } from "tslib";
import React, { useState, useEffect } from 'react';
import { ProductsSlider } from '@@Components/ProductsSlider';
import { useLang } from '@@Hooks/useLang';
import { getProductsBySkus } from '@@Helpers/getProductsBySkus';
import { SliderContainer } from './styled';
export const CrossSellProductsSlider = ({ product }) => {
    const [crossSellProducts, setCrossSellProducts] = useState(null);
    const { translate } = useLang();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            if (!product)
                return null;
            const relatedLinks = (_a = product.products_related) === null || _a === void 0 ? void 0 : _a.map((element) => element.linked_product_sku);
            if (!relatedLinks)
                return null;
            const products = yield getProductsBySkus(relatedLinks);
            setCrossSellProducts(products);
        }))();
    }, [product]);
    return (React.createElement(React.Fragment, null, (crossSellProducts === null || crossSellProducts === void 0 ? void 0 : crossSellProducts.length) > 0 && (React.createElement(SliderContainer, null,
        React.createElement(ProductsSlider, { title: translate('headers.see_also'), products: crossSellProducts })))));
};
