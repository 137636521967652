import styled from 'styled-components';
export const OrdersHistoryTitle = styled.div `
  font: normal normal bold 16px/24px Montserrat;
  margin-bottom: 15px;
`;
export const OrdersHistoryDetailsLinkIconWrapper = styled.div `
  display: none;
  @media only screen and (max-width: 1023px) {
    display: inline-block;
    margin-left: 10px;
  }
`;
export const StyleSpinner = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
