import { __awaiter } from "tslib";
import map from 'lodash-es/map';
import React from 'react';
import { Link } from 'react-router-dom';
import { PriceUtils, ThumbnailUtils } from '@adsel/libstorefront';
import { Ratio } from '@@Components/Ratio';
import { ResponsiveTable } from '@@Components/ResponsiveTable';
import { useLang } from '@@Hooks/useLang';
import { getProductLink } from '@@Storefront/utils/get-product-link';
import { getProductsBySkus } from '@@Helpers/getProductsBySkus';
import { ConfigurableVariantOption, OrderInfoElement, PriceBeforeDiscount, } from './styled';
const OrderPreviewProductsListDesktop = ({ orderItems }) => {
    const { translate } = useLang();
    const productExist = (product) => __awaiter(void 0, void 0, void 0, function* () {
        const { sku } = product;
        const response = yield getProductsBySkus(sku).then((res) => {
            if (res) {
                return res;
            }
        });
        return response.length > 0;
    });
    return (React.createElement(ResponsiveTable, null,
        React.createElement("thead", null,
            React.createElement("tr", { className: "headers" },
                React.createElement("th", null),
                React.createElement("th", null, translate('tables.heading.product')),
                React.createElement("th", null, translate('tables.heading.unit_price')),
                React.createElement("th", null, translate('tables.heading.quantity_purchased')),
                React.createElement("th", null, translate('tables.heading.sum')))),
        React.createElement("tbody", null, map(orderItems, (item) => {
            var _a, _b, _c;
            const { price, price_incl_tax } = PriceUtils.getFormattedPrice(item.base_price, item.base_price_incl_tax);
            const { price: row_price, price_incl_tax: row_price_incl_tax } = PriceUtils.getFormattedPrice(item.base_price * item.qty_ordered, item.base_price_incl_tax * item.qty_ordered);
            const hasSpecialPrice = Math.abs(item.base_original_price - item.base_price_incl_tax) >
                0.0001;
            return (React.createElement("tr", { key: item.id },
                React.createElement("td", { style: { width: '70px' } }, productExist(item) && (item === null || item === void 0 ? void 0 : item.status) !== 1 ? (React.createElement(Ratio, { bg: ThumbnailUtils.getImageSrc({
                        image: item.thumbnail,
                        width: 55,
                        height: 38,
                    }), bgSize: "contain", bgRepeat: "no-repeat", size: 100 })) : (React.createElement(Link, { to: getProductLink(item, 'produkt') },
                    React.createElement(Ratio, { bg: ThumbnailUtils.getImageSrc({
                            image: item.thumbnail,
                            width: 55,
                            height: 38,
                        }), bgSize: "contain", bgRepeat: "no-repeat", size: 100 })))),
                React.createElement("td", null,
                    React.createElement(OrderInfoElement, null,
                        "SKU: ",
                        item.sku),
                    React.createElement("div", null,
                        productExist(item) && (item === null || item === void 0 ? void 0 : item.status) !== 1 ? (React.createElement(React.Fragment, null,
                            React.createElement("strong", null, item.name),
                            item.variant_name && item.variant_url_path && (React.createElement("div", null, item.variant_name)))) : (React.createElement(React.Fragment, null,
                            React.createElement(Link, { to: getProductLink(item, 'produkt') },
                                React.createElement("strong", null, item.name)),
                            item.variant_name && item.variant_url_path && (React.createElement("div", null,
                                React.createElement(Link, { to: '/produkt/' + item.variant_url_path }, item.variant_name))))),
                        ((_a = item.variant_options) === null || _a === void 0 ? void 0 : _a.length) > 0 && (React.createElement("div", null, (_b = item.variant_options) === null || _b === void 0 ? void 0 : _b.map((option, index) => {
                            var _a;
                            return (React.createElement(ConfigurableVariantOption, { key: index }, (_a = option.value) === null || _a === void 0 ? void 0 : _a.label));
                        }))))),
                React.createElement("td", null,
                    React.createElement(OrderInfoElement, null,
                        React.createElement("strong", null, price_incl_tax),
                        ' ',
                        hasSpecialPrice ? (React.createElement(PriceBeforeDiscount, null, (_c = PriceUtils.getFormattedPrice(item.base_original_price)) === null || _c === void 0 ? void 0 : _c.price)) : ('')),
                    React.createElement("div", null,
                        price,
                        " ",
                        translate('checkout.net'))),
                React.createElement("td", null,
                    item.qty_ordered,
                    " ",
                    translate('order.items')),
                React.createElement("td", null,
                    React.createElement(OrderInfoElement, null,
                        React.createElement("strong", null, row_price_incl_tax)),
                    React.createElement("div", null,
                        row_price,
                        " ",
                        translate('checkout.net'))),
                React.createElement("td", null)));
        }))));
};
export default OrderPreviewProductsListDesktop;
