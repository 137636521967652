import styled from 'styled-components';
export const OrderPreviewHeaderContainer = styled.div `
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;
export const OrderPreviewInfo = styled.div `
  display: flex;
`;
export const OrderPreviewBack = styled.div `
  margin-right: 15px;
  margin-top: 4px;

  @media (max-width: 1023px) {
    margin-right: 10px;
  }
`;
export const OrderPreviewID = styled.div `
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
`;
export const OrderPreviewDate = styled.div `
  font-size: 14px;
  line-height: 22px;
`;
export const OrderPreviewLinks = styled.div `
  @media (max-width: 1023px) {
    display: none;
  }
`;
export const CAStatus = styled.div `
  font-size: 14px;
  line-height: 22px;
`;
