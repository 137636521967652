import React from 'react';
import { useLang } from '@@Hooks/useLang';
import { ProductAvailabilityContainer, ProductAvailabilityIndicator, ProductAvailabilityText, } from './styled';
export const ProductAvailability = ({ product, availableText, unavailableText }) => {
    const { translate } = useLang();
    const isAvailable = () => {
        var _a, _b;
        return ((_a = product === null || product === void 0 ? void 0 : product.stock) === null || _a === void 0 ? void 0 : _a.qty) > 0 && ((_b = product === null || product === void 0 ? void 0 : product.stock) === null || _b === void 0 ? void 0 : _b.is_in_stock);
    };
    return (React.createElement(ProductAvailabilityContainer, null,
        React.createElement(ProductAvailabilityIndicator, { isAvailable: isAvailable() }),
        React.createElement(ProductAvailabilityText, null, isAvailable()
            ? availableText || translate('products.product_available')
            : unavailableText || translate('products.product_unavailable'))));
};
