import styled from 'styled-components';
import CommonButton from '@@Components/Buttons/Button';
export const BlogRecentPostsContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;

  ${(props) => props.theme.mixins.mobile(`
    display: flex;
    flex-direction: column;
  `)};
`;
export const BlogRecentPostsRow = styled.div `
  position: relative;
  width: 100%;
  max-width: 1135px;
  height: 405px;
  text-align: left;

  ${(props) => props.theme.mixins.mobile(`
    min-height: 0;
    height: auto;
    padding: 0;
`)};
`;
export const BlockContentContainer = styled.div `
  width: 100%;
  padding-right: 70px;

  ${(props) => props.theme.mixins.mobile(`
    padding: 0 15px;
  `)};
`;
export const BlogTitle = styled.h3 `
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  margin-bottom: 30px;
`;
export const BlogCategory = styled.div `
  color: #525252;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
  span {
    display: inline-block;
    margin-right: 10px;
  }
`;
export const BlogContent = styled.div `
  font-size: 16px;
  line-height: 26px;
  min-height: 180px;

  ${(props) => props.theme.mixins.mobile(`
    min-height: 0;
  `)};
`;
export const BlogImageContainer = styled.div `
  line-height: 26px;

  ${(props) => props.theme.mixins.mobile(`
    margin-top: 20px;
    img { 
      max-width: 100%;
      height: auto;
    }
  `)};
`;
export const ButtonCustom = styled(CommonButton) `
  min-width: 210px;
  font-size: 14px;
  padding: 0;
  margin-top: 40px;
  border: 1px solid black;
  background-color: #000000;
  color: #ffffff;

  ${(props) => props.theme.mixins.mobile(`
    display: none;
  `)};
`;
export const AbsoluteButtonsContainer = styled.div `
  position: absolute;
  bottom: 0;
  left: 0;

  ${(props) => props.theme.mixins.mobile(`
    position: relative;
  `)};
`;
export const PageButtonsContainer = styled.div `
  display: flex;
  align-items: center;
  margin-top: 30px;

  button {
    font-size: 14px;
    line-height: 18px;
    margin-right: 10px;

    &:hover {
      font-weight: bold;
    }
  }

  ${(props) => props.theme.mixins.mobile(`
    padding: 0 15px;
    
    button {
      padding: 0;
    }
  `)};
`;
