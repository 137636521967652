import styled from 'styled-components';
export const ImagesWrapper = styled.section `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const ImagesContainer = styled.div `
  display: flex;
  align-items: center;
  width: 100%;
  ${(props) => props.theme.mixins.mobile(`
    flex-direction: column;
    height: 120vw;
  `)};
`;
export const ImageLeft = styled.div `
  height: 420px;
  width: 50%;
  margin-right: 5px;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${(props) => props.theme.mixins.mobile(`
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
    height: 190px;
  `)}
`;
export const ImageRight = styled.div `
  height: 420px;
  width: 50%;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${(props) => props.theme.mixins.mobile(`
    height: 190px;
    width: 100%;
  `)};
`;
