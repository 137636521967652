import React from 'react';
import { translate } from '@@Helpers/translateGlobal';
import { FormSectionHeading } from './styles';
export const CheckoutFormSection = React.forwardRef(({ title, active = true, editButtonVisible = false, children, dataCy, onEditClick, }, ref) => {
    return (React.createElement("section", { "data-cy": dataCy, ref: ref },
        React.createElement(FormSectionHeading, { active: active },
            React.createElement("h3", null, title),
            editButtonVisible && (React.createElement("a", { onClick: () => {
                    if (onEditClick)
                        onEditClick();
                } }, translate('checkout_form.edit')))),
        active && React.createElement("div", { className: "content" }, children)));
});
