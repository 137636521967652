import React from 'react';
import { useLang } from '@@Hooks/useLang';
import Button from '@@Components/Buttons/Button';
import { Title, Subtitle, SvgWrapper, ButtonWrapper, InnerContainer, BottomDescription, NoResultsContainer, } from '@@Components/NoSearchResults/styled';
export const NoSearchResults = (props) => {
    const { translate } = useLang();
    const { title = translate('placeholders.no_results_title'), image, subtitle = translate('placeholders.no_results_subtitle'), description = translate('placeholders.no_results_description'), buttonText = translate('placeholders.no_results_button_content'), buttonLink = '/', onButtonClick, marginTop = 40, marginBottom, } = props;
    return (React.createElement(NoResultsContainer, { marginTop: marginTop, marginBottom: marginBottom },
        React.createElement(InnerContainer, null,
            React.createElement(SvgWrapper, { background: image }),
            React.createElement(Title, null, title || translate('placeholders.no_results_title')),
            React.createElement(Subtitle, null, subtitle || translate('placeholders.no_results_subtitle')),
            React.createElement(BottomDescription, null, description || translate('placeholders.no_results_description')),
            React.createElement(ButtonWrapper, null, buttonLink ? (React.createElement("a", { href: buttonLink },
                React.createElement(Button, { onClick: onButtonClick }, buttonText))) : (React.createElement(Button, { onClick: onButtonClick }, buttonText))))));
};
