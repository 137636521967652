import { __awaiter } from "tslib";
import React from 'react';
import { useLang } from '@@Hooks/useLang';
import { useSelector } from 'react-redux';
import { clearItems } from '@@Redux/thunks/cart.thunks';
import { dispatch } from '@@Redux/dispatch';
import { CartProductTile } from '@@Components/CartProductTile';
import { StyledDescription } from '@@Components/Minicart/styles';
import Button from '@@Components/Buttons/Button';
import { useRouter } from '@@Hooks/useRouter';
import { useEnhancedCartItems } from '@@Hooks/useEnhancedCartItems';
import { userIsLoggedSelector } from '@@Redux/selectors/user.selectors';
import { StyledContainer, StyledContent, StyledHeader, StyledTitle, StyledClear, StyledCountItems, StyledContentEmpty, } from './styled';
const CheckoutCartPage = () => {
    const isUserLogged = useSelector(userIsLoggedSelector);
    const { translate } = useLang();
    const { goTo } = useRouter();
    const { itemsWithImages } = useEnhancedCartItems();
    return (React.createElement(React.Fragment, null, (itemsWithImages === null || itemsWithImages === void 0 ? void 0 : itemsWithImages.length) > 0 ? (React.createElement(StyledContainer, null,
        React.createElement(StyledHeader, null,
            React.createElement(StyledTitle, null,
                translate('checkout.your_cart'),
                React.createElement(StyledCountItems, null,
                    "(",
                    itemsWithImages.length,
                    ")")),
            React.createElement(StyledClear, { onClick: () => __awaiter(void 0, void 0, void 0, function* () { return yield dispatch(clearItems()); }) }, translate('actions.clear_cart'))),
        React.createElement(StyledContent, null, itemsWithImages.map((cartItem, index) => {
            return (React.createElement(CartProductTile, Object.assign({ last: index === itemsWithImages.length - 1, key: index }, cartItem, { showId: true, withChangeCount: true })));
        })))) : (React.createElement(StyledContentEmpty, null,
        React.createElement(StyledTitle, null, translate('checkout.cart_is_empty')),
        React.createElement(StyledDescription, null, isUserLogged
            ? translate('checkout.description_empty_logged')
            : translate('checkout.description_empty')),
        isUserLogged ? (React.createElement(Button, { appearance: "text", onClick: () => goTo('/') }, translate('actions.get_back_to_shopping'))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { onClick: () => goTo('/login'), appearance: "dark" }, translate('checkout.login')),
            React.createElement(Button, { onClick: () => goTo('/') }, translate('checkout.homepage'))))))));
};
export default CheckoutCartPage;
