import styled from 'styled-components';
export const HoveredImageColumn = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DropdownListGridContainer = styled.div `
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 25px;
`;
export const NavbarWrapper = styled.div `
  ${(props) => (props.withTextLinks ? '--additional-navbar-height: 35px;' : '')}
  background: #fff;
  top: 0;
  z-index: 50;
  position: sticky;
  height: calc(var(--navbar-height) + var(--additional-navbar-height, 0));
  ${(props) => !props.noShadow && 'box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);'}
`;
export const NavbarDesktopWrapper = styled.div `
  padding: 0 36px;
  height: 50px;
`;
export const NavbarDesktopInnerContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;
export const LogoWrapper = styled.div `
  cursor: pointer;
  padding-right: 20px;
`;
export const NavbarContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const NavbarLinksWrapper = styled.ul `
  display: flex;
  align-items: center;
`;
export const NavbarLinkWrapper = styled.li `
  color: #000000;
  ${(props) => `padding: 0 ${props.paddingRight || 20}px 0 0;`}
  white-space: nowrap;
  svg {
    display: inline;
    margin-left: 8px;
  }
`;
export const NavbarLink = styled.a `
  font-weight: ${(props) => (props.expanded ? 700 : 400)};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
    svg {
      fill: ${(props) => props.theme.colors.hover};
    }
  }
`;
export const NavbarDropdown = styled.div `
  width: 100%;
  height: 560px;
  background-color: #fff;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 3;
  display: flex;
`;
export const DropdownWrapper = styled(NavbarDropdown) `
  padding: 30px 0;
`;
export const DropdownListWrapper = styled.div `
  padding: 0;
  position: relative;
  overflow: hidden;
`;
export const DropdownListContainer = styled.ul `
  max-height: 460px;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 40px;
`;
export const DrawerContent = styled.div `
  padding: 21px 16px 0;
  flex-grow: 2;
  height: calc(100% - 40px);
  overflow: auto;
  margin-top: 40px;
`;
export const CategoryLogo = styled.div `
  background-image: url(${(props) => props.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 90px;
  min-width: 90px;
  height: 50px;
  margin-bottom: 20px;
  margin-top: 4px;
`;
export const DrawerHeader = styled.div `
  position: absolute;
  top: 0;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
`;
export const HamburgerWrapper = styled.div `
  position: absolute;
  left: 15px;
  display: flex;
  align-items: center;
  > .hamburger {
    transform: scale(0.8);
  }
`;
export const IconWrapper = styled.div `
  padding: 12px 15px;
`;
export const MobileNavbarLinkWrapper = styled.div `
  color: #000000;
  padding: 0 0 20px 0;
  cursor: pointer;
  ${(props) => props.active
    ? `
    font-weight: 800;
  `
    : `
    font-weight: 400;
  `}
  svg {
    display: inline;
    margin-left: 8px;
  }
`;
export const NavbarMobileContainer = styled.div `
  padding: 0 15px 0 ${(props) => (props.isCheckout ? '15' : '55')}px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-${(props) => (props.isCheckout ? 'around' : 'between')};
  position: relative;
  ${(props) => !props.isCheckout && 'box-shadow: 0 15px 15px rgba(0,0,0,.1);'}
`;
export const NavbarMobileWrapper = styled.div `
  height: 100%;
  display: flex;
  flex-flow: column;
`;
export const NavigationPanelsContent = styled.div `
  &:not(:last-child) {
    border-bottom: 1px solid #bababa;
  }
  margin-bottom: 20px;
`;
export const StoreLocationMobile = styled.div `
  font-weight: 400;
  font-size: 14px;
  margin: 10px 0;
`;
export const MobileNavbarLink = styled.div `
  ${(props) => props.isHeader && 'margin-left: 15px;'}
`;
export const IconsNavbarMobileContainer = styled.div `
  display: flex;
  flex-direction: row;
`;
export const CartIconWrapper = styled.div `
  position: relative;
  width: ${(props) => (props.mobile ? 25 : 27)}px;
`;
export const ItemsQuantityIndicator = styled.div `
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 100%;
  color: white;
  position: absolute;
  top: -5px;
  left: -8px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;
export const StyledCategoryItem = styled.li `
  break-inside: avoid;
`;
export const StyledTitleCategory = styled.a `
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 20px;
  font-weight: 700;
  display: block;
  white-space: break-spaces;
  width: fit-content;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;
export const StyledItem = styled.a `
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  display: block;
  white-space: break-spaces;
  width: fit-content;
  &:hover {
    color: ${(props) => props.theme.colors.hover};
  }
`;
export const StyledFirstCategory = styled.div `
  display: flex;
  align-items: center;
  svg {
    width: 10px;
    height: 10px;
    margin-left: 0;
    margin-right: 10px;
  }
`;
export const CategoriesAndButtonWrapper = styled.div `
  height: calc(100% - 55px);
  position: relative;
  overflow: auto;
`;
export const CategoriesButtonWrapper = styled.div `
  margin-top: 20px;
  padding: 0;
  position: sticky;
  bottom: 0;
  padding-bottom: 35px;
  left: 0;
  right: 0;
  background-color: white;
`;
export const DropdownBottomGradient = styled.div `
  position: absolute;
  left: 0;
  right: 4px;
  height: 100px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 1)
  );
  z-index: 999;
  bottom: -25px;
  pointer-events: none;
`;
export const DropdownListInner = styled.div `
  ${(props) => props.childrenIsExist
    ? `
    column-count: 4;
    column-gap: 25px;

    @media screen and (max-width: 1200px) {
      column-count: 3;
    }

    ${StyledCategoryItem} + ${StyledCategoryItem} {
      margin-top: 25px;
    }
  `
    : ''}
`;
export const ContentInnerWrapper = styled.div `
  margin-top: 30px;
`;
export const SearchBarWrapper = styled.div `
  width: 376px;

  @media screen and (max-width: 1345px) {
    width: 235px;
  }
`;
export const LogoAndSearchBarWrapper = styled.div `
  display: flex;
  align-items: center;
`;
export const NavbarIconWrapper = styled.div `
  height: 27px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.hover};
    }
  }
`;
export const NavbarIconsWrapper = styled.div `
  display: flex;
  margin-left: 2px;

  ${NavbarIconWrapper} + ${NavbarIconWrapper} {
    margin-left: ${(props) => (props.mobile ? 12 : 20)}px;
  }
`;
