import styled from 'styled-components';
export const CheckoutSummaryDivider = styled.div `
  border-bottom: 1px solid #d8d8d8;
  margin: 13px 0 5px;
`;
export const CheckoutSummaryBigPrice = styled.div `
  color: #d9000d;
  font-size: 28px;
  line-height: 1;
  font-weight: bold;
`;
