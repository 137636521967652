import styled from 'styled-components';
export const MainContainer = styled.div `
  position: relative;
  width: 100%;
  @media (min-width: 883px) {
    margin-top: 0;
  }
  --min-height-offset: 327px;
  @media screen and (max-width: 500px) {
    --min-height-offset: ${(props) => (props.isProductPage ? '40px' : '150px')};
  }
  min-height: calc(100vh - var(--min-height-offset));
`;
