// In this file we have our heading definition
// Below is link to our design library for reference:
// https://xd.adobe.com/view/9639f77f-48d9-48e8-b82b-f83c75de5af0-c36a/screen/df94e187-d8ca-41a9-89df-fa5e9ce00c40
import { __rest } from "tslib";
import styled, { css } from 'styled-components';
import React from 'react';
const mobileBreakpoint = 700;
const REGULAR = 400;
const SEMIBOLD = 600;
const BOLD = 700;
const fontSizes = {
    h1: 34,
    h2: 26,
    h3: 24,
    h4: 20,
    h5: 16,
};
const headerMargins = {
    h1: 30,
    h2: 25,
    h3: 20,
    h4: 20,
    h5: 15,
};
const headingCommon = (props, scaleFontOnMobile) => `
  --font-size-multiplier: 1;
  ${scaleFontOnMobile
    ? `
    @media screen and (max-width: ${mobileBreakpoint}px) {
      --font-size-multiplier: 0.7;
    }
  `
    : ''}
  line-height: 1.1;
  font-family: ${props.font ? props.theme.font[props.font] : props.theme.font.primary};
  ${props.color && props.theme.colors[props.color]
    ? `color: ${props.theme.colors[props.color]};`
    : ''}
  // font-weight: ${BOLD};
  ${props.weight === 'semibold' ? `font-weight: ${SEMIBOLD}` : ''};
  ${props.weight === 'normal' ? `font-weight: ${REGULAR}` : ''};
`;
const headerStyles = css `
  ${(props) => headingCommon(props, props.size === 'h1' || props.size === 'h2' || props.size === 'h3')};

  /* H1 */
  ${(props) => props.size === 'h1'
    ? `
    font-size: calc(${fontSizes.h1}px * var(--font-size-multiplier));
    font-weight: ${BOLD}; // H1 is always bold in our design library
    margin-bottom: ${props.marginBottom || headerMargins.h1}px;  
  `
    : ''}

  /* H2 */
  ${(props) => props.size === 'h2'
    ? `
    font-size: calc(${fontSizes.h2}px * var(--font-size-multiplier));
    margin-bottom: ${props.marginBottom || headerMargins.h2}px;
  `
    : ''}

  /* H3 */
  ${(props) => props.size === 'h3'
    ? `
    font-size: calc(${fontSizes.h3}px * var(--font-size-multiplier));
    margin-bottom: ${props.marginBottom || headerMargins.h3}px;
  `
    : ''}

  /* H4 */
  ${(props) => props.size === 'h4'
    ? `
    font-size: calc(${fontSizes.h4}px * var(--font-size-multiplier));
    margin-bottom: ${props.marginBottom || headerMargins.h4}px;
  `
    : ''}

  /* H5 */
  ${(props) => props.size === 'h5'
    ? `
    font-size: ${fontSizes.h5}px;
    margin-bottom: ${props.marginBottom || headerMargins.h5}px;
  `
    : ''}
`;
const HeadingH1 = styled.h1 `
  ${headerStyles}
`;
const HeadingH2 = styled.h2 `
  ${headerStyles}
`;
const HeadingH3 = styled.h3 `
  ${headerStyles}
`;
const HeadingH4 = styled.h4 `
  ${headerStyles}
`;
const HeadingH5 = styled.h5 `
  ${headerStyles}
`;
const getComponentForHeading = (tag) => {
    switch (tag) {
        case 'h5':
            return HeadingH5;
        case 'h4':
            return HeadingH4;
        case 'h3':
            return HeadingH3;
        case 'h2':
            return HeadingH2;
        case 'h1':
        default:
            return HeadingH1;
    }
};
export const Heading = (_a) => {
    var { weight = 'bold' } = _a, props = __rest(_a, ["weight"]);
    const tag = props.tag || props.size;
    const HeaderComponent = getComponentForHeading(tag);
    return (React.createElement(HeaderComponent, Object.assign({}, props, { weight: weight, size: props.size }), props.children));
};
