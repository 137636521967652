import styled from 'styled-components';
export const CouponFormWrapper = styled.div ``;
export const CouponInputContainer = styled.div `
  display: flex;
  align-items: flex-start;
  width: 100%;

  button {
    margin-left: -1px;
    position: relative;
    z-index: 1;
    height: 42px !important;
  }
`;
export const CouponInputWrapper = styled.div `
  ${(props) => props.hasError
    ? `
    input {
      border-color: #D9000D !important;
    }
  `
    : `
  `}
  width: 100%;
`;
export const CouponErrorMsg = styled.p `
  color: #d9000d;
  font-size: 13px;
  margin-top: 2px;
  margin-bottom: 0;
`;
