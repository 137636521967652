import { __awaiter } from "tslib";
import React, { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { notify } from '@@Helpers/notifications';
import { NotificationType } from '@grupakmk/libstorefront';
import FbIcon from '@@Assets/icons/fb.svg';
import GoogleIcon from '@@Assets/icons/google.svg';
import { useLang } from '@@Hooks/useLang';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
import { useSelector } from 'react-redux';
import { dispatch } from '@@Redux/dispatch';
import { socialLogin } from '@@Redux/thunks/user.thunks';
import LSF from '@@Storefront';
import { ButtonsWrapper, Title, SocialsContainer, StyledFacebook, StyledGoogle, } from './styled';
const SocialLoginButtons = (props) => {
    var _a, _b;
    const { socials, title } = props;
    const mageConfig = useSelector(mageConfigSelector);
    const { translate } = useLang();
    const handleSocialLoginError = (e, provider) => {
        console.error(e);
        if ((e === null || e === void 0 ? void 0 : e.error) === 'idpiframe_initialization_failed') {
            return;
        }
        if ((provider === 'google' && (e === null || e === void 0 ? void 0 : e.error) === 'popup_closed_by_user') ||
            (provider === 'facebook' && (e === null || e === void 0 ? void 0 : e.status) === 'unknown')) {
            notify(translate('actions.close_social_login'), NotificationType.WARN);
        }
        else {
            notify(translate('actions.social_login_error'), NotificationType.ERROR);
        }
    };
    useEffect(() => {
        if (props.googleToken) {
            checkAccessTokenAndLoginUser('google', props.googleToken);
        }
    }, [props.googleToken]);
    const checkAccessTokenAndLoginUser = (provider, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
        const token = yield dispatch(socialLogin({ provider, token: accessToken }));
        if (token) {
            yield LSF.CartService.recalculate({
                quickSync: true,
                forceServerSync: true,
            });
            yield LSF.CartService.getShippingMethods();
        }
    });
    const onGoogleSuccessResponse = (response) => {
        if (response === null || response === void 0 ? void 0 : response.tokenId) {
            checkAccessTokenAndLoginUser('google', response.tokenId);
        }
        else {
            handleSocialLoginError(null, 'google');
            console.error('Missing tokenId from google response: ', response);
        }
    };
    const onFacebookSuccessResponse = (response) => {
        if (response === null || response === void 0 ? void 0 : response.accessToken) {
            checkAccessTokenAndLoginUser('facebook', response.accessToken);
        }
        else {
            handleSocialLoginError(null, 'facebook');
            console.error('Missing accessToken from facebook response: ', response);
        }
    };
    const getRedirectUri = () => {
        var _a;
        return ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.origin) ? window.location.origin + '/login' : '';
    };
    const renderSocialButton = (provider, index) => {
        var _a, _b, _c, _d;
        if (provider === 'facebook' && ((_a = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.socials) === null || _a === void 0 ? void 0 : _a.facebook)) {
            return (React.createElement(FacebookLogin, { key: index, appId: (_b = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.socials) === null || _b === void 0 ? void 0 : _b.facebook, autoLoad: false, disableMobileRedirect: true, fields: "name,email,picture", callback: onFacebookSuccessResponse, render: (renderProps) => (React.createElement(StyledFacebook, { onClick: renderProps.onClick },
                    React.createElement(FbIcon, null),
                    translate('actions.login_with_facebook'))) }));
        }
        else if (provider === 'google' && ((_c = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.socials) === null || _c === void 0 ? void 0 : _c.google)) {
            return (React.createElement(GoogleLogin, { key: index, clientId: (_d = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.socials) === null || _d === void 0 ? void 0 : _d.google, render: (renderProps) => (React.createElement(StyledGoogle, { onClick: renderProps.onClick, disabled: renderProps.disabled },
                    React.createElement(GoogleIcon, null),
                    translate('actions.login_with_google'))), onSuccess: onGoogleSuccessResponse, uxMode: "redirect", redirectUri: getRedirectUri(), onFailure: (e) => handleSocialLoginError(e, provider), cookiePolicy: 'single_host_origin' }));
        }
        return '';
    };
    return (React.createElement(React.Fragment, null, (socials === null || socials === void 0 ? void 0 : socials.length) > 0 &&
        (((_a = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.socials) === null || _a === void 0 ? void 0 : _a.google) || ((_b = mageConfig === null || mageConfig === void 0 ? void 0 : mageConfig.socials) === null || _b === void 0 ? void 0 : _b.facebook)) && (React.createElement(SocialsContainer, null,
        title && React.createElement(Title, null, title),
        React.createElement(ButtonsWrapper, null, socials.map((el, index) => renderSocialButton(el, index)))))));
};
export default SocialLoginButtons;
