import React from 'react';
import styled from 'styled-components';
import { Icon } from '@@Components/Icon/Icon';
import { Link } from '@kmk/components/src/Link';
const SocialButtonsContainer = styled.ul `
  width: 100%;
  margin-top: 2em;
  li {
    display: inline-block;
    margin-right: 15px;
  }
  svg {
    fill: #fff;
  }
`;
export const SocialButtons = () => (React.createElement(SocialButtonsContainer, null,
    React.createElement("li", null,
        React.createElement(Link, { to: "//www.facebook.com/LibertyMotoStorePolska", target: "_blank", title: "facebook" },
            React.createElement(Icon, { icon: "facebook", width: "12", height: "22" }))),
    React.createElement("li", null,
        React.createElement(Link, { to: "//www.youtube.com/@libertymotostore", target: "_blank", title: "youtube" },
            React.createElement(Icon, { icon: "youtube", width: "28", height: "20" }))),
    React.createElement("li", null,
        React.createElement(Link, { to: "//www.instagram.com/liberty_motostore/", target: "_blank", title: "instagram" },
            React.createElement(Icon, { icon: "instagram", width: "20", height: "20" }))),
    React.createElement("li", null,
        React.createElement(Link, { to: "//www.tiktok.com/@libertymotostore", target: "_blank", title: "tiktok" },
            React.createElement(Icon, { icon: "tiktok", width: "20", height: "20" })))));
