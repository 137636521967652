import styled from 'styled-components';
export const CategoryTreeContainer = styled.div `
  display: flex;
  flex-direction: column;
  position: sticky;
  top: ${(props) => (props.largeTop ? '120px' : '70px')};
  min-height: fit-content;
  max-height: 87vh;
  overflow: auto;
  margin-right: 20px;
  @media (max-width: 1000px) {
    top: 70px;
  }
  ${(props) => props.mobileMode &&
    `
    position: static;
    margin-right: 0;
    min-height: none;
    max-height: none;
    overflow: hidden;
  `};
`;
export const CategoryTitle = styled.h4 `
  margin-bottom: 20px;
`;
export const CategoryTreeNodeItemChildren = styled.div `
  position: relative;
  margin-left: 15px;
`;
export const CategoryTreeNodeItemNameButton = styled.div `
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 0 0 22px;
  width: fit-content;
  min-height: 25px;
  position: relative;
`;
export const CategoryTreeNodeItemName = styled.p `
  text-align: left;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  ${(props) => props.isActive && `color: ${props.theme.colors.secondary}`};
  ${(props) => !props.mobileMode &&
    `
    &:hover {
      border-bottom: 1px solid ${props.theme.input.borderColors.hover};
    }
  `};
`;
export const CategoryTreeNodeItemButton = styled.button `
  color: #000000;
  min-width: 22px;
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  ${(props) => props.side === 'right' &&
    `
    justify-content: flex-end;
    margin-left: 10px;
  `}
  ${(props) => props.side === 'left' &&
    `
    position: absolute;
    left: 0;
    justify-content: flex-start;
    margin-left: -5px;
  `}
`;
export const CategoryTreeNodeItem = styled.div `
  &:not(.level2, .level3) {
    &:not(:last-child) {
      > ${CategoryTreeNodeItemNameButton} {
        margin-bottom: 3px;
      }
      > ${CategoryTreeNodeItemChildren} {
        margin-bottom: 3px;
      }
    }
  }

  &.level2 {
    > ${CategoryTreeNodeItemChildren} {
      margin: -7px 0 27px 25px;
      ${(props) => props.mobileMode && ' margin: -7px 0 18px 0;'};
    }
    > ${CategoryTreeNodeItemNameButton} {
      margin-bottom: ${(props) => (props.mobileMode ? '20px' : '13px')};
      font-weight: bold;
      font-size: 16px;
      ${(props) => props.mobileMode &&
    `
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 0;
      `};
    }
    &:not(:first-child) {
      ${(props) => props.mobileMode &&
    `
        border-top: 1px solid ${props.theme.input.borderColors.default};
        padding-top: 20px;
      `}
    }
  }

  &.level3 {
    > ${CategoryTreeNodeItemNameButton} {
      font-weight: bold;
      margin-bottom: 10px;
    }
    > ${CategoryTreeNodeItemChildren} {
      margin: -7px 0 12px 0;
    }
  }

  &.level4 {
    > ${CategoryTreeNodeItemNameButton} {
      font-weight: 500;
    }
  }

  &.level6 {
    > ${CategoryTreeNodeItemNameButton} {
      color: #363535;
    }
  }
`;
export const ButtonWrapper = styled.div `
  ${(props) => props.margin && `margin: ${props.margin}`};
`;
