import styled from 'styled-components';
export const ResponsiveTable = styled.table `
  font-size: 14px;
  width: 100%;

  thead tr.headers {
    border-bottom: 1px solid #000;
  }

  th {
    font-weight: normal;
    padding: 10px 15px;
    text-align: left;

    &:first-of-type {
      padding-left: 0;
    }
  }

  td {
    padding: 15px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
      text-align: right;
    }
  }

  tbody tr {
    border-bottom: solid 1px #bababa;

    &:hover {
      border-bottom: solid 1px #525252;
    }
  }

  @media only screen and (max-width: 1023px) {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tbody tr {
      padding: 5px 0;
    }

    td {
      border: none;
      position: relative;
      padding: 3px 0 3px 100px !important;

      &:last-of-type {
        text-align: left;
      }
    }

    td:before {
      position: absolute;
      top: 3px;
      left: 0;
      white-space: nowrap;
      content: attr(data-title);
    }
  }
`;
