import styled from 'styled-components';
export const ProductsSliderContainer = styled.div `
  width: 100%;
  height: 100%;
  max-width: 1440px;
`;
export const SliderWrapper = styled.div `
  position: relative;
  width: 100%;
  height: 100%;
  height: 330px;
  max-width: ${(props) => (props.shortSlider ? '1019px' : '1367px')};
  @media (max-width: 1300px) {
    height: 310px;
  }
  @media (max-width: 550px) {
    height: 274px;
  }
`;
export const SlideButton = styled.div `
  width: 26px;
  height: 40px;
  background-color: rgba(203, 203, 203, 0.5);
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SlideLeftButton = styled(SlideButton) `
  left: -13px;
`;
export const SlideRightButton = styled(SlideButton) `
  right: -13px;
`;
