import styled from 'styled-components';
export const CheckoutCouponsContainer = styled.div `
  display: flex;
  flex-flow: column;
`;
export const CheckoutCouponsExpandButtonWrapper = styled.div `
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;
export const CouponLabel = styled.div `
  font-size: 1.3rem;
  font-weight: 700;
  margin-right: 1rem;
`;
export const CheckoutCouponsInputs = styled.div ``;
export const CouponInputWrapper = styled.div `
  margin-top: 1rem;
  display: flex;
  flex-flow: row nowrap;
  height: 44px;
  & * {
    height: 100%;
  }
  & > button {
    max-height: 44px;
  }
`;
export const CheckoutCouponHeaderWrapper = styled.div `
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;

  h2 {
    margin-right: 8px;
    font-size: 16px;
    user-select: none;
  }
`;
export const CouponSection = styled.div ``;
