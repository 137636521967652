import { __awaiter } from "tslib";
import React, { useState } from 'react';
import Input from '@@Components/Input/Input';
import { useForm } from 'react-hook-form';
import { validatePassword } from '@@Helpers/validators';
import { useLang } from '@@Hooks/useLang';
import { notify } from '@@Helpers/notifications';
import { NotificationType } from '@adsel/libstorefront';
import { userProfileSelector } from '@@Redux/selectors/user.selectors';
import { useSelector } from 'react-redux';
import { changePassword } from '@@Storefront/actions/user/changePassword';
import { ChangeUserPasswordFormContainer, StyledButton, Title, StyledForm, } from './styled';
export const ChangeUserPasswordForm = () => {
    const { register, handleSubmit, errors } = useForm({ mode: 'onChange' });
    const userProfile = useSelector(userProfileSelector);
    const { translate } = useLang();
    const [isFormModified, setIsFormModified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const passwordData = {
            currentPassword: data.oldPassword,
            newPassword: data.newPassword,
        };
        try {
            setIsLoading(true);
            yield changePassword(passwordData);
            notify(translate('messages.password_change_success'), NotificationType.SUCCESS);
        }
        catch (error) {
            notify(translate('messages.password_change_error'), NotificationType.ERROR);
        }
        setIsLoading(false);
    });
    return (React.createElement(React.Fragment, null, userProfile && Object.keys(userProfile).length > 0 && (React.createElement(ChangeUserPasswordFormContainer, null,
        React.createElement(Title, null, translate('headers.password_change')),
        React.createElement(StyledForm, { autoComplete: "none", noValidate: true, onSubmit: handleSubmit(onSubmit) },
            React.createElement(Input, { label: translate('checkout.old_password'), type: "password", name: "oldPassword", placeholder: `${translate('common.enter')} ${translate('checkout.old_password')}`, onChange: () => setIsFormModified(true), error: errors['oldPassword'], ref: register({ validate: validatePassword }) }),
            React.createElement(Input, { label: translate('checkout.new_password'), type: "password", name: "newPassword", placeholder: `${translate('common.enter')} ${translate('checkout.new_password')}`, onChange: () => setIsFormModified(true), error: errors['newPassword'], ref: register({ validate: validatePassword }) }),
            React.createElement(StyledButton, { loading: isLoading, isVisible: isFormModified }, translate('actions.save_password')))))));
};
