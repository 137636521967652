import LoginForm from '@@Forms/LoginForm';
import { ResetPassword } from '@@Components/ResetPassword';
import React, { useState } from 'react';
import { translate } from '@@Helpers/translateGlobal';
import { Heading } from '@@Components/Heading';
import { LoginFormWrapper } from './styles';
export const CheckoutLoginPopupContent = () => {
    const [remindPassword, setRemindPassword] = useState(false);
    return (React.createElement("div", null, remindPassword ? (React.createElement("div", null,
        React.createElement(ResetPassword, { onSuccess: () => setRemindPassword(false), withBackButton: true, onBack: () => setRemindPassword(false) }))) : (React.createElement(LoginFormWrapper, null,
        React.createElement(Heading, { size: "h3", style: {
                lineHeight: '1.5',
                marginBottom: '30px',
                whiteSpace: 'pre-wrap',
            } }, translate('account.are_you_user_two_lines')),
        React.createElement(LoginForm, { onRemindPasswordClick: () => {
                setRemindPassword(true);
            } })))));
};
