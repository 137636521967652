import styled from 'styled-components';
export const ProductPageContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ProductPageInnerContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1135px;
  padding-top: 20px;
  @media screen and (max-width: 1255px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media screen and (max-width: 1135px) {
    width: 900px;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (max-width: 960px) {
    width: 822px;
  }
  @media screen and (max-width: 890px) {
    width: 762px;
  }
  @media screen and (max-width: 810px) {
    width: 100%;
    padding: 10px 15px 0 15px;
  }
`;
export const TabsWrapper = styled.div `
  width: 100%;
`;
export const SliderContainer = styled.div `
  width: 100%;
`;
export const ChartSizeContent = styled.div `
  width: 80%;
  * {
    ::-webkit-scrollbar {
      height: var(--scrollbar-width);
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    ::-webkit-scrollbar-thumb {
      background-color: black;
      outline: 1px solid white;
    }
  }
  img {
    max-width: 100%;
    height: auto !important;
  }
  @media screen and (max-width: 1135px) {
    width: 100%;
  }
  table {
    margin: 0 auto 20px !important;
    width: auto !important;
    height: auto !important;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 20px;
    align-items: center;
    justify-content: start;
  }
  tr {
    td {
      border: 1px solid black;
      padding: 5px 15px;
      transition: 0.3s background;
      min-width: 50px;
      font-weight: 500;
      &:nth-child(1) {
        text-align: left;
        text-transform: uppercase;
      }
      &:nth-child(n + 2) {
        text-align: center;
      }
      p:last-child {
        margin: 0;
      }
    }
    &:nth-child(even) {
      background: #f3f3f3;
    }
  }
  ${(props) => props.theme.mixins.mobile('padding-bottom: 30px;')}
`;
