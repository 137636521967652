import { __awaiter } from "tslib";
import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '@@Components/Icon/Icon';
import * as CheckoutFrontThunks from '@@Redux/thunks/checkout.thunks';
import { dispatch } from '@@Redux/dispatch';
import { Spinner } from '@@Components/Spinner';
const CouponCodesWrapper = styled.div `
  display: flex;
  flex-flow: column;
  margin-bottom: 0px; // to counteract the bottom spacing caused by RemoveCouponButton's padding
`;
const CouponCodeWrapper = styled.div `
  display: flex;
  align-items: center;
`;
const CouponName = styled.div `
  font-size: 14px;
  font-weight: bold;
  flex-grow: 2;
`;
const RemoveCouponButton = styled.div `
  padding: 10px;
  cursor: pointer;
`;
export const CouponCodes = (props) => {
    const [isPending, setIsPending] = useState(false);
    const onCouponRemove = (coupon) => __awaiter(void 0, void 0, void 0, function* () {
        setIsPending(true);
        yield dispatch(CheckoutFrontThunks.removeCoupon(coupon));
        setIsPending(false);
    });
    return (React.createElement(CouponCodesWrapper, null, props.coupons.map((coupon, i) => (React.createElement(CouponCodeWrapper, { key: i },
        React.createElement(CouponName, null, coupon),
        isPending ? (React.createElement(Spinner, { size: 16 })) : (React.createElement(RemoveCouponButton, { onClick: () => onCouponRemove(coupon) },
            React.createElement(Icon, { width: 20, height: 17, icon: "trash" }))))))));
};
