import React, { useEffect, useState } from 'react';
import { ProductsSlider } from '@@Components/ProductsSlider';
import { useLang } from '@@Hooks/useLang';
import { ResponsiveMatcher } from '@@Components/ResponsiveMatcher';
import { getBestsellers } from '@@Storefront/actions/product/getBestsellers';
import { StyledRoot } from './styled';
export const PopularProducts = ({ fullWidth, }) => {
    const { translate } = useLang();
    const [items, setItems] = useState(null);
    useEffect(() => {
        getBestsellers().then((res) => res && setItems(res));
    }, []);
    return (React.createElement(React.Fragment, null, (items === null || items === void 0 ? void 0 : items.length) > 0 && (React.createElement(StyledRoot, { fullWidth: fullWidth }, fullWidth ? (React.createElement(ProductsSlider, { title: translate('products.popular.title'), products: items })) : (React.createElement(ResponsiveMatcher, { mobileBreakpoint: 900, desktop: React.createElement(ProductsSlider, { title: translate('products.popular.title'), shortSlider: true, products: items }), mobile: React.createElement(ProductsSlider, { title: translate('products.popular.title'), products: items }) }))))));
};
