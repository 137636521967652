import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { checkoutSelector } from '@@Redux/selectors/checkout.selectors';
import { useSelector } from 'react-redux';
import { translate } from '@@Helpers/translateGlobal';
import LSF from '@@Storefront/index';
import { isDifferentInvoiceAddress } from '@@Helpers/isDifferentInvoiceAddress';
import { CheckoutUserDataWrapper } from './styles';
export const CheckoutUserData = () => {
    const checkout = useSelector(checkoutSelector);
    const shippingDetails = checkout === null || checkout === void 0 ? void 0 : checkout.shippingDetails;
    const paymentDetails = checkout === null || checkout === void 0 ? void 0 : checkout.paymentDetails;
    const isInvoiceCompanyDataPresent = (paymentDetails === null || paymentDetails === void 0 ? void 0 : paymentDetails.company) && paymentDetails.company !== '-';
    const [countries, setCountries] = useState([]);
    const getCountryName = (countryId) => {
        var _a;
        const name = (_a = countries === null || countries === void 0 ? void 0 : countries.find((country) => country.id === countryId)) === null || _a === void 0 ? void 0 : _a.full_name_locale;
        return name || countryId;
    };
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const countries = yield LSF.CountriesService.getCountriesList();
                setCountries(countries.filter((c) => c.full_name_locale));
            }
            catch (error) { }
        }))();
    }, []);
    return (React.createElement(React.Fragment, null, shippingDetails && (React.createElement(CheckoutUserDataWrapper, null,
        React.createElement("p", null,
            React.createElement("b", null,
                shippingDetails.firstName,
                " ",
                shippingDetails.lastName),
            React.createElement("br", null),
            shippingDetails.streetAddress,
            " ",
            shippingDetails.houseNo || '',
            shippingDetails.apartmentNumber
                ? `/${shippingDetails.apartmentNumber}`
                : '',
            React.createElement("br", null),
            shippingDetails.zipCode,
            " ",
            shippingDetails.city,
            React.createElement("br", null),
            getCountryName(shippingDetails.country),
            React.createElement("br", null),
            React.createElement("span", null,
                translate('checkout_form.phone_short'),
                ' ',
                shippingDetails.phoneNumber)),
        paymentDetails && isDifferentInvoiceAddress() && (React.createElement("p", null,
            React.createElement("b", null, translate('checkout_form.invoice_data')),
            React.createElement("br", null),
            isInvoiceCompanyDataPresent ? (React.createElement(React.Fragment, null,
                paymentDetails.company,
                paymentDetails.vat_id ? (React.createElement(React.Fragment, null,
                    React.createElement("br", null),
                    translate('checkout_form.nip'),
                    ": ",
                    paymentDetails.vat_id)) : (''))) : (React.createElement(React.Fragment, null,
                paymentDetails.firstName,
                " ",
                paymentDetails.lastName)),
            React.createElement("br", null),
            paymentDetails.streetAddress,
            " ",
            paymentDetails.houseNo,
            paymentDetails.apartmentNumber
                ? `/${paymentDetails.apartmentNumber}`
                : '',
            React.createElement("br", null),
            paymentDetails.zipCode,
            " ",
            paymentDetails.city,
            React.createElement("br", null),
            getCountryName(paymentDetails.country),
            React.createElement("br", null)))))));
};
