import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as CheckoutSelectors from '@@Redux/selectors/checkout.selectors';
import LSF from '@@Storefront/index';
import { Radio } from '@@Components/Input/Radio';
import { setPaymentMethod } from '@@Redux/thunks/checkout.thunks';
import { dispatch } from '@@Redux/dispatch';
import Button from '@@Components/Buttons/Button';
import { translate } from '@@Helpers/translateGlobal';
import { checkoutSelector } from '@@Redux/selectors/checkout.selectors';
import { Spinner } from '@@Components/Spinner';
import { SubmitButtonWrapper, PaymentMethodsWrapper, PaymentMethodWrapper, } from './styled';
export const PaymentSelection = ({ onSelect }) => {
    var _a;
    const { paymentMethods } = useSelector(CheckoutSelectors.checkoutSelector);
    const checkout = useSelector(checkoutSelector);
    const [isPaymentSelected, setIsPaymentSelected] = useState(false);
    const [localPaymentMethod, setLocalPaymentMethod] = useState();
    const selectPaymentMethod = () => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(setPaymentMethod(localPaymentMethod));
        if (onSelect) {
            onSelect();
        }
    });
    useEffect(() => {
        var _a;
        if ((_a = checkout === null || checkout === void 0 ? void 0 : checkout.paymentDetails) === null || _a === void 0 ? void 0 : _a.paymentMethod) {
            setLocalPaymentMethod(checkout.paymentDetails.paymentMethod);
        }
    }, [(_a = checkout === null || checkout === void 0 ? void 0 : checkout.paymentDetails) === null || _a === void 0 ? void 0 : _a.paymentMethod]);
    useEffect(() => {
        LSF.CartService.getPaymentMethods();
    }, []);
    useEffect(() => {
        const selectedPaymentCode = localPaymentMethod === null || localPaymentMethod === void 0 ? void 0 : localPaymentMethod.code;
        const hasPaymentSelection = selectedPaymentCode &&
            paymentMethods.find((method) => method.code === selectedPaymentCode);
        setIsPaymentSelected(hasPaymentSelection);
    }, [paymentMethods, localPaymentMethod === null || localPaymentMethod === void 0 ? void 0 : localPaymentMethod.code]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PaymentMethodsWrapper, null, paymentMethods.length ? (React.createElement(React.Fragment, null, paymentMethods.map((item, index) => {
            var _a, _b;
            return (item === null || item === void 0 ? void 0 : item.code) && (React.createElement(PaymentMethodWrapper, { key: index },
                React.createElement(Radio, { name: "payment-method", value: item.code, onChange: () => setLocalPaymentMethod(item), checked: ((_b = (_a = checkout.paymentDetails) === null || _a === void 0 ? void 0 : _a.paymentMethod) === null || _b === void 0 ? void 0 : _b.code) ===
                        item.code }, item.title)));
        }))) : (React.createElement(Spinner, null))),
        React.createElement(SubmitButtonWrapper, null,
            React.createElement(Button, { onClick: () => selectPaymentMethod(), appearance: "primary", disabled: !isPaymentSelected, "data-cy": "proceedButton" }, translate('checkout_form.proceed')))));
};
