import React from 'react';
import { useRouter } from '@@Hooks/useRouter';
import { FormSuccessPageContainer, FormSuccessPageWrapper, } from '@@Pages/FormSuccessPage/styled';
import Button from '@@Components/Buttons/Button';
import { Link } from 'react-router-dom';
import { useLang } from '@@Hooks/useLang';
const FormSuccessPage = () => {
    var _a;
    const { location } = useRouter();
    const { translate } = useLang();
    return (React.createElement(FormSuccessPageContainer, null,
        React.createElement(FormSuccessPageWrapper, null,
            React.createElement("h1", null, translate('headers.form_success_confirmation')),
            React.createElement("p", null, ((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.successMessage) ||
                translate('messages.form_success_confirmation')),
            React.createElement(Link, { to: "/" },
                React.createElement(Button, { maxWidth: 210 }, translate('pages.main'))))));
};
export default FormSuccessPage;
