import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { useLang } from '@@Hooks/useLang';
import { getCountriesList } from '@@Storefront/actions/countries/getCountriesList';
import { OrderPreviewSummaryContainer, OrderPreviewSummaryItem, OrderPreviewSummaryHeading, OrderSummaryHeader, } from './styled';
import { OrderSegments } from '../../../CheckoutPage/components/OrderSegments';
const OrderPreviewSummary = (props) => {
    var _a, _b;
    const { translate } = useLang();
    const { order, shipping } = props;
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            setCountries(yield getCountriesList());
        }))();
    }, []);
    const getCountryNameById = (id) => {
        const country = countries.find((country) => country.id === id);
        return (country === null || country === void 0 ? void 0 : country.full_name_locale) || '';
    };
    return (React.createElement(OrderPreviewSummaryContainer, null,
        React.createElement(OrderPreviewSummaryItem, null,
            React.createElement(OrderPreviewSummaryHeading, null, translate('checkout.shipping_address')),
            React.createElement("div", null,
                React.createElement("div", null,
                    shipping.firstname,
                    " ",
                    shipping.lastname),
                React.createElement("div", null, shipping.street.map((street) => {
                    return React.createElement("span", { key: street }, street);
                })),
                React.createElement("div", null,
                    shipping.postcode,
                    " ",
                    shipping.city),
                React.createElement("div", null, getCountryNameById(shipping.country_id)),
                React.createElement("div", null,
                    "tel. ",
                    shipping.telephone))),
        order.billing_address && (React.createElement(OrderPreviewSummaryItem, null,
            React.createElement(OrderPreviewSummaryHeading, null, translate('checkout.billing_info')),
            React.createElement("div", null,
                React.createElement("div", null,
                    order.billing_address.firstname,
                    " ",
                    order.billing_address.lastname),
                React.createElement("div", null, order.billing_address.company),
                order.billing_address.vat_id && (React.createElement("div", null,
                    "NIP: ",
                    order.billing_address.vat_id)),
                React.createElement("div", null, order.billing_address.street.map((street) => {
                    return React.createElement("span", { key: street }, street);
                })),
                React.createElement("div", null,
                    order.billing_address.postcode,
                    " ",
                    order.billing_address.city),
                React.createElement("div", null, getCountryNameById(order.billing_address.country_id))))),
        React.createElement(OrderPreviewSummaryItem, null,
            React.createElement(OrderSummaryHeader, null,
                React.createElement(OrderPreviewSummaryHeading, null, `${translate('checkout.summary')
                    .charAt(0)
                    .toLowerCase()}${translate('checkout.summary').slice(1)}`)),
            ((_a = order === null || order === void 0 ? void 0 : order.extension_attributes) === null || _a === void 0 ? void 0 : _a.total_segments) && (React.createElement(OrderSegments, { selectedShipping: true, totals: (_b = order === null || order === void 0 ? void 0 : order.extension_attributes) === null || _b === void 0 ? void 0 : _b.total_segments })))));
};
export default OrderPreviewSummary;
