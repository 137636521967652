import styled from 'styled-components';
export const StyledProduct = styled.div `
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-bottom: 11px;
  margin-bottom: 11px;
  ${(props) => !props.last &&
    `
    border-bottom: 1px solid #BABABA;   
  `}
`;
export const StyledContent = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const StyledCount = styled.div `
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 10px;
  padding-right: 20px;
`;
export const StyledPrice = styled.div `
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
`;
export const StyledOriginalPrice = styled.span `
  text-decoration: line-through;
  font-weight: 400;
  margin-left: 7px;
`;
export const StyledDelete = styled.div `
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;
export const StyledContainerAnimated = styled.div `
  height: 40px;
  ${(props) => !props.noBorder && `border: 1px solid #D8D8D8; width: 103px;`}
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledImage = styled.img `
  width: 103px;
  object-fit: contain;
  margin-right: 20px;
  @media (max-width: 500px) {
    margin-right: 10px;
    width: 61px;
  }
  cursor: pointer;
`;
export const StyledTitle = styled.div `
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  top: -2px;
  width: 88%;
  @media (max-width: 500px) {
    width: 85%;
  }
`;
export const StyledConfiguredLabel = styled.span `
  margin-right: 5px;
  &:last-child {
    margin-right: 15px;
  }
  color: #525252;
`;
export const StyledGross = styled.div `
  text-align: right;
  font-family: 'Montserrat', serif;
  font-size: 14px;
  line-height: 23px;
  color: black;
  font-weight: bold;
  display: flex;
  align-items: baseline;
`;
export const StyledNet = styled.div `
  text-align: right;
  font-family: 'Montserrat', serif;
  font-size: 14px;
  line-height: 23px;
  color: black;
`;
export const StyledCounter = styled.div `
  > div {
    min-width: 105px;
    max-width: 105px;
    height: 42px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 15px;
  }
`;
export const StyledPrices = styled.div `
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
`;
export const StyledTax = styled.div `
  margin-left: 5px;
  font-weight: normal;
`;
export const StyledPricing = styled.div `
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;
export const StyledId = styled.div ``;
export const ProductPriceWrapper = styled.div ``;
export const ProductPriceContainer = styled.div `
  display: flex;
  align-items: center;
`;
export const ProductOriginalPrice = styled.div `
  font-weight: 400;
  margin-left: 7px;
  text-decoration: line-through;
`;
export const ProductPrice = styled.div `
  font-weight: 700;
`;
