import { __awaiter } from "tslib";
import React, { useState } from 'react';
import Input from '@@Components/Input/Input';
import Button from '@@Components/Buttons/Button';
import { useControlledForm, } from '@@Hooks/useControlledForm';
import { useLang } from '@@Hooks/useLang';
import { dispatch } from '@@Redux/dispatch';
import * as CheckoutFrontThunks from '@@Redux/thunks/checkout.thunks';
import { useSelector } from 'react-redux';
import { cartSelector } from '@@Redux/selectors/cart.selectors';
import { CouponFormWrapper, CouponInputContainer, CouponInputWrapper, CouponErrorMsg, } from './styled';
export const CouponForm = React.forwardRef((props, ref) => {
    const [isPending, setIsPending] = useState(false);
    const cart = useSelector(cartSelector);
    const { translate } = useLang();
    const [errorMsg, setErrorMsg] = useState();
    const { register, errors, handleSubmit } = useControlledForm({ mode: 'onChange', onChange: props.onChange }, ref);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsPending(true);
        try {
            yield dispatch(CheckoutFrontThunks.applyCoupon(data.code));
            setErrorMsg(null);
        }
        catch (error) {
            setErrorMsg('Niepoprawny kod. Spróbuj ponownie.');
        }
        setIsPending(false);
    });
    return (React.createElement(React.Fragment, null, cart.coupons.length === 0 && (React.createElement(CouponFormWrapper, null,
        React.createElement("form", { noValidate: true, autoComplete: "off", onSubmit: handleSubmit(onSubmit) },
            React.createElement(CouponInputContainer, null,
                React.createElement(CouponInputWrapper, { hasError: !!errorMsg },
                    React.createElement(Input, { required: true, name: "code", type: "text", error: errors.code, placeholder: translate('checkout.enter_coupon_code'), ref: register({
                            required: translate('validation.required'),
                        }) }),
                    errorMsg && React.createElement(CouponErrorMsg, null, errorMsg)),
                React.createElement(Button, { loading: isPending, maxWidth: 175 }, "dodaj")))))));
});
