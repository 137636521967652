import React from 'react';
import { Link } from 'react-router-dom';
import { ThumbnailUtils } from '@grupakmk/libstorefront';
import { ResponsiveMatcher } from '@@Components/ResponsiveMatcher';
import isLinkExternal, { removeHostFromUrl } from '@@Helpers/isLinkExternal';
import { CategoryBannerImage, CategoryBannerContainer } from './styled';
export const CategoryBanner = ({ category, }) => {
    const banner = (React.createElement(ResponsiveMatcher, { mobile: (category === null || category === void 0 ? void 0 : category.mobile_image) && (React.createElement(CategoryBannerImage, { src: ThumbnailUtils.getImageSrc({
                image: category === null || category === void 0 ? void 0 : category.mobile_image,
                width: 576,
                height: 270,
                entity: '',
            }) })), desktop: (category === null || category === void 0 ? void 0 : category.header_image) && (React.createElement(CategoryBannerImage, { src: ThumbnailUtils.getImageSrc({
                image: category === null || category === void 0 ? void 0 : category.header_image,
                width: 1019,
                height: 150,
                entity: '',
            }) })), mobileBreakpoint: 576 }));
    const getBannerWithLink = () => {
        if (category === null || category === void 0 ? void 0 : category.header_image_url) {
            if (isLinkExternal(category.header_image_url)) {
                return (React.createElement("a", { href: category.header_image_url, target: "_blank", rel: "noopener noreferrer" }, banner));
            }
            else {
                return (React.createElement(Link, { to: removeHostFromUrl(category.header_image_url) }, banner));
            }
        }
        else {
            return banner;
        }
    };
    return (React.createElement(CategoryBannerContainer, null, getBannerWithLink()));
};
