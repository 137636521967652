import React from 'react';
import { ProductUtils } from '@adsel/libstorefront';
import { useLang } from '@@Hooks/useLang';
import { ProductBadge, ProductBadgesContainer } from './styled';
export const ProductBadges = ({ product, margin, maxWidth, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { translate } = useLang();
    const getDiscountPercentage = (price, originalPrice) => {
        const discount = originalPrice - price;
        const percentage = (discount / originalPrice) * 100;
        return Math.round(percentage);
    };
    const isDiscount = ((_b = (_a = product === null || product === void 0 ? void 0 : product.prices) === null || _a === void 0 ? void 0 : _a.final_price) === null || _b === void 0 ? void 0 : _b.gross) && ((_d = (_c = product === null || product === void 0 ? void 0 : product.prices) === null || _c === void 0 ? void 0 : _c.price) === null || _d === void 0 ? void 0 : _d.gross) &&
        ((_f = (_e = product === null || product === void 0 ? void 0 : product.prices) === null || _e === void 0 ? void 0 : _e.final_price) === null || _f === void 0 ? void 0 : _f.gross) < ((_h = (_g = product === null || product === void 0 ? void 0 : product.prices) === null || _g === void 0 ? void 0 : _g.price) === null || _h === void 0 ? void 0 : _h.gross);
    const discountPercentage = isDiscount
        ? getDiscountPercentage((_k = (_j = product === null || product === void 0 ? void 0 : product.prices) === null || _j === void 0 ? void 0 : _j.final_price) === null || _k === void 0 ? void 0 : _k.gross, (_m = (_l = product === null || product === void 0 ? void 0 : product.prices) === null || _l === void 0 ? void 0 : _l.price) === null || _m === void 0 ? void 0 : _m.gross)
        : null;
    return (React.createElement(React.Fragment, null, product && (React.createElement(ProductBadgesContainer, { margin: margin, maxWidth: maxWidth },
        ProductUtils.isBestseller(product) && (React.createElement(ProductBadge, { type: "bestseller" }, translate('products.labels.bestseller'))),
        ProductUtils.isNewProduct(product) && (React.createElement(ProductBadge, { type: "new" }, translate('products.labels.new'))),
        discountPercentage && (React.createElement(ProductBadge, { type: "discount" }, `-${discountPercentage}%`))))));
};
