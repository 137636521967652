import React, { useState } from 'react';
import { Link } from '@@Components/Link';
import { useLang } from '@@Hooks/useLang';
import { Breadcrumbs } from '@@Components/Breadcrumbs';
import LSF from '@@Storefront';
import LogoutIcon from '@@Assets/icons/logout.svg';
import { LimitedContainer } from '@adsel/liberty-components/src/LimitedContainer';
import { useRouter } from '@@Hooks/useRouter';
import Icon from '@@Components/Icon/Icon';
import { StyledContainer, StyledMenu, StyledContent, StyledTitlePage, StyledLogout, BreadcrumbsWrapper, AccountPageLayoutContainer, } from './styles';
const AccountPageLayout = (props) => {
    const { pathname } = props;
    const isMobile = window.innerWidth < 1024;
    const [open, setOpen] = useState(isMobile);
    const { translate } = useLang();
    const { goTo } = useRouter();
    const logout = () => {
        try {
            LSF.UserService.logout();
            goTo('/login');
        }
        catch (e) {
            console.log('Error during logout:', e);
        }
    };
    const handleBreadcrumbs = () => {
        switch (pathname) {
            case '/account/edit':
                return (React.createElement(Breadcrumbs, null,
                    React.createElement(Link, { to: '/account/edit' }, translate('pages.account'))));
            case '/account/orders-history':
                return (React.createElement(Breadcrumbs, null,
                    React.createElement(Link, { to: "/account/edit" }, translate('nav.my_account')),
                    React.createElement(Link, { to: "/account/orders-history" }, translate('nav.orders_history'))));
            default:
                if (pathname.search('orders-history/') > 0) {
                    const [, , , order] = pathname.split('/');
                    return (React.createElement(Breadcrumbs, null,
                        React.createElement(Link, { to: "/account/edit" }, translate('nav.my_account')),
                        React.createElement(Link, { to: "/account/orders-history" }, translate('nav.orders_history')),
                        React.createElement(Link, { to: `/account/orders-history/${order}` }, translate('nav.orders_history_preview'))));
                }
        }
    };
    const handleOpen = () => setOpen(!open);
    const dropDownMenu = () => (React.createElement(React.Fragment, null,
        React.createElement(Link, { to: '/account/edit', className: pathname === '/account/edit' && 'active' }, `${translate('nav.edit_account').charAt(0).toUpperCase()}${translate('nav.edit_account').slice(1)}`),
        React.createElement(Link, { to: '/account/orders-history', className: pathname === '/account/orders-history' && 'active' }, `${translate('nav.orders_history').charAt(0).toUpperCase()}${translate('nav.orders_history').slice(1)}`),
        React.createElement(StyledLogout, { onClick: () => logout() },
            React.createElement(LogoutIcon, null),
            " ",
            translate('nav.logout'))));
    return (React.createElement(LimitedContainer, { wide: true, nopadding: true },
        React.createElement(AccountPageLayoutContainer, null,
            !isMobile && (React.createElement(BreadcrumbsWrapper, null, handleBreadcrumbs())),
            React.createElement(StyledContainer, { bottom: true },
                React.createElement(StyledMenu, null,
                    React.createElement(StyledTitlePage, { open: open },
                        translate('pages.account'),
                        isMobile && React.createElement(Icon, { onClick: () => handleOpen() })),
                    isMobile ? (open ? dropDownMenu() : '') : dropDownMenu()),
                React.createElement(StyledContent, null, props.children)))));
};
export default AccountPageLayout;
