import styled, { css } from 'styled-components';
import { BannerSide } from './';
export const SliderWithBannerContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const SliderAndBannerWrapper = styled.div `
  display: flex;
  width: 100%;
  height: 330px;
  @media (max-width: 1300px) {
    height: 310px;
  }
  @media (max-width: 550px) {
    height: 274px;
  }
  @media (max-width: 499px) {
    flex-direction: column;
    height: 558px;
  }

  ${({ $isMobile }) => $isMobile &&
    css `
      @media (max-width: 499px) {
        height: auto;
      }
    `}
`;
export const SliderWrapper = styled.div `
  width: 74.6%;
  @media (max-width: 1199px) {
    width: 65.9%;
  }
  @media (max-width: 899px) {
    width: 48.4%;
  }
  @media (max-width: 750px) {
    width: 47.9%;
  }
  @media (max-width: 620px) {
    width: 47.6%;
  }
  @media (max-width: 499px) {
    width: 100%;
  }
`;
export const BannerWrapper = styled.div `
  ${(props) => props.side === BannerSide.Right
    ? 'margin-left: 25px'
    : 'margin-right: 25px'};
  width: 100%;
  @media (min-width: 1440px) {
    max-width: 323px;
  }
  @media (max-width: 1439px) {
    width: 23.6%;
  }
  @media (max-width: 1199px) {
    width: 31.8%;
  }
  @media (max-width: 899px) {
    width: 51.6%;
  }
  @media (max-width: 750px) {
    width: 48%;
  }
  @media (max-width: 620px) {
    width: 47.7%;
  }
  @media (max-width: 499px) {
    height: 274px;
    margin: 0;
    ${(props) => props.side === BannerSide.Right
    ? `
      margin-top: 10px;
    `
    : `
      margin-bottom: 10px;
    `}
    margin-left: -15px;
    width: calc(100% + 15px);
  }
`;
export const BannerImage = styled.div `
  background: url('${(props) => props.background}') no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
`;
export const TitleWrapper = styled.div ``;
