import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@@Components/Input/Checkbox';
import { useLang } from '@@Hooks/useLang';
import styled from 'styled-components';
import { useControlledForm } from '@@Hooks/useControlledForm';
import { useSelector } from 'react-redux';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
const UserNewsletterSubscriptionFormWrapper = styled.div `
  margin: 20px 0;
  text-align: left !important;

  label {
    span {
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;
export const UserNewsletterSubscriptionForm = React.forwardRef((props, ref) => {
    var _a, _b;
    const { translate } = useLang();
    const { errors, control } = useControlledForm({ mode: 'onBlur' }, ref);
    const mageConfig = useSelector(mageConfigSelector);
    return (React.createElement(UserNewsletterSubscriptionFormWrapper, { style: props.style },
        React.createElement(Controller, { control: control, as: Checkbox, name: "newsletter", error: errors.newsletter, defaultValue: false, rules: { required: false } },
            React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: ((_b = (_a = mageConfig.marketing) === null || _a === void 0 ? void 0 : _a.newsletter) === null || _b === void 0 ? void 0 : _b.newsletter_subscribe_text) ||
                        'Zapisz się na darmowy newsletter!',
                } }))));
});
