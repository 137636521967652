import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProductBenefitsBannerContainer, ProductBenefitsBannerItem, } from './styled';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
import { ThumbnailUtils } from '@adsel/libstorefront';
export const ProductBenefitBadges = (props) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [items, setItems] = useState([]);
    const config = useSelector(mageConfigSelector);
    useEffect(() => {
        var _a;
        const ids = (_a = props.product) === null || _a === void 0 ? void 0 : _a.shopping_benefits;
        if ((ids === null || ids === void 0 ? void 0 : ids.length) && !(items === null || items === void 0 ? void 0 : items.length)) {
            const items = ids
                .map((id) => { var _a, _b; return (_b = (_a = config === null || config === void 0 ? void 0 : config.shopping_benefits) === null || _a === void 0 ? void 0 : _a.product_page) === null || _b === void 0 ? void 0 : _b.slider[id]; })
                .filter((item) => item);
            if (items === null || items === void 0 ? void 0 : items.length) {
                setItems(items);
            }
        }
    }, [props.product, config, items]);
    useEffect(() => {
        let interval;
        if ((items === null || items === void 0 ? void 0 : items.length) > 1 && !interval) {
            const onInterval = () => {
                setCurrentIndex((currentIndex) => (currentIndex + 1) % (items === null || items === void 0 ? void 0 : items.length));
            };
            interval = setInterval(onInterval, 5000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [items]);
    return (items === null || items === void 0 ? void 0 : items.length) > 0 ? (React.createElement(ProductBenefitsBannerContainer, null, items.map((item, index) => (React.createElement(ProductBenefitsBannerItem, { visible: currentIndex === index },
        item.image && (React.createElement("img", { src: ThumbnailUtils.getImageSrc({
                image: item.image,
                width: 26,
                height: 26,
                entity: '',
            }), alt: '' })),
        item.name))))) : ('');
};
