import React from 'react';
import { Tabs, Tab } from '@@Components/Tabs/Tabs';
import { useLang } from '@@Hooks/useLang';
import { useRouter } from '@@Hooks/useRouter';
import { useSelector } from 'react-redux';
import { cartSelectors } from '@@Redux/selectors/cart.selectors';
import { CheckoutLayoutTabsWrapper } from './styled';
export const CheckoutNavigationTabs = () => {
    var _a;
    const { translate } = useLang();
    const { goTo } = useRouter();
    const cartItems = useSelector(cartSelectors);
    return (React.createElement(CheckoutLayoutTabsWrapper, null,
        React.createElement(Tabs, { defaultOpened: location.pathname.includes('cart') ? 0 : 1, noBorder: true, centered: true, narrow: true, numbered: true, noActiveBigFont: true },
            React.createElement(Tab, { title: translate('checkout.your_cart'), onClick: () => goTo('/checkout/cart') }),
            React.createElement(Tab, { disabled: !(((_a = cartItems) === null || _a === void 0 ? void 0 : _a.length) > 0), title: translate('checkout.enter_data_and_pay'), onClick: () => goTo('/checkout/form') }))));
};
