import styled from 'styled-components';
export const Description = styled.div `
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 15px;
  ul,
  ol {
    margin: unset;
    padding: unset;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }
  a {
    border-bottom: 1px solid #000;
    text-decoration: none;
  }
`;
export const ShowMoreButton = styled.button `
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  column-gap: 10px;
  align-self: flex-start;
  padding: 0;
  margin-bottom: 15px;
`;
