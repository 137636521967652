import styled from 'styled-components';
import Button from '@@Components/Buttons/Button';
/**
 * Full-width page container
 */
export const BrandPageFullWidthContainer = styled.div `
  width: 100%;
  text-align: center;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;
/**
 * Container with maximum width (1135px)
 */
export const BrandPageContainer = styled.div `
  display: inline-block;
  width: 100%;
  max-width: 1135px;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;
/**
 * Container with maximum width (1135px)
 */
export const BrandPageWideContainer = styled.div `
  display: inline-block;
  width: 100%;
  max-width: 1366px;

  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft};`}
  @media (max-width: 1200px) {
    ${(props) => props.mobileMarginLeft && `margin-left: ${props.mobileMarginLeft};`}
  }

  ${(props) => props.theme.mixins.mobile(`
    text-align: left;
  `)}
`;
export const NoBrandPageBlocksPlaceholder = styled.div `
  display: flex;
  justify-content: center;
  margin: 4rem 0;
`;
export const SpinnerWrapper = styled.div `
  margin-top: 40vh;
`;
export const StyledButton = styled(Button) `
  width: 100%;
  max-width: 300px;
  margin-top: 35px;
  background-color: black;
  color: white;

  ${(props) => props.theme.mixins.mobile(`
    max-width: 90%;
    margin-top: 25px;
  `)}
`;
