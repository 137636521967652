import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { useRouter } from '@@Hooks/useRouter';
import { useCategory } from '@@Hooks/useCategory';
import { useLang } from '@@Hooks/useLang';
import { CategoryLogo, CategoryNavigation, CategoryNavigationBarWrapper, CategoryNavigationInnerContainer, CategoryNavigationLink, } from '@@Components/CategoryNavigationBar/styled';
import { ThumbnailUtils } from '@adsel/libstorefront';
import StickyElementWatcher from 'react-sticky-element-watcher';
import { getBrandPathname, isTypeMotorcycle } from '@@Helpers/paths';
import { useSelector } from 'react-redux';
import { mageConfigSelector } from '@@Redux/selectors/config.selectors';
export const CategoryNavigationBar = () => {
    var _a, _b;
    const { location } = useRouter();
    const pathname = getBrandPathname();
    const config = useSelector(mageConfigSelector);
    const { currentCategory } = location.pathname.search('brand') === 1
        ? useCategory(pathname)
        : useCategory();
    const { translate } = useLang();
    const isLinkActive = (linkType) => location.pathname.search(linkType) === 1;
    const pathNameGood = () => location.pathname.search('brand') === 1 ||
        location.pathname.search('marki/') === 1;
    const handleOpen = (link) => window.open(link, '_self');
    const productAttributeBrandCategories = (_a = config === null || config === void 0 ? void 0 : config.libertyLocal) === null || _a === void 0 ? void 0 : _a.productAttributeBrandCategories;
    const attributeOptionsBrandCategories = (_b = config === null || config === void 0 ? void 0 : config.libertyLocal) === null || _b === void 0 ? void 0 : _b.attributeOptionBrandCategories;
    const [nameCategory, setNameCategory] = useState('');
    const getNameCurrentCategory = () => __awaiter(void 0, void 0, void 0, function* () {
        const splitCurrentCategoryPath = yield (currentCategory === null || currentCategory === void 0 ? void 0 : currentCategory.url_path.split('/'));
        setNameCategory(splitCurrentCategoryPath[splitCurrentCategoryPath.length - 1]);
    });
    useEffect(() => {
        if (currentCategory)
            getNameCurrentCategory();
    }, [currentCategory]);
    return (React.createElement(React.Fragment, null, pathNameGood() && currentCategory && (React.createElement(StickyElementWatcher, { render: (isStuck) => (React.createElement(CategoryNavigationBarWrapper, { isStuck: isStuck },
            React.createElement(CategoryNavigationInnerContainer, null,
                React.createElement(CategoryLogo, { background: ThumbnailUtils.getImageSrc({
                        image: currentCategory.brand_logo || 'default.jpg',
                        width: 200,
                        height: 100,
                        entity: '',
                    }) }),
                React.createElement(CategoryNavigation, null,
                    React.createElement(CategoryNavigationLink, { active: !isTypeMotorcycle(productAttributeBrandCategories, attributeOptionsBrandCategories) && !isLinkActive('brand') },
                        React.createElement("span", { onClick: () => handleOpen(`/marki/${nameCategory}`) }, translate('nav.category_products'))),
                    React.createElement(CategoryNavigationLink, { active: isTypeMotorcycle(productAttributeBrandCategories, attributeOptionsBrandCategories) },
                        React.createElement("span", { onClick: () => handleOpen(`/marki/${nameCategory}?${productAttributeBrandCategories}=${attributeOptionsBrandCategories}`) }, translate('nav.category_motors'))),
                    React.createElement(CategoryNavigationLink, { active: isLinkActive('brand') },
                        React.createElement("span", { onClick: () => handleOpen(`/brand/${nameCategory}`) }, translate('nav.category_details'))))))) }))));
};
