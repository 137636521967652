import React from 'react';
import { useLang } from '@@Hooks/useLang';
import { ImageContainer, StyledButton, StyledLink } from './styled';
import { ResponsiveMatcher } from '@adsel/components/src/ResponsiveMatcher';
const Status404Page = () => {
    const { translate } = useLang();
    const button = (React.createElement(StyledLink, { to: "/", title: "error status link" },
        React.createElement(StyledButton, { appearance: "dark" }, translate('placeholders.404_button'))));
    return (React.createElement(ResponsiveMatcher, { mobile: React.createElement(ImageContainer, { url: "assets/image/status/404_mobile.jpg" }, button), desktop: React.createElement(ImageContainer, { url: "assets/image/status/404.jpg" }, button) }));
};
export default Status404Page;
