import styled from 'styled-components';
export const StyledContainer = styled.div ``;
export const StyledContentEmpty = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  max-width: 446px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 20px;
  }
  button {
    margin-bottom: 10px;
  }
`;
export const StyledCountItems = styled.div `
  margin-left: 5px;
  font-weight: 400;
`;
export const StyledProduct = styled.div `
  width: 100%;
`;
export const StyledDeleted = styled.div `
  position: absolute;
  right: 0;
  display: inline;
  text-align: right;
  top: 0;
  cursor: pointer;
`;
export const StyledAttributes = styled.div `
  display: flex;
`;
export const StyledAttr = styled.div `
  margin-right: 15px;
  margin-bottom: 15px;
`;
export const StyledID = styled.div `
  font-family: 'Montserrat', serif;
  font-size: 14px;
  line-height: 22px;
  color: black;
`;
export const StyledTitleProduct = styled.div `
  font-family: 'Montserrat', serif;
  font-size: 14px;
  line-height: 19px;
  color: black;
  font-weight: 700;
  min-height: 39px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StyledContent = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;
export const StyledElement = styled.div `
  position: relative;
  display: flex;
  margin-bottom: 12px;
  img {
    height: 140px;
    width: 140px;
    object-fit: contain;
    border: 1px solid #707070;
    margin-right: 18px;
  }
  ${(props) => props.last
    ? `
        border-bottom: none;
    `
    : `
        padding-bottom: 4px;
        border-bottom: 1px solid #BABABA;
    `}
`;
export const StyledHeader = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #525252;
  margin-bottom: 15px;
`;
export const StyledTitle = styled.div `
  font-family: 'Montserrat', serif;
  font-size: 20px;
  line-height: 24px;
  color: black;
  font-weight: 700;
  margin-bottom: 15px;
  display: flex;
  align-items: baseline;
`;
export const StyledClear = styled.div `
  font-family: 'Montserrat', serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.28;
  color: black;
  font-weight: 600;
  margin-bottom: 15px;
  cursor: pointer;
`;
