import styled from 'styled-components';
export const BannerContainer = styled.div `
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 450px;
  text-align: left;
  padding: 93px 93px 93px 93px;
  color: #000;
  ${(props) => props.theme.mixins.mobile(`
        padding: 66px 15px;
    `)}
`;
export const BannerContent = styled.div `
  color: ${(props) => props.textColor};
  max-width: 1135px;
  margin: 0 auto;
`;
export const BannerTitle = styled.h1 `
  letter-spacing: 1.02px;
  font-weight: bold;
  margin-bottom: 2rem;
  max-width: 400px;
  white-space: pre-wrap;
`;
export const BannerDescription = styled.h5 `
  font-weight: 600;
  margin-bottom: 3rem;
  max-width: 600px;
  white-space: pre-wrap;
  ${(props) => props.theme.mixins.mobile(`
        font-size: 14px;
        line-height: 23px;
    `)}
`;
