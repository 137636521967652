import styled from 'styled-components';
import { Button } from '@@Components/Buttons/Button';
export const RegisterFormWrapper = styled.div `
  width: 440px;
  margin: 0 auto;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const UserConsentsFormWrapper = styled.div `
  margin-top: 2rem;
  div {
    text-align: left;
  }
`;
export const RegisterButton = styled(Button) `
  color: #fff;
  background-color: #000;
  margin-bottom: 25px;
`;
export const GoogleLogInButton = styled(Button) `
  letter-spacing: 0.28px;
  font-weight: normal;
  margin-bottom: 15px;
`;
