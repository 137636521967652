import styled from 'styled-components';
export const NoResultsContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${(props) => props.marginTop}px;
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}px`};
  max-width: 1020px;
`;
export const InnerContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
export const SvgWrapper = styled.div `
  background-image: url('${(props) => props.background}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 146px;
  height: 160px;
  margin-bottom: 50px;
`;
export const Title = styled.h2 `
  font-size: 28px;
  max-width: 700px;
  text-align: center;
  ${(props) => props.theme.mixins.mobile('font-size: 20px;')}
`;
export const Subtitle = styled.h2 `
  font-size: 28px;
  margin-bottom: 35px;
  max-width: 700px;
  text-align: center;
  ${(props) => props.theme.mixins.mobile('font-size: 20px;')}
`;
export const BottomDescription = styled.p `
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  text-align: center;
  ${(props) => props.theme.mixins.mobile(`
    font-size: 14px; 
    line-height: 18px;
  `)}
`;
export const ButtonWrapper = styled.div `
  margin-top: 30px;
  min-width: 250px;
  @media (max-width: 550px) {
    width: 90%;
  }
`;
