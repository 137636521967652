import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLang } from '@@Hooks/useLang';
import { Checkbox } from '@@Components/Input/Checkbox';
import FormRowsWrapper from '@@Components/FormRowsWrapper';
import { AgreementDescription } from '@@Components/AgreementDescription';
import { marketingConsentsSelector } from '@@Redux/selectors/config.selectors';
import { useControlledForm } from '@@Hooks/useControlledForm';
import { userProfileSelector } from '@@Redux/selectors/user.selectors';
import { MarketingConsentSectionsWrapper } from './styles';
export const UserConsentsForm = React.forwardRef(({ onChange = () => { }, withNewsletter }, ref) => {
    const marketingConsents = useSelector(marketingConsentsSelector);
    const { translate } = useLang();
    const { register, errors, setValue } = useControlledForm({ mode: 'onChange', onChange }, ref);
    const userProfile = useSelector(userProfileSelector);
    useEffect(() => {
        var _a, _b;
        if (userProfile) {
            (_a = userProfile.custom_attributes) === null || _a === void 0 ? void 0 : _a.map((attribute) => {
                setValue(attribute.attribute_code, attribute.value === '1');
            });
            if ((_b = userProfile.extension_attributes) === null || _b === void 0 ? void 0 : _b.is_subscribed) {
                setValue('newsletter', true);
            }
        }
    }, [userProfile]);
    return (React.createElement("form", { noValidate: true, autoComplete: "off" },
        React.createElement(MarketingConsentSectionsWrapper, { "data-cy": "consents" },
            React.createElement("section", null,
                React.createElement(FormRowsWrapper, null,
                    marketingConsents &&
                        Object.keys(marketingConsents).map((key, index) => {
                            const { description, required } = marketingConsents[key];
                            const isRequired = required && required === '1'
                                ? true
                                : required === '0'
                                    ? false
                                    : true;
                            return (React.createElement("div", { className: "form-row", key: index },
                                React.createElement(Checkbox, { required: isRequired, error: errors[key], name: key, ref: register(Object.assign({}, (isRequired && {
                                        required: translate('validation.required'),
                                    }))) },
                                    React.createElement(AgreementDescription, { expandable: (description === null || description === void 0 ? void 0 : description.length) > 160 },
                                        React.createElement(React.Fragment, null,
                                            isRequired ? '*' : '',
                                            React.createElement("div", { dangerouslySetInnerHTML: { __html: description } }))))));
                        }),
                    withNewsletter && (React.createElement("div", { className: "form-row" },
                        React.createElement(Checkbox, { name: "newsletter", ref: register({}) }, translate('checkout.newsletter_agreement')))))))));
});
