import styled from 'styled-components';
import CommonButton from '@@Components/Buttons/Button';
export const CorporateLinksWrapper = styled.div `
  display: flex;
  width: 100%;
  ${(props) => props.theme.mixins.mobile(`
    flex-flow: row wrap;
  `)}
`;
export const BannerContainer = styled.div `
  position: relative;
  background-image: url(${(props) => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: left;
  color: ${(props) => props.color};
  padding: 2.5rem;
  flex-grow: 2;
  min-height: 448px;
  max-width: 33%;
  margin: 5px 0;
  &:nth-child(even) {
    margin-right: 5px;
    margin-left: 5px;
  }
  ${(props) => props.theme.mixins.mobile(`
    max-width: 100%;
    width: 100%;
    margin: 5px 0;
    &:nth-child(even) {
      margin: 0;
    }
  `)}
`;
export const BannerSubtitle = styled.h5 `
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
`;
export const BannerTitle = styled.h3 `
  font-weight: bold;
  margin-bottom: 30px;
`;
export const BannerContent = styled.div `
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.24px;
  font-weight: 400;
  margin-bottom: 80px;
  li {
    margin-bottom: 20px;
    list-style-type: none;
    &:hover {
      font-weight: 800;
    }
  }
  b {
    font-weight: 800;
  }
`;
export const ButtonContainer = styled.div `
  position: absolute;
  width: calc(100% - 36px);
  bottom: 45px;
  left: 36px;
`;
export const StyledButton = styled(CommonButton) `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 250px;
  height: 42px;
  font-weight: 600;
  font-style: italic;
  ${(props) => props.theme.mixins.mobile(`
    height: 34px; 
    font-size: 13px;
  `)};
`;
