import styled from 'styled-components';
export const ProductAvailabilityContainer = styled.div `
  display: flex;
  align-items: center;
  column-gap: 5px;
`;
export const ProductAvailabilityText = styled.p `
  font-weight: bold;
  margin: 0;
`;
export const ProductAvailabilityIndicator = styled.div `
  width: 11px;
  height: 11px;
  background-color: #d9000d;
  border-radius: 100%;
  ${(props) => props.isAvailable && 'background-color: #1EDE60'};
`;
