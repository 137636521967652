import { __awaiter } from "tslib";
import { Select } from '@@Components/Select/Select';
import React, { useEffect, useState } from 'react';
import { StoreLocatorService, } from '@@StorelocatorPlugin';
import LSF from '@@Storefront/index';
import styled from 'styled-components';
import { translate } from '@@Helpers/translateGlobal';
const LocationsSelectWrapper = styled.div `
  margin-top: 15px;
`;
export const ShippingStoreLocator = ({ errMsg, onLocationSelect }) => {
    var _a;
    const [options, setOptions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(); // todo not needed
    const mapLocationsToSelectOptions = (locations) => {
        return locations.map((loc) => ({
            label: `${loc.city} ${loc.street}`,
            value: loc.location_id.toString(),
        }));
    };
    const mapSelectOptionToLocation = (value) => {
        return locations.find((location) => !Number.isNaN(Number(value)) &&
            Number(location.location_id) === Number(value));
    };
    const onSelectChange = (value) => {
        const loc = mapSelectOptionToLocation(value);
        if (loc) {
            setSelectedLocation(loc);
        }
    };
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const locations = (yield LSF.get(StoreLocatorService).getLocations());
            setLocations(locations);
        }))();
    }, []);
    useEffect(() => {
        setOptions(mapLocationsToSelectOptions(locations));
    }, [locations]);
    useEffect(() => {
        if (selectedLocation && onLocationSelect) {
            onLocationSelect(selectedLocation);
        }
    }, [selectedLocation]);
    return (React.createElement(LocationsSelectWrapper, null, (options === null || options === void 0 ? void 0 : options.length) > 0 ? (React.createElement(Select, { label: `*${translate('checkout.select_delivery_location')}`, placeholder: translate('checkout.delivery_location'), required: true, multiple: false, options: options, value: ((_a = selectedLocation === null || selectedLocation === void 0 ? void 0 : selectedLocation.location_id) === null || _a === void 0 ? void 0 : _a.toString()) || '', error: errMsg ? { message: errMsg } : null, onChange: (option) => { var _a; return onSelectChange((_a = option) === null || _a === void 0 ? void 0 : _a.value); } })) : (React.createElement(React.Fragment, null))));
};
