import styled from 'styled-components';
export const SummaryHeader = styled.h2 `
  margin-bottom: 25px;
  font-size: 20px;
`;
export const CheckoutPageSummaryContainer = styled.div `
  width: 100%;
`;
export const CheckoutPageSummaryWrapper = styled.div `
  position: sticky;
  top: calc(113px + 0.5rem);

  min-width: 400px;

  padding: 30px 25px;
  background-color: #ffffff;
  border: 1px solid #525252;

  ${(props) => props.theme.mixins.mobile(`
    min-width: 100%;
    width: 100%;
    padding: 2rem 0.9rem;
  `)}
`;
export const CheckoutUserConsentsWrapper = styled.div `
  margin-top: 10px;
  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
`;
export const CheckoutPriceEntryRow = styled.div `
  display: flex;
  flex-flow: row nowrap;
  font-size: 14px;
  padding: 5px 0;

  &:first-of-type {
    padding-top: 0;
  }

  & > div:first-of-type {
    flex-grow: 2;
    line-height: 1.57;
  }
  & > div:last-of-type {
    white-space: nowrap;
    text-align: right;
    margin-left: 1rem;
    font-weight: 600;
    line-height: 1.57;

    &.bigPrice {
      color: ${(props) => props.theme.colors.secondary};
      font-size: 28px;
      line-height: 1;
      font-weight: bold;
    }
  }
`;
export const CheckoutPriceContainer = styled.div `
  display: flex;
  flex-flow: column;
`;
export const CheckoutSummaryDivider = styled.div `
  border-bottom: 1px solid ${(props) => props.theme.colors.borderLight};
  margin: 13px 0 5px;
`;
export const CheckoutCouponsWrapper = styled.div `
  margin-top: 20px;
`;
export const CheckoutSummaryNextStepWrapper = styled.div `
  margin-top: 20px;

  @media screen and (max-width: 1024px) {
    button {
      width: 100% !important;
      max-width: none !important;
    }
  }
`;
export const AcceptedPaymentWrapper = styled.div `
  margin-top: 8rem;

  ${(props) => props.theme.mixins.mobile(`
    margin-top: 4rem;
  `)}
`;
export const ImojeIconsWrapper = styled.div `
  margin-bottom: -15px;
  margin-top: 15px;

  img {
    width: 100%;
    height: auto;
  }
`;
