import styled from 'styled-components';
export const ProductBenefitsBannerContainer = styled.div `
  position: relative;
  width: 100%;
  height: 46px;
  background: #fafafa;
  margin: 15px 0;
`;
export const ProductBenefitsBannerItem = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  transition: opacity 0.4s ease;
  color: #000000;
  line-height: 46px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 10px;
    margin-left: -5px;
  }
  ${(props) => (props.visible ? 'opacity: 1' : 'opacity: 0')};
`;
