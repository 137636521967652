import { __awaiter } from "tslib";
import React from 'react';
import { useLang } from '@@Hooks/useLang';
import { Checkbox } from '@@Components/Input/Checkbox';
import { userProfileSelector } from '@@Redux/selectors/user.selectors';
import { marketingConsentsSelector } from '@@Redux/selectors/config.selectors';
import { useSelector } from 'react-redux';
import { notify } from '@@Helpers/notifications';
import { NotificationType } from '@adsel/libstorefront';
import { updateUserProfile } from '@@Storefront/actions/user/updateUserProfile';
import { EditUserConsentsFormContainer, Title, CheckboxWrapper, } from './styled';
export const EditUserConsentsForm = ({ title }) => {
    const { translate } = useLang();
    const userProfile = useSelector(userProfileSelector);
    const consents = useSelector(marketingConsentsSelector);
    const consentsHandle = (value, key) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const newCustomAttributes = (_a = userProfile === null || userProfile === void 0 ? void 0 : userProfile.custom_attributes) === null || _a === void 0 ? void 0 : _a.map((attr) => {
            return attr.attribute_code === key
                ? Object.assign(Object.assign({}, attr), { value: value ? '1' : '0' }) : Object.assign({}, attr);
        });
        const newUserData = Object.assign(Object.assign({}, userProfile), { custom_attributes: newCustomAttributes });
        try {
            yield updateUserProfile(newUserData);
            notify(translate('messages.consent_change_success'), NotificationType.SUCCESS);
        }
        catch (error) {
            notify(translate('messages.consent_change_error'), NotificationType.WARN);
        }
    });
    return (React.createElement(React.Fragment, null, userProfile && Object.keys(userProfile).length > 0 && consents && (React.createElement(EditUserConsentsFormContainer, null,
        React.createElement(Title, null, title || translate('headers.consents_to_terms_and_privacy_policy')),
        React.createElement(CheckboxWrapper, null, Object.keys(consents).map((key, i) => { var _a; return (_a = userProfile.custom_attributes) === null || _a === void 0 ? void 0 : _a.map((attribute) => (attribute === null || attribute === void 0 ? void 0 : attribute.attribute_code) === key && (React.createElement(Checkbox, { key: i, checked: !!Number(attribute === null || attribute === void 0 ? void 0 : attribute.value), onChange: (value) => consentsHandle(value, key), name: key, disabled: consents[key].required === '1' },
            React.createElement("div", { dangerouslySetInnerHTML: {
                    __html: `${consents[key].required === '1' ? '*' : ''}${consents[key].description}`,
                } })))); }))))));
};
