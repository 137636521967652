import styled, { css } from 'styled-components';
export const LowestPriceWatermark = styled.div `
  ${({ variant }) => {
    switch (variant) {
        case 'card':
            return css `
          position: absolute;
          top: 10px;
          right: 10px;
          width: 120px;
          z-index: 11;
          @media (max-width: 1000px) {
            width: 80px;
          }
        `;
        case 'tile':
            return css `
          position: absolute;
          top: 5px;
          right: 5px;
          width: 80px;
        `;
    }
}}
`;
export const WatermarkImage = styled.img `
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
