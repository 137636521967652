import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { ThumbnailUtils, ProductUtils, PriceUtils, } from '@adsel/libstorefront';
import { useLang } from '@@Hooks/useLang';
import { getProductLink } from '@@Storefront/utils/get-product-link';
import { Link } from 'react-router-dom';
import { useAttribute } from '@@Hooks/useAttribute';
import { getFormattedProductPrice } from '@@Storefront/utils/get-formatted-product-price';
import { Icon } from '@@Components/Icon/Icon';
import { dispatch } from '@@Redux/dispatch';
import { addProductToCart } from '@@Redux/thunks/cart.thunks';
import { categoriesMap } from '@@Redux/selectors/category';
import { useSelector } from 'react-redux';
import { ProductBadges } from '@@Components/ProductBadges';
import { InformationContainer, ProductLongTileContainer, Title, Text, MainPrice, StyledButton, PricesAndButtonsContainer, SpecificationWrapper, SpecificationColumn, TextWrapper, PricesWrapper, MainPriceLine, ButtonsWrapper, ProductAvailabilityWrapper, ProductAvailabilityIndicator, OriginalPriceLabel, PricesWithTax, ProductImage, } from '@@Components/ProductLongTile/styled';
export const ProductLongTile = ({ product, onAskAboutAvailabilityClick, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
    const categories = useSelector(categoriesMap);
    const { translate } = useLang();
    const { getAttributeValue } = useAttribute();
    const [price, setPrice] = useState();
    const [brand, setBrand] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState(null);
    const [isAvailable, setIsAvailable] = useState(false);
    const getProductImage = (product) => {
        return ThumbnailUtils.getImageSrc({
            image: product === null || product === void 0 ? void 0 : product.image,
            width: 320,
            height: 240,
            entity: '',
        });
    };
    const addToCart = (product) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        yield dispatch(addProductToCart(product));
        setIsLoading(false);
    });
    const isProductUnavailable = (product) => {
        var _a;
        return product.qty < 1 || !((_a = product.stock) === null || _a === void 0 ? void 0 : _a.is_in_stock);
    };
    const assignDeepestCategory = (product) => {
        var _a;
        const categoryIds = (_a = product.root_category_path) === null || _a === void 0 ? void 0 : _a.split('/');
        if (!categoryIds || (categoryIds === null || categoryIds === void 0 ? void 0 : categoryIds.length) < 1)
            return;
        const categoryId = Number(categoryIds[categoryIds.length - 1]);
        const deepestCategory = categories[categoryId];
        if (!deepestCategory)
            return;
        setCategory(deepestCategory);
    };
    useEffect(() => {
        if (product) {
            const brandData = getAttributeValue('manufacturer', product.manufacturer);
            setBrand(brandData);
            assignDeepestCategory(product);
            setPrice(getFormattedProductPrice(product));
            setIsAvailable(!isProductUnavailable(product));
        }
    }, [product]);
    const isDiscount = !!(((_b = (_a = product === null || product === void 0 ? void 0 : product.prices) === null || _a === void 0 ? void 0 : _a.final_price) === null || _b === void 0 ? void 0 : _b.gross) && ((_d = (_c = product === null || product === void 0 ? void 0 : product.prices) === null || _c === void 0 ? void 0 : _c.final_price) === null || _d === void 0 ? void 0 : _d.net) &&
        ((_f = (_e = product === null || product === void 0 ? void 0 : product.prices) === null || _e === void 0 ? void 0 : _e.final_price) === null || _f === void 0 ? void 0 : _f.gross) < ((_h = (_g = product === null || product === void 0 ? void 0 : product.prices) === null || _g === void 0 ? void 0 : _g.price) === null || _h === void 0 ? void 0 : _h.gross));
    const askAboutAvailability = () => {
        if (onAskAboutAvailabilityClick) {
            onAskAboutAvailabilityClick(product);
        }
    };
    const askAboutAvailabilityBtn = (React.createElement(StyledButton, { "aria-label": "Ask for availability", onClick: askAboutAvailability }, translate('headings.ask_about_availability')));
    const withStockButtons = ProductUtils.isConfigurableProduct(product) ? (React.createElement(Link, { to: getProductLink(product, '') },
        React.createElement(StyledButton, null, translate('actions.checkout_variants')))) : (React.createElement(ButtonsWrapper, null,
        React.createElement(Link, { to: getProductLink(product, '') },
            React.createElement(StyledButton, null, translate('common.check_out'))),
        React.createElement(StyledButton, { loading: isLoading, disabled: isLoading || !isAvailable, onClick: () => addToCart(product) },
            React.createElement(Icon, { icon: "cart", width: 30, height: 24 }))));
    const buttonsSection = ((_j = product === null || product === void 0 ? void 0 : product.stock) === null || _j === void 0 ? void 0 : _j.is_in_stock) ? withStockButtons
        : askAboutAvailabilityBtn;
    return (React.createElement(React.Fragment, null, product && (React.createElement(ProductLongTileContainer, null,
        React.createElement(ProductBadges, { product: product, margin: '-10px 0 0 -10px', maxWidth: 170 }),
        React.createElement(Link, { to: getProductLink(product, ''), "data-cy": "product image longTile", title: product.name },
            React.createElement(ProductImage, { src: getProductImage(product), height: "120", width: "160", alt: product.image_label || product.name })),
        React.createElement(InformationContainer, null,
            React.createElement(Link, { to: getProductLink(product, ''), title: product.name },
                React.createElement(Title, null, product.name)),
            React.createElement(SpecificationWrapper, null,
                React.createElement(SpecificationColumn, null,
                    brand && (React.createElement(TextWrapper, null,
                        React.createElement(Text, null, `${translate('products.manufacturer')}:`),
                        React.createElement(Text, { fontWeight: "bold", ellipsis: true }, brand === null || brand === void 0 ? void 0 : brand.label))),
                    (category === null || category === void 0 ? void 0 : category.name) && (React.createElement(TextWrapper, null,
                        React.createElement(Text, null, `${translate('products.category')}:`),
                        React.createElement(Text, { fontWeight: "bold", ellipsis: true }, category.name)))),
                React.createElement(SpecificationColumn, null,
                    product.sku && (React.createElement(TextWrapper, null,
                        React.createElement(Text, null, `${translate('products.product_code')}:`),
                        React.createElement(Text, { fontWeight: "bold", ellipsis: true }, product.sku))),
                    !ProductUtils.isConfigurableProduct(product) && (React.createElement(ProductAvailabilityWrapper, null,
                        React.createElement(ProductAvailabilityIndicator, { isAvailable: isAvailable }),
                        React.createElement(Text, { fontWeight: 600 }, isAvailable
                            ? translate('products.product_available')
                            : translate('products.product_unavailable'))))))),
        React.createElement(PricesAndButtonsContainer, null,
            React.createElement(PricesWrapper, null,
                React.createElement(PricesWithTax, null,
                    ((_l = (_k = product === null || product === void 0 ? void 0 : product.prices) === null || _k === void 0 ? void 0 : _k.final_price) === null || _l === void 0 ? void 0 : _l.gross) && ((_o = (_m = product === null || product === void 0 ? void 0 : product.prices) === null || _m === void 0 ? void 0 : _m.final_price) === null || _o === void 0 ? void 0 : _o.net) && (React.createElement(MainPriceLine, null,
                        React.createElement(MainPrice, null, (_p = PriceUtils.getFormattedPrice(product.prices.final_price.net, product.prices.final_price.gross)) === null || _p === void 0 ? void 0 : _p.price_incl_tax))),
                    isDiscount && (React.createElement(OriginalPriceLabel, null, (_q = PriceUtils.getFormattedPrice(product.prices.price.net, product.prices.price.gross)) === null || _q === void 0 ? void 0 : _q.price_incl_tax))),
                ((_s = (_r = product === null || product === void 0 ? void 0 : product.prices) === null || _r === void 0 ? void 0 : _r.final_price) === null || _s === void 0 ? void 0 : _s.gross) && ((_u = (_t = product === null || product === void 0 ? void 0 : product.prices) === null || _t === void 0 ? void 0 : _t.final_price) === null || _u === void 0 ? void 0 : _u.net) && (React.createElement(Text, { greyColor: true, ellipsis: true }, `${(_v = PriceUtils.getFormattedPrice(product.prices.final_price.net, product.prices.final_price.gross)) === null || _v === void 0 ? void 0 : _v.price} ${translate('checkout.net')}`))),
            buttonsSection)))));
};
