import React, { useState } from 'react';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { useLang } from '@@Hooks/useLang';
import GTMEvents from '@@Common/gtm';
import { Tabs, Tab } from '@@Components/Tabs/Tabs';
import { usePrevious } from '@@Hooks/usePrev';
import { Overview } from '../Overview';
import { ProductSpecification } from '../ProductSpecification';
import { ChartSizeContent } from '../styled';
import { TabChildContainer, TabsWrapper } from './styled';
export const ProductDetailsTabs = React.forwardRef(({ product }, ref) => {
    var _a, _b;
    const { translate } = useLang();
    const [activeTabTitle, setActiveTabTitle] = useState();
    usePrevious(activeTabTitle, () => activeTabTitle, [activeTabTitle], (prev, next) => {
        // prev must be defined. We do not want to fire the event on the first change
        // (from undefined to something)
        if (prev && next) {
            GTMEvents.push({
                event: 'productDetailTabClick',
                productName: product.name,
                tabName: activeTabTitle,
            });
        }
    });
    const onTabChange = (tabTitle) => {
        setActiveTabTitle(tabTitle);
    };
    const tabsRef = React.useRef(null); // assign null makes it compatible with elements.
    const elementRef = React.useRef(null);
    const openSizeChartTab = () => {
        if (tabsRef === null || tabsRef === void 0 ? void 0 : tabsRef.current) {
            tabsRef.current.setActiveTab(2);
            if (elementRef === null || elementRef === void 0 ? void 0 : elementRef.current) {
                scrollIntoView(elementRef.current, {
                    scrollMode: 'if-needed',
                    block: 'start',
                    behavior: 'smooth',
                });
            }
        }
    };
    if (ref) {
        React.useImperativeHandle(ref, () => ({
            openSizeChart: () => openSizeChartTab(),
            elementRef,
        }), []);
    }
    return (React.createElement(TabsWrapper, { ref: elementRef },
        React.createElement(Tabs, { ref: tabsRef, defaultOpened: 0, onActiveTabChange: (activeTabId, activeTabTitle) => onTabChange(activeTabTitle) },
            React.createElement(Tab, { title: translate('common.description') },
                React.createElement(TabChildContainer, null, (product === null || product === void 0 ? void 0 : product.description) && React.createElement(Overview, { product: product }))),
            React.createElement(Tab, { title: translate('products.additional_information') },
                React.createElement(TabChildContainer, null,
                    React.createElement(ProductSpecification, { product: product }))),
            ((_a = product === null || product === void 0 ? void 0 : product.extension_attributes) === null || _a === void 0 ? void 0 : _a.size_chart) && (React.createElement(Tab, { title: translate('actions.size_chart') },
                React.createElement(TabChildContainer, null,
                    React.createElement(ChartSizeContent, { className: "sizeContent", dangerouslySetInnerHTML: {
                            __html: (_b = product.extension_attributes) === null || _b === void 0 ? void 0 : _b.size_chart,
                        } })))))));
});
