import styled from 'styled-components';
export const UserAddressesTableWrapper = styled.div `
  margin-top: 40px;
`;
export const AccountPageEditContainer = styled.div `
  width: 100%;
`;
export const EditColumnsWrapper = styled.div `
  display: flex;
  column-gap: 140px;
  width: 100%;
  @media screen and (max-width: 1330px) {
    column-gap: 80px;
  }
  @media screen and (max-width: 1260px) {
    column-gap: 50px;
  }
  @media screen and (max-width: 1160px) {
    column-gap: 30px;
  }
  @media screen and (max-width: 1120px) {
    column-gap: 60px;
  }
  ${(props) => props.theme.mixins.mobile(`
    flex-direction: column;
    grid-gap: 35px;
  `)}
`;
export const EditColumn = styled.div `
  display: flex;
  flex-direction: column;
  width: 440px;
  ${(props) => props.theme.mixins.mobile(`
    min-width: 0px;
    width: 100%;
 `)}
  ${(props) => props.rowGap && `row-gap: ${props.rowGap}px;`}
`;
export const Title = styled.p `
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 25px;
`;
