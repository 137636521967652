import styled from 'styled-components';
export const LoginPageWrapper = styled.div `
  display: flex;
  width: 100%;
  max-height: 95vh;
  ${(props) => props.theme.mixins.mobile(`
    flex-direction: column;
    max-height: unset;
  `)};
`;
export const LoginPageImageWrapper = styled.div `
  flex: 1;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${(props) => props.theme.mixins.mobile(`
    height: auto;
  `)};
`;
export const LoginPageSection = styled.div `
  text-align: center;
  flex: 1;
  font-size: 14px;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
`;
export const RegisterLink = styled.button `
  color: #0f0f0f;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  text-decoration: underline;
`;
export const PageTitle = styled.h1 `
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 39px;
  font-size: 24px;
  font-weight: 900;
  line-height: 36px;
  span {
    max-width: 300px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
`;
export const LoginPageContentContainer = styled.div `
  min-height: 95vh;
  width: 100%;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
  ${(props) => props.theme.mixins.mobile(`
    margin-bottom: 20px;
    min-height: unset;
  `)};
`;
export const StyledParagraph = styled.p `
  @media (max-width: 1024px) {
    padding: 0 26px;
  }
`;
