import React from 'react';
import { validateEmail } from '@@Helpers/validators';
import { useLang } from '@@Hooks/useLang';
import Input from '@@Components/Input/Input';
import Button from '@@Components/Buttons/Button';
import { useForm } from 'react-hook-form';
import { StyledForm, FormInputWrapper, FormButtonWrapper } from './styled';
export const EmailAlertForm = ({ loading, onSubmit, }) => {
    const { translate } = useLang();
    const { handleSubmit, register, errors } = useForm({ mode: 'all' });
    return (React.createElement(StyledForm, { onSubmit: handleSubmit(onSubmit), autoComplete: "off", noValidate: true },
        React.createElement(FormInputWrapper, null,
            React.createElement(Input, { type: "text", name: "email", placeholder: translate('placeholders.insert_email_address'), ref: register({ validate: validateEmail }), error: errors['email'] })),
        React.createElement(FormButtonWrapper, null,
            React.createElement(Button, { loading: loading }, translate('actions.make_an_inquiry')))));
};
