import { __awaiter } from "tslib";
import { useLang } from '@@Hooks/useLang';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Alert } from '@@Components/Alert';
import { dispatch } from '@@Redux/dispatch';
import Input from '@@Components/Input/Input';
import { useRouter } from '@@Hooks/useRouter';
import React, { useEffect, useState } from 'react';
import { login } from '@@Redux/thunks/user.thunks';
import FormRowsWrapper from '@@Components/FormRowsWrapper';
import { ResetPasswordModal } from '@@Components/ResetPasswordModal';
import { userIsLoginInProgress } from '@@Redux/selectors/user.selectors';
import SocialLoginButtons from '@@Components/SocialLoginButtons';
import LSF from '@@Storefront';
import { Button } from '@@Components/Buttons/Button';
import { validateEmail, validatePassword } from '@@Helpers/validators';
import GTMEvents from '@@Common/gtm';
import { LoginFormWrapper, RemindPasswordLink, LoginButtonContainer, RemindPasswordButtonContainer, } from './styles';
export const LoginForm = (props) => {
    const isLoginInProgress = useSelector(userIsLoginInProgress);
    const { register, errors, handleSubmit, setError, clearErrors } = useForm({
        mode: 'onTouched',
    });
    const { translate } = useLang();
    const { search } = useRouter();
    const [genericAuthError, setGenericAuthError] = useState('');
    const [isPasswordRecoveryOpened, setIsPasswordRecoveryOpened] = useState(false);
    const handleLoginSubmit = ({ email, password }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield dispatch(login({ email, password }));
            try {
                GTMEvents.userLogged();
            }
            catch (error) { }
            try {
                yield LSF.CartService.recalculate({
                    quickSync: true,
                    forceServerSync: true,
                });
                yield LSF.CartService.getShippingMethods();
            }
            catch (error) {
                console.log(error);
            }
        }
        catch (error) {
            setGenericAuthError((error === null || error === void 0 ? void 0 : error.message) || (error === null || error === void 0 ? void 0 : error.result) || JSON.stringify(error));
        }
    });
    const handleRemindPasswordLinkClick = () => {
        if (props.onRemindPasswordClick) {
            props.onRemindPasswordClick();
        }
        else {
            setIsPasswordRecoveryOpened(true);
        }
    };
    useEffect(() => {
        clearErrors();
    }, [isPasswordRecoveryOpened]);
    useEffect(() => {
        if (search && search.reset_password) {
            setIsPasswordRecoveryOpened(true);
        }
    }, []);
    return (React.createElement(LoginFormWrapper, null,
        React.createElement("form", { onSubmit: handleSubmit(handleLoginSubmit), noValidate: true, autoComplete: "nope", "data-cy": "form login" },
            genericAuthError && React.createElement(Alert, { type: "error" }, genericAuthError),
            React.createElement(FormRowsWrapper, null,
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { name: "email", ref: register({ required: true, validate: validateEmail }), placeholder: translate('customer.getEmailAddress'), label: translate('customer.getEmailAddress'), error: errors.email })),
                React.createElement("div", { className: "form-row" },
                    React.createElement(Input, { type: "password", name: "password", ref: register({
                            required: true,
                            minLength: 6,
                            validate: validatePassword,
                        }), placeholder: translate('customer.getPassword'), label: translate('customer.getPassword'), error: errors.password }))),
            React.createElement(LoginButtonContainer, null,
                React.createElement(Button, { appearance: "primary", disabled: isLoginInProgress, loading: isLoginInProgress }, translate('actions.log_in')))),
        React.createElement(RemindPasswordButtonContainer, null,
            React.createElement(RemindPasswordLink, { onClick: () => handleRemindPasswordLinkClick() }, translate('actions.i_dont_remember_password'))),
        React.createElement(SocialLoginButtons, { title: translate('account.log_in_with_social_account'), socials: ['facebook', 'google'], googleToken: props.googleToken }),
        React.createElement(ResetPasswordModal, { isOpened: isPasswordRecoveryOpened, onClose: () => setIsPasswordRecoveryOpened(false) })));
};
export default LoginForm;
