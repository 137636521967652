import React, { useEffect, useState } from 'react';
import { Dropdown } from '@@Pages/CategoryPage/Dropdown';
import { Pagination } from '@@Pages/CategoryPage/Pagination';
import { Icon } from '@@Components/Icon/Icon';
import { Translate, TranslatePlural } from '@@Client/lang';
import { useLang } from '@@Hooks/useLang';
import { productSelector } from '@@Redux/selectors/product.selectors';
import { useSelector } from 'react-redux';
import { Layout } from '@@Types';
import { CategoryResultsManageBarWrapper, PaginationWrapper, Text, TextPadding, TextMobile, ProductsInfoWrapper, DropdownContainer, DropdownWrapper, IconWrapper, ViewIconsWrapper, } from './styled';
export const CategoryResultsManageBar = ({ onSortAndFilterClick, sortValue, sortOptions, onSortChange, currentPage, totalPages, onPageChange, layout, onLayoutChange, }) => {
    const [layoutOption, setLayoutOption] = useState(Layout.grid);
    const { translate } = useLang();
    const productRedux = useSelector(productSelector);
    const handleIconClick = (option) => {
        onLayoutChange && onLayoutChange(option);
    };
    useEffect(() => {
        setLayoutOption(layout);
    }, [layout]);
    const productsList = productRedux === null || productRedux === void 0 ? void 0 : productRedux.list;
    return (React.createElement(CategoryResultsManageBarWrapper, null,
        React.createElement(ProductsInfoWrapper, null,
            React.createElement(Text, null,
                React.createElement(TranslatePlural, { count: productsList === null || productsList === void 0 ? void 0 : productsList.total, id: "plural.result" }))),
        React.createElement(PaginationWrapper, null,
            React.createElement(Pagination, { onChange: onPageChange, current: currentPage, count: totalPages, total: totalPages })),
        React.createElement(DropdownContainer, null,
            React.createElement(TextMobile, { onClick: onSortAndFilterClick },
                React.createElement(Translate, { id: "common.sort" }),
                " ",
                React.createElement(Translate, { id: "common.and" }),
                ' ',
                React.createElement(Translate, { id: "common.filter" }),
                React.createElement(IconWrapper, null,
                    React.createElement(Icon, { icon: 'sort', width: 20 }))),
            React.createElement(ViewIconsWrapper, null,
                React.createElement(IconWrapper, { isInactive: layoutOption === Layout.grid, onClick: () => handleIconClick(Layout.list) },
                    React.createElement(Icon, { icon: "list", width: 14, height: 14 })),
                React.createElement(IconWrapper, { isInactive: layoutOption === Layout.list, onClick: () => handleIconClick(Layout.grid) },
                    React.createElement(Icon, { icon: "grid", width: 14, height: 14 }))),
            React.createElement(TextPadding, null,
                React.createElement(Translate, { id: "common.sort" }),
                ':'),
            React.createElement(DropdownWrapper, null,
                React.createElement(Dropdown, { placeholder: translate('common.select'), value: sortValue, options: sortOptions, onChange: onSortChange })))));
};
