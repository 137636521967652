import styled from 'styled-components';
export const AccountPageLayoutContainer = styled.div `
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 0 36px;
  @media screen and (max-width: 1260px) {
    padding: 0 15px;
  }
`;
export const StyledContainer = styled.div `
  display: flex;
  padding: 35px 0 50px;
  ${(props) => props.bottom &&
    `
    min-height: 550px;
  `}
  @media (max-width: 1120px) {
    padding: 25px 0 10px;
    flex-direction: column;
  }
`;
export const BreadcrumbsWrapper = styled.div `
  padding-top: 15px;
`;
export const StyledMenu = styled.div `
  min-width: 140px;
  margin-right: 90px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1260px) {
    margin-right: 50px;
  }
  @media screen and (max-width: 1160px) {
    margin-right: 30px;
  }
  @media (max-width: 1120px) {
    margin-right: 0;
    width: 100%;
  }
  > a {
    font-size: 14px;
    line-height: 22px;
    color: black;
    margin-top: 15px;
    &.active {
      color: #d9000d;
      font-weight: 600;
    }
    &:hover {
      color: #d9000d;
    }
  }
`;
export const StyledContent = styled.div `
  width: 100%;

  @media (max-width: 1120px) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #bababa;
  }
`;
export const StyledLogout = styled.div `
  margin-top: 80px;
  font-size: 14px;
  line-height: 22px;
  color: black;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    color: #d9000d;
    svg {
      path {
        fill: #d9000d;
      }
    }
  }
  @media (max-width: 1120px) {
    margin-top: 35px;
  }
`;
export const StyledTitlePage = styled.div `
  text-transform: capitalize;
  font-size: 20px;
  display: inline-flex;
  line-height: 24px;
  font-weight: 700;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 28px;
  }
  svg {
    margin-left: 10px;
    height: 8px;
    transition: 0.5s all;
    ${(props) => props.open &&
    `
      transform: rotate(180deg);
      transition: .5s all;
    `}
  }
`;
