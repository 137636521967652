import React from 'react';
import { BannerTitle, StyledButton, BannerContent, BannerSubtitle, BannerContainer, ButtonContainer, CorporateLinksWrapper, } from '@@Components/BannersWithLinks/styled';
import { ThumbnailUtils } from '@@Storefront';
export const BannersWithLinks = (props) => {
    var _a;
    return (React.createElement(CorporateLinksWrapper, null, (_a = props === null || props === void 0 ? void 0 : props.slides) === null || _a === void 0 ? void 0 : _a.sort((a, b) => a.priority > b.priority ? 1 : -1).map((item, index) => {
        var _a;
        return (item === null || item === void 0 ? void 0 : item.image) ? (React.createElement(BannerContainer, { key: index, color: (item === null || item === void 0 ? void 0 : item.image_text_color) || '#fff', background: ThumbnailUtils.getImageSrc({
                image: (_a = item === null || item === void 0 ? void 0 : item.image[0]) === null || _a === void 0 ? void 0 : _a.url,
                entity: '',
                height: 450,
            }) },
            (item === null || item === void 0 ? void 0 : item.subtitle) ? (React.createElement(BannerSubtitle, null, item === null || item === void 0 ? void 0 : item.subtitle)) : null,
            item.title ? React.createElement(BannerTitle, null, item.title) : null,
            (item === null || item === void 0 ? void 0 : item.description) ? (React.createElement(BannerContent, null,
                React.createElement("div", { dangerouslySetInnerHTML: { __html: item.description } }))) : null,
            (item === null || item === void 0 ? void 0 : item.button_text) && (item === null || item === void 0 ? void 0 : item.button_url) && (React.createElement(ButtonContainer, null,
                React.createElement("a", { href: item === null || item === void 0 ? void 0 : item.button_url },
                    React.createElement(StyledButton, null, item.button_text)))))) : null;
    })));
};
