import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Button from '@@Components/Buttons/Button';
export const ImageContainer = styled.div `
  display: flex;
  justify-content: center;
  background-image: url('${(props) => props.url}');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  position: relative;
  @media screen and (min-width: 1501px) {
    background-size: cover;
  }
  @media screen and (max-width: 768px) {
    background-size: cover;
  }
`;
export const StyledLink = styled(Link) `
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20%;
`;
export const StyledButton = styled(Button) `
  width: 300px;
`;
