import styled from 'styled-components';
export const TabsWrapper = styled.div `
  width: 100%;
  scroll-padding-top: 85px;
`;
export const TabChildContainer = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 65px;
`;
